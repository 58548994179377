
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { JobCard } from "@/models/services";
import { JobcardForm } from "@/helpers/classes/Form";
import { VehicleProfile } from "@/models/vehicle";
import { JobLib } from "@/helpers";

@Component({})
export default class JobCardModal extends Vue {
  @PropSync("isOpen") open!: boolean;
  @Prop() vehicle!: VehicleProfile | null;
  @PropSync("jobcard") jc!: JobCard | undefined;

  async submit() {
    try {
      //console.log("Submit async call for jobcard", this.jc && this.jc.id);
      if (this.jc && this.jc.id) await JobLib.deleteVehicleJobCard(this.jc.id);
      this.$emit("submit");
      this.open = false;
      this.jc = undefined;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
