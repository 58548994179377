
// import { ImageAttachment } from "@/models/files";
import { Component, Vue, PropSync, Watch, Prop } from "vue-property-decorator";
import ImageAttachmentField from "@/components/fields/ImageAttachment.vue";
import { OdometerSnapshot, CreateVehicleAttachments } from "@/models/vehicle";
import { VForm } from "vuetify/lib";
import { ReviewAttachmentForm } from "@/helpers/classes/Form";

@Component({
  components: {
    ImageAttachmentField,
  },
})
export default class ReviewAttachment extends Vue {
  @PropSync("item") initializeItem!: {
    something: boolean;
  };
  get getValid() {
    return this.valid;
  }
  form: ReviewAttachmentForm | null = null;
  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    // console.log("Has changes");
    // if (this.form && this.form.reference) {
    //   this.valid = this.form.compare();
    // } else {
    //   if (this.form) {

    //   };
    //   this.valid = false;
    // }
    if (this.form) {
      this.form.compare();
    } else {
      this.valid = false;
    }
  }
  valid = false;
  name = "";
  rules = {
    required: (v: string) => !!v || "Required",
    biggerThan0: (v: number) =>
      (!!v && v >= 0) || "Need to be greater and equal to 0",
  };
  errorMessage = "";

  get hasError() {
    return !!this.errorMessage;
  }
  mounted() {
    this.form = new ReviewAttachmentForm();
  }
  submitForm() {
    if ((this.$refs.form as any).validate()) {
      // Submit the data
    } else {
      this.errorMessage =
        "Please fix the errors in the form before submitting.";
    }
  }
}
