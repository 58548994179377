import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";
import { Event, Event_CREATE, EventCategories } from "@/models/event";
import { Query } from "@/models/search";

const events = () => {
  const baseUrl = "/api/EventCalendar";
  const GetAllEventCalendar = async (query: Query): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/GetAllEventCalendar",
      query
    );
    return Promise.resolve(res.data);
  };

  const GetEventCalendar = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + `/GetEventCalendar?id=${id}`);
    return Promise.resolve(res.data);
  };

  const CreateEventCalendar = async (data: Event_CREATE): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/CreateEventCalendar",
      data
    );
    return Promise.resolve(res.data);
  };

  const UpdateEventCalendar = async (data: Event): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateEventCalendar",
      data
    );
    return Promise.resolve(res.data);
  };

  const RemoveEventCalendar = async (data: Event): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/RemoveEventCalendar",
      data
    );
    return Promise.resolve(res.data);
  };

  const GetEventCategoriess = async (): Promise<EventCategories[]> => {
    const res = await axiosInstance.get(baseUrl + "/GetEventCategoriess");
    return Promise.resolve(res.data);
  };

  const AddEventCategories = async (data: EventCategories): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/AddEventCategories", data);
    return Promise.resolve(res.data);
  };

  const UpdateEventCategories = async (data: EventCategories): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateEventCategories",
      data
    );
    return Promise.resolve(res.data);
  };

  const DeleteEventCategories = async (data: EventCategories): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/DeleteEventCategories",
      data
    );
    return Promise.resolve(res.data);
  };

  return {
    GetAllEventCalendar,
    GetEventCalendar,
    CreateEventCalendar,
    UpdateEventCalendar,
    RemoveEventCalendar,
    GetEventCategoriess,
    AddEventCategories,
    UpdateEventCategories,
    DeleteEventCategories,
  };
};

const eventsHook = events();
export { eventsHook };
