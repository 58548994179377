
import { Component, Vue } from "vue-property-decorator";
import { NotificationLib, VehicleLib } from "@/helpers";
import { axiosNoAuth, generateMessageFromError } from "@/plugins/axios";
import VechileModule from "@/store/modules/Vehicles";
// import Firebase from "@/store/modules/firebase";
import { getMessaging } from "firebase/messaging";
import fbHook from "@/helpers/firebase";

interface Share {
  id: number;
  text: "Sale" | "Repair";
}

@Component
export default class EMailVehicle extends Vue {
  form = { email: "", shareable: true, shareType: 1 };
  loading = false;
  reasons: Share[] = [
    {
      id: 0,
      text: "Sale",
    },
    {
      id: 1,
      text: "Repair",
    },
  ];
  get getValid() {
    return /.+@.+(\..+)+/.test(this.form.email);
  }
  close(): void {
    this.form.email = "";
    this.display = false;
  }
  get vehicle() {
    return VechileModule.getInteracted.item;
  }

  get display() {
    return VechileModule.getInteracted.emailSharing;
  }
  set display(val: boolean) {
    VechileModule.setInteractedFlag({
      key: "emailSharing",
      value: val,
    });
  }
  async email(): Promise<void> {
    try {
      this.loading = true;
      if (this.vehicle) {
        await VehicleLib.ShareVehicleViaEmail({
          ...this.form,
          vehicleId: this.vehicle.id,
        });
        fbHook.logEvent(3, {
          method: "Email",
          content_type: "Vehicle Share",
          item_id: this.vehicle.id.toString(),
        });
      }

      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.close();
    }
  }
}
