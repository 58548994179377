import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// Components
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "../components/NavDrawer.vue";
import Footer from "@/components/Footer.vue";

// Authentication
import Authentication from "@/views/Authentication.vue";
import ResetPassword from "@/views/authentication/ResetPassword.vue";

// Employees
import ConfirmDriver from "@/views/ConfirmDriver.vue";

// Navigation
/* import MobileMenu from "@/components/MobileMenu.vue"; */
import BottomNav from "@/components/BottomNavigation.vue";

// Entities
import Profile from "@/views/Profile.vue";
import Calendar from "@/views/Calendar.vue";
import Cars_Parts from "@/views/Cars_&_Parts/Cars_&_Parts.vue";
import Avatar from "@/views/Avatar.vue";
import Videos from "@/views/Videos/Videos.vue";

// Vehicles
import VehicleList from "@/views/Vehicles.vue";
import VehicleJobCards from "@/views/vehicle/JobCards.vue";
import ConfirmSharedVehicle from "@/views/ConfirmSharedVehicle.vue";
import Garage from "@/views/Garage.vue";

// Services
import Directory from "@/views/Directory.vue";

// Miscellaneous
import Landing from "@/views/Landing.vue";
// import ContactUs from "@/views/ContactUs.vue";
import TandCs from "@/views/TandCs.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

// Authentication
import AuthModule from "@/store/modules/Auth";
import { NotificationLib, StorageLib, UserLib } from "@/helpers/index";
import { UserRegisterForm } from "@/helpers/classes/Form";
import { AuthToken } from "@/models/user";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      name: "garage",
    },
  },
  {
    path: "/home",
    name: "home",
    components: {
      default: Landing,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/vehicle/join/:encoded",
    name: "confirmSharedVehicle",
    components: {
      default: ConfirmSharedVehicle,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
    props: true,
  },
  {
    path: "/profile",
    name: "profile",
    components: {
      default: Profile,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  /*  {
    path: "/menu",
    name: "menu",
    components: {
      default: MobileMenu,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  }, */
  /* {
    path: "/vehicles",
    name: "vehicleList",
    components: {
      default: VehicleList,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  }, */
  {
    path: "/vehicles",
    redirect: {
      name: "garage",
    },
  },
  {
    path: "/garage",
    name: "garage",
    components: {
      default: Garage,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/Cars_&_Parts/:product?",
    name: "cars_&_parts",
    components: {
      default: Cars_Parts,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/avatar/:vehicle?",
    name: "avatar",
    components: {
      default: Avatar,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/videos/:video?",
    name: "videos",
    components: {
      default: Videos,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/jobcards/:vehicle",
    name: "vehicle-jobcards",
    components: {
      default: VehicleJobCards,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
    // children: [
    //   {
    //     path: "",
    //     name: "vehicle-jobcards",
    //     components: {
    //       default: VehicleJobCards,
    //     },
    //   },
    //   {
    //     path: "jobcard/:id",
    //   },
    //   {
    //     path: "invoices/:id",
    //   },
    // ],
  },
  // {
  //   path: "/contact-us",
  //   name: "contactUs",
  //   components: {
  //     default: ContactUs,
  //     footer: Footer,
  //     appBar: AppBar,
  //     bottomNav: BottomNav,
  //   },
  // },
  {
    path: "/directory",
    name: "directory",
    components: {
      default: Directory,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    components: {
      default: Calendar,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TandCs",
    components: {
      default: TandCs,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    components: {
      default: PrivacyPolicy,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/authentication",
    name: "authentication",
    components: {
      default: Authentication,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/confirmDriver/:uuid",
    name: "confirmDriver",
    components: {
      default: ConfirmDriver,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
      bottomNav: BottomNav,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    components: {
      default: ResetPassword,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  // getLocalStorage
  // check accessToken expiry
  // proceed
  // else logout

  /* console.log("AuthModule.isLoggedIn", AuthModule.isLoggedIn);
  if (AuthModule.isLoggedIn == false) {
    NotificationLib.createWarningNotification("Please Log In to Continue");
    UserLib.logout();
    router.push({
      name: "authentication",
      hash: "#login",
      query: {
        redirectedFrom: to.fullPath,
      },
    });
  } */

  try {
    const resStorage = await StorageLib.getLogin();
    const resAuthModule = AuthModule.getAuthToken;
    let token: AuthToken | null = null;
    //("Res StorageLib.getLogin", resStorage);
    //console.log("AuthModule.getAuthToken", resAuthModule);
    if (resStorage != null) token = resStorage;
    if (resAuthModule != null) token = resAuthModule;
    // console.log("Token", token);

    if (to.name == "forgotPassword") {
      next();
    } else {
      if (token !== null) {
        //console.log("Got Login");
        const valid = await UserLib.processNewJWT(token);
        // NotificationLib.createErrorNotification("Get JWT Token");
        // console.log(valid);
        if (valid) {
          setTimeout(async () => {
            try {
              // NotificationLib.createErrorNotification("Get Profile");
              if (to.name != from.name) {
                await UserLib.getProfile();
              }
            } catch (err) {
              UserLib.logout();
              router.push({
                name: "authentication",
                hash: "#login",
                query: {
                  redirectedFrom: to.fullPath,
                },
              });
              // NotificationLib.createErrorNotification("Profile Error Caught");
            }
            // console.log(res);
            // AuthModule.setProfile(res);
          }, 1000);
        } else {
          NotificationLib.createWarningNotification(
            "Token expired. Please log in again"
          );
          UserLib.logout();
          router.push({
            name: "authentication",
            hash: "#login",
            query: {
              redirectedFrom: to.fullPath,
            },
          });
        }
      }

      if (token == null && to.name != "authentication") {
        if (to.name != "home") {
          NotificationLib.createWarningNotification(
            "Please log in to continue"
          );
          router.push({
            name: "authentication",
            hash: "#login",
            query: {
              redirectedFrom: to.fullPath,
            },
          });
        }
      }
      // todo lock routes based on roles on them.
      // console.log("Route ", to, from);
      // if ()
      next();
    }
  } catch (err) {
    return Promise.reject(err);
  }
});
export default router;
