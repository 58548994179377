
import { Component, Vue, Watch } from "vue-property-decorator";
import { NotificationLib, JobLib, VehicleLib, DocumentLib } from "@/helpers";
import { VehicleProfile } from "@/models/index";
import VehiclesModule from "@/store/modules/Vehicles";
import WorkItemModal from "@/views/vehicle/jobcards/WorkItemModal.vue";
import DeleteJobCardModal from "@/views/vehicle/jobcards/DeleteJobCardModal.vue";
import BillingModal from "@/views/vehicle/jobcards/BillingModal.vue";
import JobcardModal from "@/views/vehicle/jobcards/JobCardModal.vue"; // Use this

import AuthModule from "@/store/modules/Auth";
import { axiosInstance, generateMessageFromError } from "@/plugins/axios";
import { stringToBase64URL } from "@/utilities";
import { JobCard, Status, WorkItem } from "@/models/services";
import dayjs from "dayjs";
import { VehicleStatus } from "@/models/vehicle";
import { Registration } from "@/models/user";
// import { JobCard, WorkItem } from "@/models/Jobs";
// import InvoiceModule from "@/store/modules/Invoices";
// import QuotesModule from "@/store/modules/Quotes";
import { Profile } from "@/models/user";
import Loader from "@/store/modules/Loader";
// import { namespace } from "vuex-class";

// const quotesModule = namespace("QuotesModule");
// const invoicesModule = namespace("InvoicesModule");

@Component({
  components: {
    DeleteJobCardModal,
    WorkItemModal,
    JobcardModal,
    BillingModal,
  },
  filters: {
    numberFormatter(number: number) {
      return new Intl.NumberFormat().format(number);
    },
    convertToDate(e: string) {
      return dayjs(e).format("YYYY-MM-DD HH:mm");
    },
  },
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Vehicle | Job Cards",
  },
})
export default class VehicleJobCards extends Vue {
  get getProfile() {
    return AuthModule.getProfile;
  }
  optionsModal = false;
  // @quotesModule.State("jobcard")
  quotes: JobCard | null = null;

  // @invoicesModule.State("jobcard")
  invoices: JobCard | null = null;
  billing: JobCard | null = null;

  /**
   * Status
   *
   * Used to inceded on status changes
   */
  statusConfirmationDialog = false;
  statusConfirmation: {
    jobcard: JobCard;
    status: VehicleStatus;
    index: number;
  } | null = null;

  workItemModal: {
    index?: number;
    workItem?: WorkItem;
    open: boolean;
    jobcard: JobCard | null;
  } = {
    open: false,
    jobcard: null,
  };
  deleteModal: {
    open: boolean;
    jobcard?: JobCard;
  } = {
    open: false,
  };
  jobcardModal: {
    open: boolean;
    jobcard?: JobCard;
  } = {
    open: false,
  };
  get jobStatus() {
    return VehiclesModule.getStatuses;
  }
  currentVehicle: VehicleProfile | null = null;
  jobcards: JobCard[] = [];
  activeJobcard: JobCard | null = null;

  // @Watch("currentVehicle")
  // onVehicleChange(val: VehicleProfile) {
  //   this.init();
  // }

  openOptions(i: JobCard) {
    this.activeJobcard = i;
    this.optionsModal = true;
  }

  get getJobcards() {
    return this.getJobcardsFiltered.filter((e) => {
      if (this.filterList == "Active") {
        return e.status.id != 5 ? e : false;
      } else {
        return e.status.id == 5 ? e : false;
      }
    });
  }
  get getCompletedJobcards() {
    return this.getJobcardsFiltered.filter((e) => e.status.id == 5);
  }

  get getJobcardsFiltered() {
    return this.jobcards.sort((a: JobCard, b: JobCard) => {
      if (a.inDate && b.inDate) {
        // console.log("Sort ", a, b);
        let jA = a.inDate;
        let jB = b.inDate;
        if (typeof jA == "string") {
          jA = dayjs(jA);
        }
        if (typeof jB == "string") {
          jB = dayjs(jB);
        }
        if (jA.isBefore(jB)) {
          return this.sortDesc ? 1 : -1;
        } else if (jA.isAfter(jB)) {
          return this.sortDesc ? -1 : 1;
        } else {
          return 0;
        }
      }
      return 0;
    });
  }
  /**
   * Searching
   */
  search = "";
  serverItemsLength = 0;
  sortDesc = true;
  options = {
    page: 1,
    itemsPerPage: -1,
    sortBy: ["fullName"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  availFilterList = ["Active", "Completed"];
  filterList: "Active" | "Completed" = "Active";
  headers = [
    { text: "Job Card", value: "id" },
    { text: "Description", value: "description" },
    { text: "Status", value: "status" },
    { text: "Status", value: "statusDescription" },
    { text: "Quotes", value: "quotes" },
    { text: "Invoice", value: "invoice" },
    { text: "Booked Out", value: "bookedOut" },
    { text: "BookedIn", value: "bookedIn" },
    { text: "In Date", value: "inDate" },
    { text: "OutDate", value: "outDate" },
    { text: "Provider", value: "provider" },
    { text: "Work Items", value: "jobcardWorkItems" },
    { text: "Attachments", value: "attachments" },
    { text: "Created By", value: "createBy" },
    { text: "Created By", value: "createDate" },
    { text: "Updated By", value: "updateBy" },
    { text: "Updated By", value: "updateDate" },
  ];
  get profile() {
    return AuthModule.getProfile;
  }
  // get allowedToAdd() {
  //   if (this.profile) {
  //     if (this.filterList == "Active") {
  //       return this.profile.userDetails.userType != "Individual";
  //     }
  //   }
  //   return false;
  // }
  async deleteWorkItem(jobCardId: number, workItemId: number) {
    try {
      const temp = await JobLib.deleteJobCardWorkItem({
        jobCardId,
        workItemId,
      });
      await this.init();
      // console.log("Delete work item now ", jobCardId, workItemId);
    } catch (err) {
      console.error(err);
    }
  }
  openWorkItem(jobcard: JobCard | null, workItem?: WorkItem) {
    // console.log(jobcard);
    // console.log(workItem);
    this.workItemModal.jobcard = jobcard;
    this.workItemModal.workItem = workItem;
    this.workItemModal.open = true;
  }
  closeWorkItem() {
    this.workItemModal.open = false;
    this.workItemModal.jobcard = null;
    this.workItemModal.workItem = undefined;
  }
  viewInvoices(jobCard: JobCard) {
    this.invoices = jobCard;
  }
  viewQuotes(jobCard: JobCard) {
    this.quotes = jobCard;
  }
  viewBilling(jobCard: JobCard) {
    this.billing = jobCard;
  }
  async updateBilling() {
    if (this.billing && this.billing.id) {
      //console.log("Calling JobCard Uopdate", billing.id);
      const jobcard = await JobLib.reviewJobCard(this.billing.id);
      if (jobcard) {
        this.billing = jobcard;
      } else {
        this.billing = null;
      }
    }
  }
  removeJobcard(jobcard: JobCard) {
    this.deleteModal.open = true;
    this.deleteModal.jobcard = jobcard;
  }
  openJobCard(jobcard?: JobCard): void {
    this.jobcardModal.open = true;
    this.jobcardModal.jobcard = jobcard;
  }
  get authenticated(): boolean {
    return AuthModule.isLoggedIn;
  }
  async loadStatuses() {
    try {
      await VehicleLib.loadStatuses();
    } catch (err) {
      console.warn(err);
    }
  }
  confirmStatus(jobcard: JobCard, status: VehicleStatus, index: number) {
    this.statusConfirmation = {
      jobcard: jobcard,
      status: status,
      index: index,
    };
    this.statusConfirmationDialog = true;
  }
  async setStatus(jobcard: JobCard, status: VehicleStatus, index: number) {
    this.statusConfirmationDialog = false;
    // console.log("Confirm status", this.statusConfirmation);
    if (jobcard.id) {
      try {
        const res = await JobLib.updateVehicleJobCardStatus({
          id: jobcard.id,
          status: status.id,
        });
        this.jobcards[index].status = status;
        return Promise.resolve(res);
      } catch {
        return Promise.reject();
      }
    }
    await this.populate();
  }
  async loadVehicle() {
    try {
      Loader.setLoadingState(true);
      const res = await VehicleLib.VehicleByID(this.$route.params.vehicle);
      this.currentVehicle = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
  async populate() {
    Loader.setLoadingState(true);
    try {
      if (AuthModule.isLoggedIn == false) return Promise.resolve();
      this.jobcards = await JobLib.reviewVehicleJobCards(
        this.$route.params.vehicle
      );
      // console.log(this.jobCards);
    } catch (err: any) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve();
    } finally {
      Loader.setLoadingState(false);
    }
  }
  async init() {
    await this.loadStatuses();
    await this.loadVehicle();
    await this.populate();
  }
  async mounted() {
    // console.log("VEHICLE:JOB CARDS");
    await this.init();
  }
}
