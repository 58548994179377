
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { UserLib, VehicleLib } from "@/helpers";
import { Profile } from "@/models/user";
import ConfirmPasswordCard from "@/components/ConfirmDriver/ConfirmPasswordCard.vue";
import ConfirmDriverCard from "@/components/ConfirmDriver/ConfirmDriverCard.vue";
import AuthModule from "@/store/modules/Auth";
import { SHARE_TYPES } from "@/constant/constants";
import {
  VehicleProfile,
  VehicleShared,
  ISharedVehicleCheck,
  VehicleSharedViaLink,
  IShareVehicleWrapped,
} from "@/models/vehicle";

@Component({
  components: {
    ConfirmDriverCard,
    ConfirmPasswordCard,
  },
})
export default class ConfirmSharedVehicle extends Vue {
  sharedCheck: ISharedVehicleCheck | null = null;
  decoded: IShareVehicleWrapped = JSON.parse(atob(this.$route.params.encoded));
  loading = false;
  get authenticated() {
    const temp = AuthModule.isLoggedIn;
    if (temp == false)
      this.$router.push({
        name: "authentication",
        hash: "#login",
        query: {
          redirectedFrom: this.$route.fullPath,
        },
      });
    return temp;
  }
  get getProfile() {
    return AuthModule.getProfile;
  }
  get reason() {
    if (!this.decoded) {
      return null;
    }
    return SHARE_TYPES.find((e) => e.id == this.decoded.shareType)?.text;
  }
  async mounted() {
    if (this.authenticated) {
      const decoded = JSON.parse(atob(this.$route.params.encoded));
      const res = await VehicleLib.CheckSharedVehicle(
        decoded.id,
        decoded.vehicleId
      );
      this.sharedCheck = res;
    }
  }
  get getSharedCheck() {
    return this.sharedCheck;
  }
  async submit() {
    // console.log("Accepting");
    try {
      this.loading = true;
      if (!this.sharedCheck) return;
      if (this.sharedCheck.status !== "Shareable") {
        this.$router.push({
          name: "garage",
          query: {
            list: this.sharedCheck.status == "Owner" ? "current" : "shared",
          },
        });
      } else {
        const decoded = JSON.parse(atob(this.$route.params.encoded));
        // console.log("Decoded ", decoded);
        if (!decoded || !this.sharedCheck) return Promise.resolve();
        const state: VehicleSharedViaLink = {
          userId: decoded.id,
          vehicleId: decoded.vehicleId,
          shareable: decoded.shareable,
          shareType: decoded.shareType,
        };
        const res = await VehicleLib.ShareVehicleViaLink(state);

        this.$router.push({
          name: "garage",
          query: {
            list: "shared",
          },
        });
        // console.log("Shared via whatsapp", res);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
