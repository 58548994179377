
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
@Component({})
export default class SplashScreen extends Vue {
  @PropSync("open") showSplashscreen!: boolean;

  close() {
    this.showSplashscreen = false;
  }
}
