
import { Component, PropSync, Vue, Watch, Prop } from "vue-property-decorator";
import { EmployeeLib, NotificationLib, UserLib } from "@/helpers";
import { Profile } from "@/models/user";
import ConfirmPasswordCard from "./ConfirmPasswordCard.vue";
import AuthModule from "@/store/modules/Auth";
import NotificationSystem from "@/store/modules/NotificationSystem";
import { generateMessageFromError } from "@/plugins/axios";

// TODO :
// Validate e-mail first; Make this lazy
// Provide Password/Confirm Password -> Trigger login
// Push to business to inform them when user signs up

@Component
export default class ConfirmDriverCard extends Vue {
  @PropSync("comp") currentComp!:
    | typeof ConfirmDriverCard
    | typeof ConfirmPasswordCard;
  @Prop() profile!: Profile;
  confirmEmail = "";
  valid = false;
  attempts = 5;
  // driverProfile: null | Profile = null;
  // get getProfile() {
  //   return this.driverProfile;
  // }
  // set setProfile(profile: Profile) {
  //   this.driverProfile = profile;
  // }
  get getAuthProfile() {
    return AuthModule.getProfile;
  }
  rules = {
    required: (v: string) => !!v || "Email is required",
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
  };
  async submit() {
    //console.log(this.confirmEmail, this.profile.userDetails.email);
    // if (
    //   this.confirmEmail === this.profile.userDetails.email &&
    //   this.attempts >= 0
    // ) {
    try {
      const res = await EmployeeLib.confirmEmployee({
        id: this.$route.params.uuid,
        email: this.confirmEmail,
      });
      this.$emit("submit", this.profile);
    } catch (err) {
      // // console.error("Cannot confirm :", err);
      // NotificationLib.createWarningNotification(err.message);
      // return Promise.reject(err);
      const message = generateMessageFromError(err);
      NotificationLib.createErrorNotification(message);
    }
    // } else {
    //   if (this.attempts == 0) {
    //     NotificationLib.createErrorNotification(
    //       "You have run out of attempts to correctly enter your e-mail address please contact administration accordingly to correct the issue"
    //     );
    //   } else {
    //     this.attempts -= 1;
    //     NotificationLib.createWarningNotification(
    //       "Please Provide the e-mail address your supplied to your company in order to register your account"
    //     );
    //   }
    // }
  }
  // carelvandam+driver02@sharpsoftwaresolution.net
  // profile is passed in through the parent
  // @Watch("$route.uuid")
  // async onIDChange(val: string) {
  //   // this.driverProfile = await UserLib.getProfile();
  //   // this.driverProfile = await UserLib.getDriverProfile(this.$route.params.uuid);
  // }
  // async mounted() {
  //   // this.driverProfile = await UserLib.getProfile();
  //   // this.driverProfile = await UserLib.getProfile(this.$route.params.uuid);
  // }

  // TODO: Block if logged in
}
