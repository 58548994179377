import { render, staticRenderFns } from "./ReviewAttachments.vue?vue&type=template&id=3a0a81ad&scoped=true&"
import script from "./ReviewAttachments.vue?vue&type=script&lang=ts&"
export * from "./ReviewAttachments.vue?vue&type=script&lang=ts&"
import style0 from "./ReviewAttachments.vue?vue&type=style&index=0&id=3a0a81ad&prod&lang=scss&scoped=true&"
import style1 from "./ReviewAttachments.vue?vue&type=style&index=1&id=3a0a81ad&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a0a81ad",
  null
  
)

export default component.exports