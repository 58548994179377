import { Invoice, Quote } from "@/models/documents";
import { JobCard } from "@/models/services";
import { ResponseType } from "axios";
import { axiosInstance } from "@/plugins/axios";
import dayjs from "dayjs";

const DocumentLib = () => {
  const createQuote = async (quote: Quote): Promise<Quote> => {
    const res = await axiosInstance
      .post(`/api/Quote/GenerateQuote`, quote)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const reviewQuote = async (id: string | number): Promise<Invoice> => {
    const res = await axiosInstance
      .get(`/api/Quote/GetQuote`, { params: { id: id } })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const reviewQuoteAsPDF = async (
    quote: Quote,
    responseType?: ResponseType | "file"
  ): Promise<ArrayBuffer | Document | File | Blob> => {
    const res = await axiosInstance
      .get(`/api/Quote/DownloadQuotePDF`, {
        params: { id: quote.quoteId },
        responseType: responseType == "file" ? "blob" : responseType,
        headers: {
          Accept: "application/pdf",
        },
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    if (responseType == "file") {
      const file = new File([res.data], `${quote.reference}.pdf`, {
        type: res.data.type,
      });
      return Promise.resolve(file);
    } else {
      return Promise.resolve(res.data);
    }
  };

  // Does not exist
  // const reviewQuotes = async (jobcard: JobCard): Promise<Quote[]> => {
  //   if (jobcard) {
  //     const res = await axiosInstance
  //       .get(`/api/Quote/GetAllQuotesForJobCard`)
  //       .catch((err) => {
  //         return Promise.reject(err);
  //       });
  //     return Promise.resolve(res.data);
  //   } else {
  //     const res = await axiosInstance
  //       .get(`/api/Quote/GetAllQuotes`)
  //       .catch((err) => {
  //         return Promise.reject(err);
  //       });
  //     return Promise.resolve(res.data);
  //   }
  // };

  const updateQuote = async (quote: Quote): Promise<Quote> => {
    const res = await axiosInstance
      .post(`/api/Quote/UpdateQuote`, quote)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const deleteQuote = async (quote: Quote): Promise<Quote> => {
    const res = await axiosInstance
      .get(`/api/Quote/DeleteQuote`, { params: { id: quote.quoteId } })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  // { id, invoicePaymentDate, invoiceTerms }
  const createInvoice = async (quote: Quote): Promise<Invoice> => {
    const res = await axiosInstance
      .post(`/api/Invoice/CreateInvoiceFromQuote`, quote)
      .catch((err) => {
        return Promise.reject(err);
      });
    const invoice: Invoice = {
      quoteId: quote.quoteId,
      vehicleId: quote.vehicleId,
      jobcardId: quote.jobcardId,
      invoiceId: quote.invoiceId,
      business: quote.business,
      client: quote.client,
      groupJobs: quote.groupJobs,
      invoiceSubTotal: quote.quoteSubTotal,
      invoiceVAT: quote.quoteVAT,
      invoiceTotal: quote.quoteTotal,
      legalese: quote.legalese,
      invoiceTerms: "",
    }; // TOSO@CVD Because Backend was being snoep with their data
    return Promise.resolve({
      ...invoice,
      ...res.data,
    });
  };

  const reviewInvoice = async (id: string | number): Promise<Invoice> => {
    const res = await axiosInstance
      .get(`/api/Invoice/GetInvoice`, { params: { id: id } })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const reviewInvoiceAsPDF = async (
    invoice: Invoice,
    responseType?: ResponseType | "file"
  ): Promise<ArrayBuffer | Document | File | Blob> => {
    const res = await axiosInstance
      .get(`/api/Invoice/DownloadInvoicePDF`, {
        params: { id: invoice.invoiceId },
        responseType: responseType == "file" ? "blob" : responseType,
        headers: {
          Accept: "application/pdf",
        },
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    if (responseType == "file") {
      const file = new File([res.data], `${invoice.reference}.pdf`, {
        type: res.data.type,
      });
      return Promise.resolve(file);
    } else {
      return Promise.resolve(res.data);
    }
  };

  async function reviewInvoices(
    jobCardId?: number | string
  ): Promise<Invoice[]> {
    if (jobCardId) {
      const res = await axiosInstance
        .get(`/api/Invoice/GetAllInvoicesForJobcard`, {
          params: { JobCardId: jobCardId },
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    } else {
      const res = await axiosInstance
        .get(`/api/Invoice/GetAllInvoices`)
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    }
  }

  return {
    createQuote,
    reviewQuote,
    reviewQuoteAsPDF,
    // reviewQuotes,
    updateQuote,
    deleteQuote,
    createInvoice,
    reviewInvoice,
    reviewInvoiceAsPDF,
    reviewInvoices,
    // updateInvoice,
    // deleteInvoice,
  };
};

const documentLib = DocumentLib();
export { documentLib };
