var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Enter your new password ")]),(_vm.profile)?_c('v-card-text',{staticClass:"pt-3"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"11","md":"6"}},[_c('v-text-field',{attrs:{"clearable":"","error-count":"3","rules":[
                _vm.rules.required,
                _vm.rules.capital,
                _vm.rules.number,
                _vm.rules.normalCase,
                _vm.rules.special,
                _vm.rules.password,
              ],"append-icon":_vm.reveal == true ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.reveal ? 'text' : 'password',"outlined":"","label":"Password *","dense":"","rounded":""},on:{"click:append":function($event){_vm.reveal = !_vm.reveal}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"11","md":"6"}},[_c('v-text-field',{attrs:{"clearable":"","error-count":"3","rules":[
                (!!_vm.confirmPassword && _vm.password === _vm.confirmPassword) ||
                  'Please confirm your password',
              ],"append-icon":_vm.reveal ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.reveal == true ? 'text' : 'password',"outlined":"","label":"Confirm Password *","dense":"","rounded":""},on:{"click:append":function($event){_vm.reveal = !_vm.reveal}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.valid,"rounded":""},on:{"click":_vm.submit}},[_vm._v("Confirm")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }