import {
  JobCard,
  JobCardDetails,
  UpdateVehicleJobCard,
  WorkItem,
} from "@/models/services";
import { Vehicle, VehicleProfile } from "@/models/vehicle";
import { axiosInstance } from "@/plugins/axios";
import AuthModule from "@/store/modules/Auth";
import { fileToBase64 } from "@/utilities";

const JobLib = () => {
  // Ideally
  // const JobCards = async (data: JobCardsQuery): Promise<JobCards> => {
  //   const res = await axiosInstance.post(`/api/JobCards`, data);
  //   return Promise.resolve(res.data);
  // };

  // const WorkItems = async (data: WorkItemsQuery): Promise<WorkItems> => {
  //   const res = await axiosInstance.post(`/api/JobCards/WorkItems`, data);
  //   return Promise.resolve(res.data);
  // };

  const reviewVehicleJobCards = async (
    data: string | number
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/GetJobCardsForVehicle?vehicleId=${data}`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const reviewJobCard = async (
    jobCardId: string | number
  ): Promise<JobCard> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/GetJobCard?Id=${jobCardId}`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const getJobCardById = async (
    jobCardId: string | number
  ): Promise<JobCard> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/GetJobCard?Id=${jobCardId}`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const createVehicleJobCard = async (
    jobcard: JobCardDetails
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .post(`/api/Jobcard/CreateJobcard`, jobcard)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const updateVehicleJobCard = async (
    jobcard: JobCardDetails
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .post(`/api/Jobcard/EditJobCard`, jobcard)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const updateVehicleJobCardStatus = async (
    status: UpdateVehicleJobCard
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/UpdateJobCardStatus`, { params: status })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const deleteVehicleJobCard = async (
    id: number | string
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/DeleteJobcard?id=${id}`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const createJobCardWorkItem = async (
    workItem: WorkItem
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .post(`/api/Jobcard/AddWorkItem`, workItem)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const updateJobCardWorkItem = async (
    workItem: WorkItem
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .post(`/api/Jobcard/EditWorkItem`, workItem)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const deleteJobCardWorkItem = async (val: {
    jobCardId: number;
    workItemId: number;
  }): Promise<JobCard[]> => {
    const res = await axiosInstance
      .post(`/api/Jobcard/RemoveWorkItem`, val)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const createWorkItemAttachment = async (
    vehicle: VehicleProfile
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/AddWorkItem`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const reviewWorkItemAttachment = async (
    vehicle: VehicleProfile
  ): Promise<JobCard[]> => {
    const res = await axiosInstance
      .get(`/api/Jobcard/DownloadWorkItemAttachments`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  return {
    // Jobcard
    reviewJobCard,
    // Vehicle: Jobcard
    createVehicleJobCard,
    reviewVehicleJobCards,
    updateVehicleJobCard,
    updateVehicleJobCardStatus,
    deleteVehicleJobCard,
    // Jobcard: WorkItems
    createJobCardWorkItem,
    updateJobCardWorkItem,
    deleteJobCardWorkItem,
    createWorkItemAttachment,
    reviewWorkItemAttachment,
    getJobCardById,
  };
};

const jobLib = JobLib();
export { jobLib };
