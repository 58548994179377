import { Query } from "@/models/search";
import { ProductsViewModel, ProductCategories } from "@/models/store";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";

const store = () => {
  const baseUrl = "/api/Products";
  const GetProducts = async (
    query: Query
  ): Promise<{ products: ProductsViewModel[]; resultCount: number }> => {
    const res = await axiosInstance.post(baseUrl + "/GetProducts", query);
    return Promise.resolve(res.data);
  };

  const AddProduct = async (data: ProductsViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/AddProduct", data);
    return Promise.resolve(res.data);
  };

  const UpdateProducts = async (data: ProductsViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateProducts", data);
    return Promise.resolve(res.data);
  };

  const DeleteProducts = async (data: ProductsViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/DeleteProducts", data);
    return Promise.resolve(res.data);
  };

  const GetProductCategoriess = async (): Promise<ProductCategories[]> => {
    const res = await axiosInstance.get(baseUrl + "/GetProductCategoriess");
    return Promise.resolve(res.data);
  };

  const AddProductCategories = async (
    data: ProductCategories
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/AddProductCategories",
      data
    );
    return Promise.resolve(res.data);
  };

  const UpdateProductCategories = async (
    data: ProductCategories
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateProductCategories",
      data
    );
    return Promise.resolve(res.data);
  };

  const DeleteProductCategories = async (
    data: ProductCategories
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/DeleteProductCategories",
      data
    );
    return Promise.resolve(res.data);
  };

  return {
    GetProducts,
    AddProduct,
    UpdateProducts,
    DeleteProducts,
    GetProductCategoriess,
    AddProductCategories,
    UpdateProductCategories,
    DeleteProductCategories,
  };
};

const storeHook = store();
export { storeHook };
