var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"600","width":"90vw"},model:{value:(_vm.wi.open),callback:function ($$v) {_vm.$set(_vm.wi, "open", $$v)},expression:"wi.open"}},[_c('v-card',{staticClass:"cardColor",attrs:{"height":"auto","min-height":"400px","dark":""}},[_c('v-card-title',{staticClass:"primary py-1 white--text"},[(_vm.getProfile && _vm.wi.jobcard && _vm.wi.jobcard.status.id == 5)?[_c('div',[_vm._v("View work item")])]:[(_vm.form)?_c('div',[_vm._v(" "+_vm._s(!_vm.form.reference ? "Add" : "Edit")+" work item "+_vm._s(!_vm.form.reference ? "to jobcard" : "")+" ")]):_vm._e()],_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],2),_c('v-card-text',{staticClass:"fill-height px-0"},[(_vm.form)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"11"}},[_c('v-textarea',{attrs:{"label":"What is to be done? *","auto-grow":"","outlined":"","dense":"","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.form.form.description),callback:function ($$v) {_vm.$set(_vm.form.form, "description", $$v)},expression:"form.form.description"}})],1)],1),(
              _vm.getProfile &&
              _vm.wi.jobcard &&
              ((_vm.getProfile.companyDetails &&
                _vm.getProfile.companyDetails.businessName.toUpperCase() ==
                  _vm.wi.jobcard.provider.toUpperCase()) ||
                (_vm.getProfile.companyDetails == null &&
                  _vm.wi.jobcard.provider.toUpperCase() ==
                    _vm.getProfile.userDetails.email)) &&
              _vm.wi.jobcard.status.id != 5
            )?_c('v-row',{staticClass:"mt-1",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-left d-flex align-center",attrs:{"cols":"12","sm":"11"}},[_c('div',[_vm._v("Add attachments below.")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.form || _vm.form.newItem.picture.upload == ''},on:{"click":function($event){_vm.form ? _vm.form.addNewItem() : ''}}},[_vm._v(" Add ")])],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"11"}},[_c('div',[_c('ImageAttachment',{staticClass:"mb-3",attrs:{"disabled":false,"picture":_vm.form.newItem.picture,"label":"New attachment item"},on:{"update:picture":function($event){return _vm.$set(_vm.form.newItem, "picture", $event)}}}),_c('v-textarea',{attrs:{"label":"Description","outlined":"","dense":"","auto-grow":"","clearable":""},model:{value:(_vm.form.newItem.description),callback:function ($$v) {_vm.$set(_vm.form.newItem, "description", $$v)},expression:"form.newItem.description"}})],1)])],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"11"}},[_c('div',[_vm._v(" Attachments included ("+_vm._s(_vm.form.form.attachments ? _vm.form.form.attachments.length : 0)+") ")]),_c('v-list',{staticClass:"cardColor"},[(
                    _vm.form.form.attachments && _vm.form.form.attachments.length > 0
                  )?_vm._l((_vm.form.form.attachments),function(att){return _c('v-list-item',{key:_vm.form.form.jobCardId +
                      '_' +
                      att.fileName +
                      '_image' +
                      Math.random() * 1000,staticClass:"px-3 lighten-2 mb-2"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-icon',{staticClass:"ma-2"},[_c('v-img',{staticClass:"rounded",attrs:{"max-width":"120","max-height":"100","width":"auto","contain":"","src":`data:${att.mimeType};base64,${att.upload}`}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text text-wrap"},[(_vm.$vuetify.breakpoint.xs)?_c('v-img',{staticClass:"rounded",attrs:{"max-width":_vm.$vuetify.breakpoint.xs ? '55%' : 120,"width":"auto","contain":"","src":`data:${att.mimeType};base64,${att.upload}`}}):_vm._e(),_c('div',{staticClass:"mt-2 text-body-1"},[_vm._v(" "+_vm._s(att.description)+" ")])],1)],1),_c('v-list-item-action',{staticClass:"ma-0"},[(
                          _vm.getProfile &&
                          _vm.wi.jobcard &&
                          ((_vm.getProfile.companyDetails &&
                            _vm.getProfile.companyDetails.businessName.toUpperCase() ==
                              _vm.wi.jobcard.provider.toUpperCase()) ||
                            (_vm.getProfile.companyDetails == null &&
                              _vm.wi.jobcard.provider.toUpperCase() ==
                                _vm.getProfile.userDetails.email)) &&
                          _vm.wi.jobcard.status.id != 5
                        )?_c('v-btn',{staticClass:"mr-n7",attrs:{"color":"error","small":"","fab":"","absolute":"","top":"","right":""},on:{"click":function($event){_vm.form ? _vm.form.removeAttachment(att) : ''}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1)}):_c('v-list-item',{staticClass:"cardColor px-0"},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No Attachments added ")])],1)],1)],2)],1)],1)],1):_vm._e()],1),(
          _vm.getProfile &&
          _vm.wi.jobcard &&
          ((_vm.getProfile.companyDetails &&
            _vm.getProfile.companyDetails.businessName.toUpperCase() ==
              _vm.wi.jobcard.provider.toUpperCase()) ||
            (_vm.getProfile.companyDetails == null &&
              _vm.wi.jobcard.provider.toUpperCase() ==
                _vm.getProfile.userDetails.email)) &&
          _vm.wi.jobcard.status.id != 5
        )?_c('v-card-actions',{staticClass:"pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto","sm":"5"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"block":"","outlined":"","color":"white"},on:{"click":function($event){_vm.form ? _vm.form.reset() : ''}}},[_vm._v(" Reset ")])],1),_c('v-col',{attrs:{"cols":"auto","sm":"5"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"block":"","color":"primary","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)],1):_vm._e()],1)],1):_c('v-dialog',{attrs:{"scrollable":"","content-class":"dialog ma-0","transition":"dialog-bottom-transition","overlay-opacity":"0.8"},model:{value:(_vm.wi.open),callback:function ($$v) {_vm.$set(_vm.wi, "open", $$v)},expression:"wi.open"}},[_c('v-card',{staticStyle:{"border-radius":"2rem 2rem 0 0","overflow":"hidden"},attrs:{"color":"black","min-height":"35vh","dark":""}},[_c('v-card-title',{staticClass:"white--text"},[(_vm.getProfile && _vm.wi.jobcard && _vm.wi.jobcard.status.id == 5)?[_c('div',[_vm._v("View work item")])]:[(_vm.form)?_c('div',[_vm._v(" "+_vm._s(!_vm.form.reference ? "Add" : "Edit")+" work item "+_vm._s(!_vm.form.reference ? "to jobcard" : "")+" ")]):_vm._e()],_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],2),_c('v-card-text',{staticClass:"fill-height px-5"},[(_vm.form)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"11"}},[_c('v-textarea',{attrs:{"label":"What is to be done? *","auto-grow":"","outlined":"","dense":"","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.form.form.description),callback:function ($$v) {_vm.$set(_vm.form.form, "description", $$v)},expression:"form.form.description"}})],1)],1),(
              _vm.getProfile &&
              _vm.wi.jobcard &&
              ((_vm.getProfile.companyDetails &&
                _vm.getProfile.companyDetails.businessName.toUpperCase() ==
                  _vm.wi.jobcard.provider.toUpperCase()) ||
                (_vm.getProfile.companyDetails == null &&
                  _vm.wi.jobcard.provider.toUpperCase() ==
                    _vm.getProfile.userDetails.email)) &&
              _vm.wi.jobcard.status.id != 5
            )?_c('v-row',{staticClass:"mt-1",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-left d-flex align-center",attrs:{"cols":"12","sm":"11"}},[_c('div',[_vm._v("Add attachments below.")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.form || _vm.form.newItem.picture.upload == ''},on:{"click":function($event){_vm.form ? _vm.form.addNewItem() : ''}}},[_vm._v(" Add ")])],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"11"}},[_c('div',[_c('ImageAttachment',{staticClass:"mb-3",attrs:{"disabled":false,"picture":_vm.form.newItem.picture,"label":"New attachment item"},on:{"update:picture":function($event){return _vm.$set(_vm.form.newItem, "picture", $event)}}}),_c('v-textarea',{attrs:{"label":"Description","outlined":"","dense":"","auto-grow":"","clearable":""},model:{value:(_vm.form.newItem.description),callback:function ($$v) {_vm.$set(_vm.form.newItem, "description", $$v)},expression:"form.newItem.description"}})],1)])],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","sm":"11"}},[_c('div',[_vm._v(" Attachments included ("+_vm._s(_vm.form.form.attachments ? _vm.form.form.attachments.length : 0)+") ")]),_c('v-list',{attrs:{"dark":"","color":"black"}},[(
                    _vm.form.form.attachments && _vm.form.form.attachments.length > 0
                  )?_vm._l((_vm.form.form.attachments),function(att){return _c('v-list-item',{key:_vm.form.form.jobCardId +
                      '_' +
                      att.fileName +
                      '_image' +
                      Math.random() * 1000,staticClass:"px-3 secondary lighten-2 mb-2"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-icon',{staticClass:"ma-2"},[_c('v-img',{staticClass:"rounded",attrs:{"max-width":"120","max-height":"100","width":"auto","contain":"","src":`data:${att.mimeType};base64,${att.upload}`}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text text-wrap"},[(_vm.$vuetify.breakpoint.xs)?_c('v-img',{staticClass:"rounded",attrs:{"max-width":_vm.$vuetify.breakpoint.xs ? '55%' : 120,"width":"auto","contain":"","src":`data:${att.mimeType};base64,${att.upload}`}}):_vm._e(),_c('div',{staticClass:"mt-2 text-body-1"},[_vm._v(" "+_vm._s(att.description)+" ")])],1)],1),_c('v-list-item-action',{staticClass:"ma-0"},[(
                          _vm.getProfile &&
                          _vm.wi.jobcard &&
                          ((_vm.getProfile.companyDetails &&
                            _vm.getProfile.companyDetails.businessName.toUpperCase() ==
                              _vm.wi.jobcard.provider.toUpperCase()) ||
                            (_vm.getProfile.companyDetails == null &&
                              _vm.wi.jobcard.provider.toUpperCase() ==
                                _vm.getProfile.userDetails.email)) &&
                          _vm.wi.jobcard.status.id != 5
                        )?_c('v-btn',{staticClass:"mr-n7",attrs:{"color":"error","small":"","fab":"","absolute":"","top":"","right":""},on:{"click":function($event){_vm.form ? _vm.form.removeAttachment(att) : ''}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1)}):_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No Attachments added ")])],1)],1)],2)],1)],1)],1):_vm._e()],1),(
          _vm.getProfile &&
          _vm.wi.jobcard &&
          ((_vm.getProfile.companyDetails &&
            _vm.getProfile.companyDetails.businessName.toUpperCase() ==
              _vm.wi.jobcard.provider.toUpperCase()) ||
            (_vm.getProfile.companyDetails == null &&
              _vm.wi.jobcard.provider.toUpperCase() ==
                _vm.getProfile.userDetails.email)) &&
          _vm.wi.jobcard.status.id != 5
        )?_c('v-card-actions',{staticClass:"black pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"5","sm":"5"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"block":"","outlined":"","color":"white"},on:{"click":function($event){_vm.form ? _vm.form.reset() : ''}}},[_vm._v(" Reset ")])],1),_c('v-col',{attrs:{"cols":"5","sm":"5"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"block":"","color":"primary","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }