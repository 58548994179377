import { axiosInstance } from "@/plugins/axios";

const vehicleTypesLib = () => {
  // TODO: Proposal for navigating vehicle makes an models.
  // const GetManufacturer = async (manufacturer: string | null): Promise<Manufacturer[]|Manufacturer> => {
  //   const res = await axiosInstance.get(
  //     manufacturer ? `/api/manufacturer/{manufacturer.id}` :  `/api/manufacturer/`
  //   );
  //   return Promise.resolve(res.data);
  // };
  // const GetManufacturerModel = async (manufacturer: Manufacturer, model : Model | null): Promise<Model[]|Model> => {
  //   const res = await axiosInstance.get(
  //     model ? `/api/manufacturer/{manufacturer.id}/model/{manufacturer.id}` :  `/api/manufacturer/{manufacturer.id}/model/`
  //   );
  //   return Promise.resolve(res.data);
  // };
  // const GetManufacturerModelYear = async (manufacturer: Manufacturer, model : Model, year : Year | null): Promise<Model[]|Model> => {
  //   const res = await axiosInstance.get(
  //     year ? `/api/manufacturer/{manufacturer.id}/model/{manufacturer.id}/year/{year}` :  `/api/manufacturer/{manufacturer.id}/model/year/`
  //   );
  //   return Promise.resolve(res.data);
  // };

  const GetAllVehicleMakes = async (): Promise<string[]> => {
    const res = await axiosInstance.get(`/api/VehicleTypes/GetAllVehicleMakes`);

    return Promise.resolve(res.data);
  };

  const GetVehicleModel = async (make: string): Promise<string[]> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/GetVehicleModel`,
      null,
      {
        params: { make: make },
      }
    );

    return Promise.resolve(res.data);
  };

  const GetVehicleYear = async (
    make: string,
    model: string
  ): Promise<number[]> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/GetVehicleYear`,
      null,
      {
        params: { make: make, model: model },
      }
    );

    return Promise.resolve(res.data);
  };

  return {
    GetAllVehicleMakes,
    GetVehicleModel,
    GetVehicleYear,
  };
};

const vehicleTypeLib = vehicleTypesLib();
export { vehicleTypeLib };
