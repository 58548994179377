import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      // themeCache: {
      //   get: key => localStorage.getItem(key),
      //   set: (key, value) => localStorage.setItem(key, value),
      // },
    },
    themes: {
      light: {
        primary: "#010D76",
        secondary: "#2B2B2B",
        accent: "#231F1E",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#E67E22",
        // background: "#F2FCFD",
        background: {
          base: "#FFF", // TODO: New Back ground # EBEBEB
          lighten1: "#d4f6fa",
          darken1: "#d1dfe0",
        },
        navDrawer: "#000003",
        menuItem: "#fff",
        footer: "#2597B7",
        textPrimary: {
          base: "#000",
        },
        textAccent: {
          base: "#FFF",
        },
      },
    },
  },
});
