
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Nav from "@/store/modules/Nav";
import { Route } from "vue-router";

@Component
export default class BottomNavigation extends Vue {
  get tabs(): {
    to: Record<string, string>;
    text: string;
    icon: string;
    url: string;
    show: boolean;
  }[] {
    return [
      {
        to: {
          name: "",
        },
        text: "Menu",
        icon: "",
        url: "https://img.icons8.com/ios-filled/30/FFFFFF/menu--v6.png",
        show: true,
      },
      {
        to: {
          name: "garage",
        },
        text: "My Garage",
        icon: "mdi-garage",
        url: "https://img.icons8.com/external-kiranshastry-solid-kiranshastry/30/FFFFFF/external-garage-automobile-kiranshastry-solid-kiranshastry.png",
        show: true,
      },
      {
        to: {
          name: "cars_&_parts",
        },
        text: "Cars & Parts",
        icon: "",
        url: "https://img.icons8.com/material-sharp/30/FFFFFF/shopping-bag.png",
        show: true,
      },
      {
        to: {
          name: "videos",
        },
        text: "Videos",
        url: "https://img.icons8.com/ios-filled/30/FFFFFF/video.png",
        icon: "",
        show: true,
      },
      {
        to: {
          name: "avatar",
        },
        text: "Avatar",
        url: "https://img.icons8.com/fluency-systems-filled/30/FFFFFF/porsche-911-new.png",
        icon: "",
        show: true,
      },
    ];
  }
  get canCalendar() {
    if (
      AuthModule.getProfile &&
      AuthModule.getProfile.companyDetails &&
      AuthModule.getProfile.companyDetails.companyType
    ) {
      const temp = Nav.getSystemCompanyTypes.find(
        (e) =>
          AuthModule.getProfile &&
          AuthModule.getProfile.companyDetails &&
          AuthModule.getProfile.companyDetails.companyType &&
          e.id == AuthModule.getProfile.companyDetails.companyType
      );
      if (temp && temp.description.includes("Mechanic")) return true;
    }

    return false;
  }

  goTo(name: string) {
    this.$router.push({ name: name });
  }
  flipDrawer(): void {
    // console.log("Flip drawer  to: ", !this.appDrawer);
    Nav.setAppDrawer(!this.appDrawer);
  }
  get appDrawer(): boolean {
    return Nav.getAppDrawer;
  }
  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }
}
