
import { Component, Vue } from "vue-property-decorator";
import { axiosNoAuth, generateMessageFromError } from "@/plugins/axios";
import { NotificationLib } from "@/helpers/index";
import Recaptcha2 from "@/components/Recaptcha2.vue";

@Component({
  components: {
    Recaptcha2,
  },
})
export default class ForgotPassword extends Vue {
  loading = false;
  reveal = false;
  valid = false;
  validReset = false;
  resetBusy = false;
  email = null;
  error = {
    open: false,
    message: "",
    code: "",
  };

  rules = {
    required: (v: string) => !!v || "Cannot be empty",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Requires atleast 1 UPPERCASE character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Requires atleast 1 NUMBER character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Requires atleast 1 lowercase character";
    },
    email: (v: string) => {
      const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Valid email required";
    },
  };
  close() {
    this.email = null;
    this.$emit("goToLogin");
  }

  async submitForgotEmail(): Promise<void> {
    this.validReset == true;
    try {
      this.loading = true;
      await axiosNoAuth.post(`api/Login/ForgotPassword?email=${this.email}`);
      if (this.$refs.forgotPasswordForm) {
        (this.$refs.forgotPasswordForm as any).reset();
      }
      NotificationLib.createSuccessNotification(
        "Reset Link Sent to Your Email Address. (If email not found check Junk or Spam folder)"
      );
      this.close();
      return Promise.resolve();
    } catch (err) {
      this.validReset = false;
      const message = generateMessageFromError(err);
      NotificationLib.createErrorNotification(message);
    } finally {
      this.loading = false;
    }
  }
}
