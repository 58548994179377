
import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Vehicle Block Chain Management",
    meta: [
      {
        name: "description",
        content: "General Information about ISCARTI.",
      },
      {
        property: "og:description",
        content: "General Information about ISCARTI.",
      },
    ],
  },
})
export default class Landing extends Vue {
  slides = [
    {
      icon: "mdi-clipboard-text-clock-outline",
      title: "",
      content: "Vehicle Maintenance history on a Blockchain of storage.",
    },
    {
      icon: "mdi-lock-outline",
      title: "",
      content:
        "An immutable ledger that can only be accessed by members with permission. A Trustless network that is able to hold records that can’t be altered with end to end encryption.",
    },
    {
      icon: "mdi-account-lock-outline",
      title: "",
      content:
        "ISCARTI offers a solution that allows users to safely store and manage their vehicle maintenance history digitally for their own personal record.",
    },
    {
      icon: "mdi-car-clock",
      title: "",
      content: "Add your vehicles Service Records.",
    },
    {
      icon: "mdi-account-cash-outline",
      title: "",
      content: "Buy and Sell anything relating to Cars – Videos and Photo’s.",
    },
    {
      icon: "mdi-store-search-outline",
      title: "",
      content: "Find your local business.",
    },
    {
      icon: "mdi-cart-plus",
      title: "",
      content: "Buy our IsCarTi Products.",
    },
    {
      icon: "mdi-car-multiple",
      title: "",
      content: "Add your vehicle contents.",
    },
  ];
}
