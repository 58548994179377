
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { fleetLib } from "@/helpers/fleet";
import dayjs from "dayjs";
import { VehicleProfile } from "@/models/vehicle";
import { Driver } from "@/models/employee";
import { vehicleLib } from "@/helpers/vehicle";
import { NotificationLib } from "@/helpers";
import { generateMessageFromError } from "@/plugins/axios";
// import { VehicleProfile } from "@/models";
// import ImageAttachment from "@/components/fields/ImageAttachment.vue";
// import { VehicleForm } from "@/helpers/classes/Form";

@Component({
  // components: {
  //   ImageAttachment,
  // },
})
export default class DriverModal extends Vue {
  @PropSync("visible") display!: boolean;
  @PropSync("vehicle") datum!: VehicleProfile;
  // @Prop() readonly old!: VehicleProfile;
  newDatum: VehicleProfile | null = null; // This is a bit of a hack to make the view reactive; it is not ideal and must be removed.
  get getVehicle() {
    return this.newDatum || this.datum; // TODO@CVD Hack
  }
  loading = false;
  drivers: Driver[] = [];
  // @Watch("drivers")
  // get getDrivers() {
  //   return this.drivers;
  // }
  assigned: Driver | null = null;
  unassigned: Driver[] = [];
  @Watch("drivers")
  @Watch("newDatum")
  async splitDrivers() {
    // Unassigned
    if (this.drivers) {
      let unassigned = this.drivers.filter((driver) => !driver.deleted);
      if (this.assigned) {
        this.unassigned = this.unassigned.filter(
          (driver) => this.assigned && driver.id !== this.assigned.id
        );
      }
      this.unassigned = unassigned;
    } else {
      this.unassigned = [];
    }
    // Assigned
    if (this.drivers) {
      // console.log("Drivers", this.drivers);
      if (this.getVehicle && this.getVehicle.driverId) {
        let assigned = this.drivers.find((item) => {
          return this.getVehicle.driverId
            ? item.id == this.getVehicle.driverId
            : false;
        });
        // console.log("Assigned Driver", assigned);
        this.assigned = assigned ? assigned : null;
      } else {
        this.assigned = null;
      }
    } else {
      this.assigned = null;
    }
  }

  close(): void {
    this.display = false;
    this.newDatum = null;
    // console.log("Close Drivers");
    this.$emit("close");
  }

  async mounted(): Promise<void> {
    await this.loadDrivers();
  }

  @Watch("datum")
  async loadDrivers(): Promise<void> {
    this.loading = true;
    try {
      //  console.log("Drivers for ", this.datum);
      this.drivers = (await fleetLib.reviewDriver()) as Driver[]; // Returns a list of drivers if no id is specified; cast is to appease the linter
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async assignDriver(vehicle: VehicleProfile, driver: Driver): Promise<void> {
    this.loading = true;
    try {
      const result = await fleetLib.assign(vehicle, driver);
      vehicle = await vehicleLib.VehicleByID(this.getVehicle.id);
      // console.log("Updated Vehicle", vehicle);
      this.newDatum = vehicle;
      // console.log("Updated Vehicle", this.newDatum);
      NotificationLib.createSuccessNotification(result);
      return Promise.resolve();
    } catch (err) {
      const error = generateMessageFromError(err);
      NotificationLib.createErrorNotification(error);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
