
import { Component, Vue } from "vue-property-decorator";
import { NotificationLib, UserLib, VehicleLib } from "@/helpers";
import VechileModule from "@/store/modules/Vehicles";
import { Share as CapShare } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import Auth from "@/store/modules/Auth";

import { SHARE_TYPES } from "@/constant/constants";
import { IShareVehicleWrapped } from "@/models/vehicle";
import fbHook from "@/helpers/firebase";

@Component
export default class ShareVehicleViaLink extends Vue {
  form = { shareable: true, shareType: 1 };
  loading = false;
  reasons = SHARE_TYPES;
  get getProfile() {
    return UserLib.getProfile;
  }
  close(): void {
    this.display = false;
  }
  get vehicle() {
    return VechileModule.getInteracted.item;
  }

  get display() {
    return VechileModule.getInteracted.linkSharing;
  }
  set display(val: boolean) {
    VechileModule.setInteractedFlag({
      key: "linkSharing",
      value: val,
    });
  }
  async generateLink(): Promise<void> {
    // should do the share api call to get the invite link...
    // i dont know what is happening here... Not as discussed
    if (!this.vehicle || !Auth.getProfile) {
      return Promise.resolve();
    }
    let toBeEncrypted: IShareVehicleWrapped = {
      id: Auth.getProfile.userDetails.id,
      vehicleId: this.vehicle.id,
      shareType: this.form.shareType,
      shareable: this.form.shareable,
    };
    const encrypted = btoa(JSON.stringify(toBeEncrypted));

    const link = process.env.VUE_APP_URL + "/vehicle/join/" + encrypted;

    let payload = {
      title: `IsCarTi: ${this.vehicle ? this.vehicle.fullName : ""}`,
      text:
        this.vehicle.fullName +
        " has been shared with you. Click the link to add the vehicle to your shared vehicle list.",
      url: link,
    };

    if (Capacitor.isNativePlatform()) {
      await CapShare.share({
        ...payload,
        dialogTitle: "Share vehicle profile with another party",
      });
    } else {
      await navigator.share(payload);
    }
    fbHook.logEvent(3, {
      method: "Generated Link",
      content_type: "Vehicle Share",
      item_id: this.vehicle.id.toString(),
    });
  }
}
