import { Invoice, Quote } from "@/models/documents";
import { Driver, Employee } from "@/models/employee";
import { JobCard } from "@/models/services";
import { AuthToken } from "@/models/user";
import { VehicleProfile } from "@/models/vehicle";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";

const EmployeeLib = () => {
  const registerEmployee = async (employee: Employee): Promise<any> => {
    const res = await axiosInstance
      .post(`/api/register/CreateEmployees`, employee)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const confirmEmployee = async (data: {
    id: string;
    email: string;
  }): Promise<any> => {
    const res = await axiosNoAuth
      .get(`/api/Register/AcceptUser`, {
        params: data,
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const authenticateEmployee = async (val: {
    username: string;
    password: string;
  }): Promise<AuthToken> => {
    const res = await axiosNoAuth
      .post(`/api/Login/UserLinkLogin`, val)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  return {
    registerEmployee,
    confirmEmployee,
    authenticateEmployee,
  };
};

const employeeLib = EmployeeLib();
export { employeeLib };
