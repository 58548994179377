
import { Quote, QuoteGroup, QuoteItem } from "@/models/documents";
import Auth from "@/store/modules/Auth";
import { Component, PropSync, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { VForm } from "vuetify/lib/components";

@Component({}) // TODO@CVD Consider sub-classing HTMLFormElement ?
export default class QuoteForm extends VForm {
  @PropSync("record") datum!: Quote | null; //   @PropSync("record") datum!: Quote | null; versus   @PropSync("record") datum: Quote | null = null;
  // form: QuoteForm | null = null;
  valid = false;

  get display() {
    return this.datum !== null;
  }

  state = { expiryDate: false };
  // form = { openDate: false };
  stage = 1;

  rules = {
    required: (v: string) => !!v || "Please provide a value",
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Please use atleast 1 numeric character";
    },
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null
        ? value.length <= 10
        : "" || "Please provide a contact number",
  };

  workItemHeaders: DataTableHeader[] = [
    {
      text: "Product",
      value: "product",
    },
    {
      text: "Description",
      value: "description",
    },
    {
      text: "Quantity",
      value: "qty",
    },
    {
      text: "Unit Price",
      value: "price",
    },
    {
      text: "Amount",
      value: "amount",
    },
    {
      text: "",
      value: "actions",
    },
  ];

  createGroup(
    group: QuoteGroup = {
      heading: "",
      lineItems: [],
    }
  ) {
    // this.datum?.groupJobs.push(group);
    // this.datum?.groupJobs = [..this.datum?.groupJobs, group];
    // return await this.updateQuote(quote);
    try {
      if (this.datum) {
        this.$set(this.datum.groupJobs, this.datum.groupJobs.length, group);
      }
    } catch (err) {
      console.log(err);
    }
  }

  deleteQuoteGroup(group: QuoteGroup) {
    if (this.datum)
      this.datum.groupJobs.splice(this.datum.groupJobs.indexOf(group), 1);
    // return await this.updateQuote(quote);
  }

  vatCheck(src: number): number {
    var noVat = 0;

    if (Auth.getProfile != null && Auth.getProfile.companyDetails != null) {
      if (
        Auth.getProfile.companyDetails.vatNo != "" ||
        Auth.getProfile.companyDetails.vatNo != null ||
        Auth.getProfile.companyDetails.vatNo != undefined ||
        Auth.getProfile.companyDetails.vatNo
      ) {
        return src;
      } else {
        return noVat;
      }
    } else {
      return noVat;
    }
  }

  createItem(
    group: QuoteGroup,
    item: QuoteItem = {
      product: "",
      description: "",
      qty: null,
      price: 0.0,
      vat: this.vatCheck(process.env.SARS_VAT || 15),
    }
  ): void {
    // group.lineItems.push(item);
    try {
      this.$set(group.lineItems, group.lineItems.length, item); // TODO@MS: Can we chat about this code when you get a gap.
    } catch (err) {
      console.log(err);
    }
  }

  deleteItem(group: QuoteGroup, item: QuoteItem) {
    // group.lineItems.push(item);
    try {
      // this.$set(group.lineItems, group.lineItems.length, item);
      // this.$set(vm.items.splice(newLength));
      group.lineItems.splice(group.lineItems.indexOf(item), 1);
    } catch (err) {
      console.log(err);
    }
  }

  cancel() {
    this.$emit("cancel", this.datum);
    this.datum = null;
  }
  updateDescription(item: QuoteItem) {
    if (item.description == "") item.description = item.product;
  }
  submit() {
    this.$emit("submit", this.datum);
    this.datum = null;
  }
}
