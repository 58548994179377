
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import dayjs, { Dayjs } from "dayjs";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Device } from "@capacitor/device";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import { AvatarForm } from "@/helpers/classes/Form";
import { vehicleTypeLib } from "@/helpers/vehicleTypes";
import { ImageFile } from "@/models/files";
import { Image } from "@/helpers/classes/Files";
import { fileToBase64 } from "@/utilities";
import { AILib, NotificationLib, VehicleLib } from "@/helpers";
import { Vehicle, VehicleProfile, VehicleProfiles } from "@/models/vehicle";
import { generateMessageFromError } from "@/plugins/axios";
import fbHook from "@/helpers/firebase";
import { Share as CapShare } from "@capacitor/share";
import Loader from "@/store/modules/Loader";

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Avatar",
    meta: [
      {
        name: "description",
        content: "ISCARTI Avatar.",
      },
      {
        property: "og:description",
        content: "ISCARTI Avatar.",
      },
    ],
  },
  components: {
    ImageAttachment,
  },
})
export default class Avatar extends Vue {
  warningModal = false;
  warningAction = "nav";
  warningActionRouteName = "";
  scrolled = 0;
  item: ImageFile | null = null;
  form: AvatarForm | null = null;
  valid = false;
  makes: string[] = [];
  models: string[] = [];
  years: number[] = [];
  panel: number | undefined = 0;
  generateFromList = [
    "Existing Vehicle",
    "Non-Existing Vehicle",
    "Text To Image",
  ];
  generated = false;
  useProfilePic = true;
  useProfilePicDisabled = false;
  populatedInitially = false;
  existingVehicles: VehicleProfile[] = [];
  existingVehicle: VehicleProfile | null = null;
  slideshow = 0;
  results: {
    image: string[];
    info: string;
    details: string;
    savedToVehicle: boolean;
  }[] = [];
  platform: "ios" | "android" | "web" = "web";
  rules = {
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (v: { size: number }): boolean | string =>
      !v || v.size < 50000000 || "Max File Size is 50MB",
  };

  async saveToVehicle(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      if (this.existingVehicle) {
        this.existingVehicle.vehicleAvatar = {
          fileName: this.useProfilePic
            ? this.existingVehicle.profilePicture.fileName
            : "newUpload",
          fileSize: 0,
          mimeType: "image/png",
          upload: this.results[this.slideshow].image[0],
          description: "",
        };
        /* this.existingVehicle.vehicleAvatar!.upload =
          "data:image/png;base64," + this.results[this.slideshow].image[0]; */
        await VehicleLib.UpdateVehicle(this.existingVehicle as Vehicle);
        await this.getExistingVehicles();
        NotificationLib.createSuccessNotification("Saved Avatar to Vehicle");
        this.results.map((vehicle) => (vehicle.savedToVehicle = false));
        this.results[this.slideshow].savedToVehicle = true;
      }
      Promise.resolve();
    } catch (err) {
      Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async saveAsProfilePic(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      if (this.existingVehicle) {
        this.existingVehicle.profilePicture = {
          fileName: this.useProfilePic
            ? this.existingVehicle.profilePicture.fileName
            : "newUpload",
          fileSize: this.results[this.slideshow].image[0].length,
          mimeType: "image/png",
          upload: this.results[this.slideshow].image[0],
        };
        // console.log("exitsing vehicle", this.existingVehicle);
        /* this.existingVehicle.vehicleAvatar!.upload =
          "data:image/png;base64," + this.results[this.slideshow].image[0]; */
        await VehicleLib.UpdateVehicle(this.existingVehicle);
        await this.getExistingVehicles();
        NotificationLib.createSuccessNotification(
          "Saved As Vehicle Profile Picture"
        );
      }
      Promise.resolve();
    } catch (err) {
      Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async generate(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      var base64: string | null = null;
      if (this.form) {
        if (
          this.form.form.upload &&
          this.form.form.generateFrom == "Non-Existing Vehicle"
        ) {
          /* ${this.form.form.color}, */
          var prompt = `${this.form.form.year}, ${this.form.form.make}, ${this.form.form.model}`;
          base64 = await fileToBase64(this.form.form.upload as File);
          const AIres = await AILib.GetImg2Img(prompt, base64);
          //  console.log("AIres : ", AIres);
          //this.results = [];
          this.results.unshift({
            details: prompt.replaceAll(",", ""),
            image: AIres.image,
            info: AIres.info,
            savedToVehicle: false,
          });
          this.panel = undefined;
        }

        if (
          this.form.form.generateFrom == "Existing Vehicle" &&
          this.existingVehicle
        ) {
          if (this.useProfilePic == true) {
            const AIVehicleRes = await AILib.GetImg2ImgWithVehicle(
              this.existingVehicle.id,
              this.existingVehicle.profilePicture.upload
            );
            // console.log("AIVehicleRes : ", AIVehicleRes);
            //this.results = [];
            this.results.unshift({
              details: this.existingVehicle.fullName,
              image: AIVehicleRes.image,
              info: AIVehicleRes.info,
              savedToVehicle: false,
            });
            this.panel = undefined;
          } else if (this.useProfilePic == false && this.form.form.upload) {
            base64 = await fileToBase64(this.form.form.upload as File);
            const AIVehicleRes = await AILib.GetImg2ImgWithVehicle(
              this.existingVehicle.id,
              base64
            );
            // console.log("AIVehicleRes : ", AIVehicleRes);
            //this.results = [];
            this.results.unshift({
              details: this.existingVehicle.fullName,
              image: AIVehicleRes.image,
              info: AIVehicleRes.info,
              savedToVehicle: false,
            });
            this.panel = undefined;
          }
        }

        if (
          this.form.form.generateFrom == "Text To Image" &&
          this.form.form.prompt
        ) {
          const AIVehicleRes = await AILib.GetTxt2Img(this.form.form.prompt);
          // console.log("AIVehicleRes : ", AIVehicleRes);
          //this.results = [];
          this.results.unshift({
            details: this.form.form.prompt,
            image: AIVehicleRes.image,
            info: AIVehicleRes.info,
            savedToVehicle: false,
          });
          this.panel = undefined;
        }
        fbHook.logEvent(
          4,
          AuthModule.getProfile
            ? { userId: AuthModule.getProfile?.userDetails.id }
            : null
        );
        this.generated = true;
        this.populatedInitially = false;
        return Promise.resolve();
      }
    } catch (err) {
      // console.log("AI Upload Err: ", err);
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async getPlatform(): Promise<void> {
    const temp = await Device.getInfo();
    Promise.resolve();
    this.platform = temp.platform;
  }

  async download(): Promise<void> {
    const devInfo = await Device.getInfo();
    if (devInfo.platform == "web") {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.download = this.results[this.slideshow].details;
      a.href = `data:image/png;base64,${this.results[this.slideshow].image[0]}`;
      a.click();
    } else {
      const writeResp = await Filesystem.writeFile({
        path: this.results[this.slideshow].details + ".png",
        data: this.results[this.slideshow].image[0],
        directory: Directory.Library,
      });

      let payload = {
        title: this.results[this.slideshow].details + ".png",
        files: [writeResp.uri],
      };
      await CapShare.share({
        ...payload,
        dialogTitle: "Download Image",
      });
    }
  }

  async generateLinkWeb(): Promise<void> {
    const base64 = `data:image/png;base64,${
      this.results[this.slideshow].image[0]
    }`;
    /* FILE CONSTRUCT */
    const filetype = "image/png";
    const int8Array = await fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buffer) => new Uint8Array(buffer));
    const file = new File(
      [new Blob([int8Array], { type: filetype })],
      this.results[this.slideshow].details + ".png",
      {
        type: filetype,
      }
    );
    let payload = {
      title: this.results[this.slideshow].details + ".png",
      files: [file],
    };

    await navigator.share(payload);
  }

  next() {
    if (this.slideshow != this.results.length - 1) {
      this.slideshow = this.slideshow + 1;
    }
  }
  prev() {
    if (this.slideshow != 0) {
      this.slideshow = this.slideshow - 1;
    }
  }

  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    // console.log("Has changes");
    if (this.form && this.form.avatar) {
      this.form.compare();
    }
  }
  @Watch("existingVehicle")
  async onExistingVehicleChanges() {
    if (
      this.existingVehicle &&
      (this.existingVehicle.profilePicture.upload == "" ||
        this.existingVehicle.profilePicture.upload == null ||
        this.existingVehicle.profilePicture.upload == undefined)
    ) {
      this.useProfilePicDisabled = true;
      this.useProfilePic = false;
    } else {
      this.useProfilePicDisabled = false;
    }
  }
  @Watch("form.form.generateFrom")
  async onGenerateFromChanges() {
    if (this.form && this.form.form.generateFrom && this.results.length > 0) {
      if (this.populatedInitially == false) {
        this.warningAction = "generateFrom";
        this.warningModal = true;
      }
    } else if (
      this.form &&
      this.results.length == 0 &&
      this.form.form.generateFrom == "Existing Vehicle"
    ) {
      await this.getExistingVehicles();
    }
  }

  async continueAction(action: string, routeName: string): Promise<void> {
    if (action == "nav") {
      this.warningModal = false;
      this.$router.push({ name: routeName });
    }
    if (action == "generateFrom") {
      this.results = [];
      if (this.form) this.form.reset();
      if (
        this.form &&
        this.results.length == 0 &&
        this.form.form.generateFrom == "Existing Vehicle"
      ) {
        await this.getExistingVehicles();
      }
      this.warningModal = false;
    }
  }

  async closeAction(action: string): Promise<void> {
    if (action == "generateFrom") {
      if (this.form && this.form.form.generateFrom == "Existing Vehicle") {
        this.form.form.generateFrom = "Non-Existing Vehicle";
      }
      if (this.form && this.form.form.generateFrom == "Non-Existing Vehicle") {
        this.form.form.generateFrom = "Existing Vehicle";
      }
    }
    this.warningModal = false;
  }
  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }

  makesFilter(item: string, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      item != null &&
      item.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }

  modelsFilter(item: string, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      item != null &&
      item.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }
  get getPreview() {
    if (this.item) {
      return `data:${this.item.mimeType};base64,${this.item.upload}`;
    } else {
      return null;
    }
  }

  async previewProfilePic() {
    if (this.form) {
      // console.log("Gen image", this.form.form.upload);
      this.item = await Image.init(
        this.form.form.upload ? (this.form.form.upload as File) : undefined
      );

      return Promise.resolve();
    }
  }

  async getAllVehicleMakes(): Promise<void> {
    try {
      this.makes = await vehicleTypeLib.GetAllVehicleMakes();
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    }
  }

  async getVehicleModels(): Promise<void> {
    try {
      if (this.form && this.form.form && this.form.form.make)
        this.models = await vehicleTypeLib.GetVehicleModel(this.form.form.make);
      // console.log(this.models);
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    }
  }

  async getVehicleYears(): Promise<void> {
    try {
      if (this.form && this.form.form.make && this.form.form.model) {
        this.years = await vehicleTypeLib.GetVehicleYear(
          this.form.form.make,
          this.form.form.model
        );
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async mounted(): Promise<void> {
    //  console.log("Route :, ", this.$route);

    await this.getPlatform();
    if (this.$route.params.vehicle) {
      await this.getExistingVehicles();
      var vehicle = this.existingVehicles.find(
        (vehicle) => vehicle.id == Number(this.$route.params.vehicle)
      );
      //console.log("Vehicle from Route params :", vehicle);
      if (vehicle && this.form) {
        this.populatedInitially = true;
        this.form.form.generateFrom = "Existing Vehicle";
        this.existingVehicle = vehicle;
        if (vehicle.vehicleAvatarVM && vehicle.vehicleAvatarVM.data != "") {
          this.results.unshift({
            details: this.existingVehicle.fullName,
            image: [
              vehicle.vehicleAvatarVM
                ? vehicle.vehicleAvatarVM.data
                  ? vehicle.vehicleAvatarVM.data
                  : ""
                : "",
            ],
            info: "",
            savedToVehicle: true,
          });
          this.generated = true;
        }
      }
    }
    var currentYear = dayjs().year(),
      years = [];
    var startYear = 1950;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    this.years = years.sort(function (a, b) {
      return b - a;
    });

    await this.getAllVehicleMakes();

    fbHook.logEvent(0, null);
  }

  async getExistingVehicles() {
    try {
      if (AuthModule.isLoggedIn == false) return Promise.resolve();
      var res: VehicleProfiles | null = null;

      if (AuthModule.getProfile) {
        res = await VehicleLib.Vehicles({
          searchString: "",
          filterOnList: 0,
          ...{
            page: 1,
            itemsPerPage: -1,
            sortBy: ["fullName"],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: false,
          },
        });
        if (res.vehicles == null) {
          this.existingVehicles = [];
        } else {
          this.existingVehicles = res.vehicles.map((e) => {
            const temp = e;
            return temp;
          });
        }
        //console.log("Existing Vehicles :", this.existingVehicles);
      }

      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve();
    }
  }

  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }

  get getYear() {
    return dayjs().format("YYYY");
  }

  created(): void {
    this.form = new AvatarForm({
      generateFrom: "",
      prompt: "",
      make: "",
      model: "",
      upload: null,
      year: Number(this.getYear),
    });

    this.$router.beforeEach((to, from, next) => {
      // console.log("Route CHANGED", to);
      if (to.name == "avatar") {
        next();
      } else {
        if (
          this.results.length > 0 &&
          this.warningActionRouteName == "" &&
          this.populatedInitially == false
        ) {
          this.warningAction = "nav";
          if (to.name) this.warningActionRouteName = to.name;
          this.warningModal = true;
        } else {
          // console.log("else");
          next();
        }
      }
    });
    /* window.addEventListener("scroll", this.handleScroll); */
    /* window.scrollBy(0, 0); */
  }

  /* unmounted(): void {
    window.removeEventListener("scroll", this.handleScroll);
  } */

  /* handleScroll(): void {
    this.scrolled = window.scrollY;
  } */
}
