
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { vehicleTypeLib } from "@/helpers/vehicleTypes";
import dayjs from "dayjs";
import { VehicleCreate, VehicleProfile } from "@/models/vehicle";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import { VehicleForm } from "@/helpers/classes/Form";
import Auth from "@/store/modules/Auth";
import { NotificationLib, VehicleLib, AILib } from "@/helpers";
import Nav from "@/store/modules/Nav";
import { fileToBase64 } from "@/utilities";

@Component({
  components: {
    ImageAttachment,
  },
})
export default class VehicleModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly vehicle!: VehicleProfile | undefined;
  form: VehicleForm | null = null;
  valid = false;
  makes: string[] = [];
  models: string[] = [];
  years: number[] = [];
  canvas: HTMLCanvasElement | null = null;
  canvasC: CanvasRenderingContext2D | null = null;
  rectWidth = 100;
  rectHeight = 50;
  rectX = 10;
  rectY = 10;
  convertedWidth: number | null = null;
  convertedHeight: number | null = null;
  convertedX: number | null = null;
  convertedY: number | null = null;
  imgWidth: number | undefined = 0;
  imgHeight: number | undefined = 0;
  imgSRC: string | null = null;
  generated = false;
  generatedImage: string | null = null;
  useBGRemoval = false;
  loading = false;
  loadingCutOut = false;
  passesDimensionCheck = true;
  dimensionCheckMessage = "";
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    /* minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300", */
    minFileDimensions: (value: File): boolean | string =>
      !value ||
      this.dimensionCheck(value) == true ||
      "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
  };

  /*  getDimensionCheck(v: File): boolean {
    console.log(v);
    this.onChange(v);
    return this.passesDimensionCheck;
  } */

  /* getDimensionErrorMessage(): string {
    return this.dimensionCheckMessage;
  } */

  dimensionCheck(v: File): boolean {
    let reader = new FileReader();
    reader.readAsDataURL(v);
    reader.onload = (evt: any) => {
      let img = new Image();
      img.src = evt.target.result;
      const meta = (img.onload = () => {
        console.log({
          width: img.width,
          height: img.height,
        });
        return {
          width: img.width,
          height: img.height,
        };
      });

      if (meta().width >= 500 && meta().height >= 300) {
        this.passesDimensionCheck = true;

        /* this.dimensionCheckMessage = ""; */
      } else {
        this.passesDimensionCheck = false;

        /* this.dimensionCheckMessage = `Min File Dimensions are 500x300. Uploaded Image Dimensions: ${
          meta().width
        } x ${meta().height}`;*/
      }
    };
    /* (this.$refs.form as any).validate(); */
    console.log("passesCheck", this.passesDimensionCheck);
    return this.passesDimensionCheck;
  }

  makesFilter(item: string, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      item != null &&
      item.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }
  @Watch("open")
  onOpenChanged(val: boolean, old: boolean) {
    if (val == true) {
      this.form = new VehicleForm(this.vehicle);
      if (this.form && this.vehicle && this.vehicle.vehicleCutOutVM) {
        this.generated = true;
        this.generatedImage = `data:${this.vehicle.vehicleCutOutVM.mimeType};base64,${this.vehicle.vehicleCutOutVM.data}`;
        this.form.form.vehicleCutOut = {
          fileName: this.vehicle.vehicleCutOutVM.fileName
            ? this.vehicle.vehicleCutOutVM.fileName
            : "",
          fileSize: this.vehicle.vehicleCutOutVM.fileSize
            ? this.vehicle.vehicleCutOutVM.fileSize
            : 0,
          mimeType: this.vehicle.vehicleCutOutVM.mimeType
            ? this.vehicle.vehicleCutOutVM.mimeType
            : "",
          upload: this.vehicle.vehicleCutOutVM.data
            ? this.vehicle.vehicleCutOutVM.data
            : "",
          description: "",
        };

        //this.form.form.vehicleCutOut!.upload = this.generatedImage;
      }
    } else {
      if (this.form) this.form.reset();
    }
  }
  @Watch("passesDimensionCheck")
  onPassesDimensionCheckChanged() {
    (this.$refs.form as any).validate();
  }
  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form && this.form.vehicle) {
      this.form.compare();
    }
    // this.unchanged = false;
  }
  @Watch("form.form.profilePicture", { immediate: true, deep: true })
  onProfilePictureChanges() {
    if (this.form) {
      // console.log("Profile Picture Changed:", this.form.form.profilePicture);
      if (
        this.form.form.profilePicture &&
        this.form.form.profilePicture.upload != "" &&
        this.form.form.profilePicture.upload
      ) {
        //await this.onChange();
        // console.log("Profile Picture Has Value");
        this.imgSRC = `data:${this.form.form.profilePicture.mimeType};base64,${this.form.form.profilePicture.upload}`;
        const img = new Image();
        img.src = this.imgSRC;
        img.onload = () => {
          this.drawCanvas(img);
        };
      } else {
        this.imgSRC = null;
        //console.log("Profile Picture Has NO Value");
      }
    }
  }

  drawCanvas(img: HTMLImageElement) {
    this.imgHeight = img.naturalHeight;
    this.imgWidth = img.naturalWidth;
    if (this.canvas && this.canvasC) {
      var w = (3 / 2.54) * 300; // cm -> inch x PPI
      var h = (1.5 / 2.54) * 300;
      this.canvas.width = w | 0; // actual bitmap size, |0 cuts fractions
      this.canvas.height = h | 0;
      this.canvas.style.width = "300px"; // size in pixel for screen use
      this.canvas.style.height = "150px";
      var ratioX = this.canvas.width / this.imgWidth;
      var ratioY = this.canvas.height / this.imgHeight;
      var ratio = Math.min(ratioX, ratioY);
      this.canvasC.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvasC.drawImage(
        img,
        0,
        0,
        this.imgWidth ? this.imgWidth * ratio : 300,
        this.imgHeight ? this.imgHeight * ratio : 150
      );

      this.canvasC.beginPath();
      this.canvasC.rect(
        this.rectX,
        this.rectY,
        this.rectWidth,
        this.rectHeight
      );
      this.canvasC.lineWidth = 1;
      this.canvasC.strokeStyle = "red";
      this.canvasC.stroke();

      this.convertedWidth = Math.round(this.rectWidth / ratio);
      this.convertedHeight = Math.round(this.rectHeight / ratio);
      this.convertedX = Math.round(this.rectX / ratio);
      this.convertedY = Math.round(this.rectY / ratio);
      /*  console.log(
        "convertedWidth: ",
        this.convertedWidth,
        "convertedHeight: ",
        this.convertedHeight,
        "convertedX: ",
        this.convertedX,
        "convertedY: ",
        this.convertedY
      ); */
    }
  }

  async getCutOut(): Promise<void> {
    try {
      this.loadingCutOut = true;
      const res = await AILib.GetImageCutOut(
        this.convertedX,
        this.convertedY,
        this.convertedHeight,
        this.convertedWidth,
        this.imgSRC
      );
      //  console.log("CutOut Res: ", res);
      this.generatedImage = res.image;
      if (
        this.form &&
        this.generatedImage &&
        this.vehicle &&
        this.vehicle.vehicleCutOutVM
      ) {
        var splitDataUpdate = this.generatedImage.split(",");
        //console.log("splitData", splitDataUpdate);
        this.form.form.vehicleCutOut = {
          fileName: this.vehicle.vehicleCutOutVM.fileName
            ? this.vehicle.vehicleCutOutVM.fileName
            : "",
          fileSize: this.vehicle.vehicleCutOutVM.fileSize
            ? this.vehicle.vehicleCutOutVM.fileSize
            : 0,
          mimeType: "image/png",
          upload: splitDataUpdate[1],
          description: "",
        };
        this.generated = true;
      } else if (this.form && this.generatedImage) {
        var splitDataAdd = this.generatedImage.split(",");
        //console.log("splitData", splitDataAdd);
        this.form.form.vehicleCutOut = {
          fileName: this.form.form.profilePicture.fileName,
          fileSize: 0,
          mimeType: "image/png",
          upload: splitDataAdd[1],
          description: "",
        };
        this.generated = true;
      }
      Promise.resolve();
    } catch (err) {
      Promise.reject(err);
    } finally {
      this.loadingCutOut = false;
    }
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }
  get getCompanyType() {
    return Nav.getSystemCompanyTypes;
  }
  get getMechanicRole() {
    const role = Nav.getSystemCompanyTypes.find(
      (e) => e.description == "Mechanic"
    );
    if (role) return role.id;
    return null;
  }
  modelsFilter(item: string, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      item != null &&
      item.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }
  get getProfile() {
    return Auth.getProfile;
  }
  close(): void {
    this.modal = false;
    this.imgSRC = null;
    this.rectWidth = 100;
    this.rectHeight = 50;
    this.rectX = 10;
    this.rectY = 10;
    this.imgWidth = 0;
    this.imgHeight = 0;
    this.generatedImage = null;
    this.generated = false;
    this.useBGRemoval = false;
    this.passesDimensionCheck = true;
    // if (this.data.type == "add") {
    //   const refForm: any = this.$refs.registerForm;
    //   refForm.reset();
    // }
    // this.$emit("refresh");
    // this.modal = false;
  }

  async mounted(): Promise<void> {
    // console.log("Mounted.");

    var canvas = document.getElementById("myCanvas") as HTMLCanvasElement;
    if (canvas) {
      this.canvas = canvas;
      this.canvasC = canvas.getContext("2d");
    }
    this.onProfilePictureChanges();
    //console.log("CANVAS", this.canvas, this.canvasC);
    var currentYear = dayjs().year(),
      years = [];
    var startYear = 1950;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    this.years = years.sort(function (a, b) {
      return b - a;
    });

    await this.getAllVehicleMakes();
  }

  async getAllVehicleMakes(): Promise<void> {
    try {
      this.makes = await vehicleTypeLib.GetAllVehicleMakes();
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    }
  }

  async getVehicleModels(): Promise<void> {
    try {
      if (this.form && this.form.form.make)
        this.models = await vehicleTypeLib.GetVehicleModel(this.form.form.make);
      // console.log(this.models);
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    }
  }

  async getVehicleYears(): Promise<void> {
    try {
      if (this.form && this.form.form.make && this.form.form.model) {
        this.years = await vehicleTypeLib.GetVehicleYear(
          this.form.form.make,
          this.form.form.model
        );
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        if (
          this.form.form.vehicleAvatar &&
          (this.form.form.vehicleAvatar.upload == "" ||
            this.form.form.vehicleAvatar.upload == undefined ||
            this.form.form.vehicleAvatar.upload == null)
        ) {
          this.form.form.vehicleAvatar = null;
        }
        if (this.useBGRemoval == true) {
          this.form.form.vehicleCutOut = null;
        }
        await this.form.submit();
      }
      this.close();
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.$emit("refresh");
    }
  }
}
