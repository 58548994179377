
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { EmployeeLib, NotificationLib, UserLib } from "@/helpers";
import { Profile } from "@/models/user";
import { Device } from "@capacitor/device";
import { generateMessageFromError } from "@/plugins/axios";
import AuthModule from "@/store/modules/Auth";

// TODO :
// Validate e-mail first; Make this lazy
// Provide Password/Confirm Password -> Trigger login
// Push to business to inform them when user signs up

@Component
export default class ConfirmPasswordCard extends Vue {
  @Prop() profile!: Profile;
  valid = false;
  loading = false;
  password = "";
  reveal = false;
  confirmPassword = "";
  // driverProfile: null | Profile = null;
  // get getProfile() {
  //   return this.driverProfile;
  // }
  async submit() {
    this.loading = true;
    try {
      if (this.profile.userDetails.email == null) return Promise.resolve();
      // BEGIN : Duplicates views/Auth/Login.vue
      const res = await EmployeeLib.authenticateEmployee({
        username: this.profile.userDetails.email,
        password: this.password,
      }); // Replaces : const res = await UserLib.login({email: this.email,password: this.password,});
      const valid = await UserLib.processNewJWT(res);
      const device = await Device.getInfo();
      await UserLib.getProfile();
      if (device.platform != "web") {
        // console.log("PLease register here");
        await NotificationLib.registerFirebaseNotifications();
      }
      if (valid) {
        // this.login();
        this.$router.push({ name: "vehicleList" });
      } else {
        NotificationLib.createErrorNotification(
          "Token was not valid. Please reload and try logging in again."
        );
      }
      // END
      // console.log("Verify response: ", res);
      /// Perform Login
    } catch (err) {
      AuthModule.setAuthToken(null);
      const temp = generateMessageFromError(err);
      console.error("Cannot confirm :", err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // @Watch("$route.uuid")
  // async onIDChange(val: string) {
  //   this.driverProfile = await UserLib.getProfile();
  // }
  // async mounted() {
  //   this.driverProfile = await UserLib.getProfile();
  // }
  rules = {
    required: (v: string) => !!v || "Please provide a value",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Please use atleast 1 uppercase character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Please use atleast 1 numeric character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Please use atleast 1 lowercase character";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Please use atleast 1 special character";
    },
    password: (v: string) => {
      return (!!v && v.length >= 8) || "Please use atleast 8 characters";
    },
    matchPass: (v: string) => {
      if (v === this.password) {
        return true;
      } else {
        return "Passwords do not match: ";
      }
    },
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
    cell: (value: string | null = null): boolean | string => {
      if (value != null) {
        if (value.length == 10) return true;
      }
      return "Please provide a contact number";
    },
  };
}
