var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown && _vm.$route.name != 'authentication')?_c('v-app-bar',{staticClass:"black px-0 pb-0 pt-1",class:_vm.mobileAppBarHeight == 100 ? 'pt-5' : 'pt-1',attrs:{"app":"","height":_vm.mobileAppBarHeight}},[_c('v-img',{attrs:{"alt":"ISCARTI Logo","contain":"","width":"100%","src":require('@/assets/logo/IscartiLogo_withouttext.png'),"max-height":"15vh","height":"55"},on:{"click":function($event){return _vm.garage()}}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-app-bar',{staticClass:"py-0",staticStyle:{"border-bottom":"4px solid rgba(214, 214, 214, 0.2) !important"},attrs:{"dark":"","app":"","height":"120","color":"black","elevate-on-scroll":""}},[_c('v-row',{attrs:{"justify":"start","no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"triangle-left"},[_c('div',{staticClass:"inner-triangle"})])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('a',{staticClass:"white--text text-decoration-none",on:{"click":function($event){return _vm.garage()}}},[_c('v-img',{attrs:{"alt":"ICARTI Logo","contain":"","src":require('@/assets/logo/IscartiLogo.png'),"max-height":"140","max-width":"160"}})],1)])],1),_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',{attrs:{"height":"112","background-color":"black","slider-color":"primary","slider-size":"5","fixed-tabs":"","hide-slider":""}},[_c('v-tab',{staticClass:"text-no-wrap fontSize",attrs:{"to":{
              name: 'garage',
            }}},[_vm._v(" My Garage ")]),_c('v-tab',{staticClass:"text-no-wrap fontSize",attrs:{"to":{
              name: 'cars_&_parts',
            }}},[_vm._v(" Cars &Parts ")]),_c('v-tab',{staticClass:"text-no-wrap fontSize",attrs:{"to":{
              name: 'videos',
            }}},[_vm._v(" Videos ")]),_c('v-tab',{staticClass:"text-no-wrap fontSize",attrs:{"to":{
              name: 'avatar',
            }}},[_vm._v(" Avatar ")]),_c('v-btn',{staticClass:"pa-1 ml-1",attrs:{"color":"black align-self-center","elevation":"0"},on:{"click":_vm.flipDrawer}},[_c('img',{attrs:{"src":"https://img.icons8.com/ios-filled/30/FFFFFF/menu--v6.png","alt":"menu--v6"}})])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.logoutModal),callback:function ($$v) {_vm.logoutModal=$$v},expression:"logoutModal"}},[_c('v-card',{staticClass:"pb-5 pt-10"},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"text-h6"},[_vm._v("Are you sure you want to logout?")]),_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary","block":""},on:{"click":_vm.logout}},[_vm._v(" Yes, Logout ")]),_c('v-btn',{attrs:{"color":"error","block":"","outlined":""},on:{"click":function($event){_vm.logoutModal = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }