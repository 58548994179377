const ColorLib = () => {
  const hex2rgba = (hex: string, alpha = 1) => {
    const hexMatch = hex.match(/\w\w/g);
    if (hexMatch) {
      const [r, g, b] = hexMatch.map((x) => parseInt(x, 16));
      // console.log("Temp ", `rgba(${r},${g},${b},${alpha})`);
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };
  return {
    hex2rgba,
  };
};
const colorLib = ColorLib();
export { colorLib };
