import { CompanyType } from "@/models/business";
import { UserRole } from "@/models/user";
import { axiosInstance } from "@/plugins/axios";
import NavModule from "@/store/modules/Nav";

const RolesLib = () => {
  const allRoles = async (): Promise<UserRole[]> => {
    const res = await axiosInstance.get(`/api/roles/getAllRoles`);
    //console.log("Roles ", res.data);
    NavModule.setSystemRoles(res.data);
    return Promise.resolve(res.data);
  };
  const companyTypes = async (): Promise<CompanyType[]> => {
    const res = await axiosInstance.get(`/api/roles/getCompanyTypes`);
    //console.log("Company Types ", res.data);
    NavModule.setSystemCompanyTypes(res.data);
    return Promise.resolve(res.data);
  };
  const userRoles = async (): Promise<UserRole[]> => {
    const res = await axiosInstance.get(`/api/roles/getAllRoles`);
    //console.log("Roles ", res.data);
    return Promise.resolve(res.data);
  };
  return {
    allRoles,
    userRoles,
    companyTypes,
  };
};

const rolesLib = RolesLib();
export { rolesLib };
