/**
 * Converts a File into a Base 64 encoded String
 *
 * Given the File : { name:, type:, size:, }
 */
export function fileToBase64(file: File | Blob): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
export function stringToBase64URL(
  data: string, // ArrayBuffer | null
  type: string
) {
  // return new Promise((resolve, reject) => { // Ideally one should use a promise to ensure a consistent API
  const text = window.atob(data); //.map(char => {String.});
  const arr = new Uint8Array(text.length);
  for (let i = 0; i < text.length; ++i) {
    arr[i] = text.charCodeAt(i);
  }
  const blob = new Blob([arr], { type: type });
  return URL.createObjectURL(blob);
  // });
}
