
import { Component, Vue, Watch } from "vue-property-decorator";
import { VehicleProfile, VehicleProfiles } from "@/models/vehicle";
import ShareVehicleViaLink from "@/views/vehicles/ShareVehicleViaLink.vue";
import ShareVehicleViaEmail from "@/views/vehicles/ShareVehicleViaEmail.vue";
import AttachmentsModal from "@/views/vehicles/ReviewAttachments.vue";
import VehicleModal from "@/views/vehicles/VehicleModal.vue";
import DriverModal from "@/views/vehicles/Drivers.vue";
import AuthModule from "@/store/modules/Auth";
import VehiclesModule from "@/store/modules/Vehicles";
import { NotificationLib, ColorLib, VehicleLib, AILib } from "@/helpers";
import dayjs from "dayjs";
import { axiosInstance, generateMessageFromError } from "@/plugins/axios";
import { filterListVehicle } from "@/enums";
import { fleetLib } from "@/helpers/fleet";
import { fileToBase64 } from "@/utilities";
import fbHook from "@/helpers/firebase";
import Loader from "@/store/modules/Loader";

@Component({
  components: {
    ShareVehicleViaEmail,
    ShareVehicleViaLink,
    VehicleModal,
    DriverModal,
    AttachmentsModal,
  },
  filters: {
    displayDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | My Garage",
  },
})
export default class Garage extends Vue {
  slideshow = 0;
  slideshowShared = 0;
  slideshowHistoric = 0;
  activeList = -1;
  scrolled = 0;
  showMore = false;
  optionsModal = false;
  addEditModal: {
    open: boolean;
    vehicle?: VehicleProfile;
  } = {
    open: false,
  };
  driverModal: {
    visible: boolean;
    vehicle?: VehicleProfile | null;
  } = {
    visible: false,
    vehicle: null,
  };
  deleteModal: {
    open: boolean;
    item: VehicleProfile | null;
  } = {
    open: false,
    item: null,
  };
  profileModal: VehicleProfile | null = null;
  items: {
    data: VehicleProfile;
    expanded: boolean;
    fabEdit: boolean;
    fabShare: boolean;
  }[] = [];
  itemsShared: {
    data: VehicleProfile;
    expanded: boolean;
    fabEdit: boolean;
    fabShare: boolean;
  }[] = [];
  itemsHistoric: {
    data: VehicleProfile;
    expanded: boolean;
    fabEdit: boolean;
    fabShare: boolean;
  }[] = [];
  vehicle: VehicleProfile | null = null;
  profilePicture: Blob | File | null = null;
  validAddProfilePicture = false;
  showAddProfilePicture = false;
  itemsPerPage = [1, 5, 15, 50, -1];
  search = "";
  sortDesc = true;
  options = {
    page: 1,
    itemsPerPage: this.itemsPerPage[2],
    sortBy: ["fullName"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  vehicleHistory: VehicleProfile | null = null;
  filters = [
    {
      text: "Current",
      id: 0,
    },
    {
      text: "Historic",
      id: 1,
    },
    {
      text: "Shared",
      id: 2,
    },
  ];
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
  };

  get getItems() {
    return this.items.filter((e) => e.data.deleted == false);
  }
  get getSharedItems() {
    return this.itemsShared.filter((e) => e.data.deleted == false);
  }
  get getHistoricItems() {
    return this.itemsHistoric.filter((e) => e.data.deleted == false);
  }
  get getProfileModal() {
    return this.profileModal !== null;
  }
  get getUserProfile() {
    return AuthModule.getProfile;
  }
  get availFilterList() {
    if (this.getUserProfile) {
      if (this.getUserProfile.userDetails.userType === "Individual") {
        return this.filters.filter((e) => e.id != 1);
      } else {
        return this.filters;
      }
    }
    return [];
  }

  nextCurrent() {
    if (this.slideshow != this.getItems.length - 1) {
      this.slideshow = this.slideshow + 1;
    }
  }
  nextShared() {
    if (this.slideshowShared != this.getSharedItems.length - 1) {
      this.slideshowShared = this.slideshowShared + 1;
    }
  }
  nextHistoric() {
    if (this.slideshowHistoric != this.getHistoricItems.length - 1) {
      this.slideshowHistoric = this.slideshowHistoric + 1;
    }
  }
  prevCurrent() {
    if (this.slideshow != 0) {
      this.slideshow = this.slideshow - 1;
    }
  }
  prevShared() {
    if (this.slideshowShared != 0) {
      this.slideshowShared = this.slideshowShared - 1;
    }
  }
  prevHistoric() {
    if (this.slideshowHistoric != 0) {
      this.slideshowHistoric = this.slideshowHistoric - 1;
    }
  }

  hex2rgba(hex: string, alpha: number) {
    //  console.log("Hex ", hex);
    return ColorLib.hex2rgba(hex, alpha);
  }

  beforeMount(): void {
    //  console.log("Shared from push", this.$route.query.list);
  }

  @Watch("options", { deep: true })
  @Watch("search")
  async init() {
    try {
      Loader.setLoadingState(true);
      await this.getCurrent();
      await this.getShared();
      await this.getHistoric();
      this.activeList = 0;
      if (this.optionsModal == true) this.optionsModal = false;
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async getCurrent() {
    try {
      if (AuthModule.isLoggedIn == false) return Promise.resolve();
      var res: VehicleProfiles | null = null;
      if (AuthModule.getProfile && AuthModule.getProfile.userDetails.driverId) {
        const vehicles = await fleetLib.driverVehicles(
          AuthModule.getProfile.userDetails.driverId
        );
        res = {
          resultCount: vehicles.length,
          vehicles: vehicles,
        };
        this.items = vehicles.map((e) => {
          const temp = {
            data: e,
            expanded: false,
            fabEdit: false,
            fabShare: false,
            fabDriver: false,
          };
          return temp;
        });
      }
      {
        res = await VehicleLib.Vehicles({
          searchString: this.search,
          filterOnList: 0,
          ...this.options,
        });
        if (res.vehicles == null) {
          this.items = [];
        } else {
          this.items = res.vehicles.map((e) => {
            const temp = {
              data: e,
              expanded: false,
              fabEdit: false,
              fabShare: false,
              fabDriver: false,
            };
            return temp;
          });
        }
      }

      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve();
    }
  }

  async getShared() {
    try {
      if (AuthModule.isLoggedIn == false) return Promise.resolve();
      var res: VehicleProfiles | null = null;
      if (AuthModule.getProfile && AuthModule.getProfile.userDetails.driverId) {
        const vehicles = await fleetLib.driverVehicles(
          AuthModule.getProfile.userDetails.driverId
        );
        res = {
          resultCount: vehicles.length,
          vehicles: vehicles,
        };
        this.itemsShared = vehicles.map((e) => {
          const temp = {
            data: e,
            expanded: false,
            fabEdit: false,
            fabShare: false,
            fabDriver: false,
          };
          return temp;
        });
      }
      {
        res = await VehicleLib.Vehicles({
          searchString: this.search,
          filterOnList: 2,
          ...this.options,
        });
        if (res.vehicles == null) {
          this.itemsShared = [];
        } else {
          this.itemsShared = res.vehicles.map((e) => {
            const temp = {
              data: e,
              expanded: false,
              fabEdit: false,
              fabShare: false,
              fabDriver: false,
            };
            return temp;
          });
        }
      }

      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve();
    }
  }

  async getHistoric() {
    try {
      if (AuthModule.isLoggedIn == false) return Promise.resolve();
      var res: VehicleProfiles | null = null;
      if (AuthModule.getProfile && AuthModule.getProfile.userDetails.driverId) {
        const vehicles = await fleetLib.driverVehicles(
          AuthModule.getProfile.userDetails.driverId
        );
        res = {
          resultCount: vehicles.length,
          vehicles: vehicles,
        };
        this.itemsHistoric = vehicles.map((e) => {
          const temp = {
            data: e,
            expanded: false,
            fabEdit: false,
            fabShare: false,
            fabDriver: false,
          };
          return temp;
        });
      }
      {
        res = await VehicleLib.Vehicles({
          searchString: this.search,
          filterOnList: 1,
          ...this.options,
        });
        if (res.vehicles == null) {
          this.itemsHistoric = [];
        } else {
          this.itemsHistoric = res.vehicles.map((e) => {
            const temp = {
              data: e,
              expanded: false,
              fabEdit: false,
              fabShare: false,
              fabDriver: false,
            };
            return temp;
          });
        }
      }

      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve();
    }
  }

  get isFleet() {
    return this.getUserProfile && this.getUserProfile.companyDetails
      ? this.getUserProfile.companyDetails.companyType === 1 // Fleet
      : false;
  }
  get isIndividual() {
    return this.getUserProfile
      ? this.getUserProfile.userDetails.userType === "Individual"
      : false;
  }
  get isWorkshop() {
    return this.getUserProfile && this.getUserProfile.companyDetails
      ? this.getUserProfile.companyDetails.companyType === 1 // Mechanic
      : false;
  }

  @Watch("slideshow")
  @Watch("slideshowHistoric")
  @Watch("slideshowShared")
  @Watch("getItems")
  @Watch("getHistoricItems")
  @Watch("getSharedItems")
  @Watch("activeList", { immediate: true })
  setActiveVehicle() {
    //window.scrollTo(0, 0);
    switch (this.activeList) {
      case 0:
        if (this.getItems.length > 0)
          this.vehicle = this.getItems[this.slideshow].data;
        break;
      case 1:
        if (this.getHistoricItems.length > 0)
          this.vehicle = this.getHistoricItems[this.slideshowHistoric].data;
        break;
      case 2:
        if (this.getSharedItems.length > 0)
          this.vehicle = this.getSharedItems[this.slideshowShared].data;
        break;
    }

    // console.log("Active Vehicle :", this.vehicle);
  }

  openAttachments() {
    // this.vehicleAttachments = vehicleAttachments;
    if (this.vehicle) {
      VehiclesModule.setInteractedFlag({
        key: "item",
        value: this.vehicle,
      });
      VehiclesModule.setInteractedFlag({
        key: "attachments",
        value: true,
      });
    }
    // console.log("Open attachments ", vehicleAttachments);
  }
  // Vehicle History Modal
  openHistory() {
    if (this.vehicle) {
      VehiclesModule.setInteractedFlag({
        key: "item",
        value: this.vehicle,
      });
      VehiclesModule.setInteractedFlag({
        key: "history",
        value: true,
      });
    }
  }

  openEdit(): void {
    if (this.vehicle) {
      this.addEditModal.vehicle = this.vehicle;
      this.addEditModal.open = true;
    }
  }

  openAdd(): void {
    this.addEditModal.vehicle = undefined;
    this.addEditModal.open = true;
  }

  openVehicle() {
    if (this.vehicle)
      this.$router.push({
        name: "vehicle-jobcards",
        params: { vehicle: this.vehicle.id.toString() },
      });
  }

  vehicleEmailSharing(): void {
    if (this.vehicle) {
      VehiclesModule.setInteractedFlag({
        key: "emailSharing",
        value: true,
      });
      VehiclesModule.setInteractedFlag({
        key: "item",
        value: this.vehicle,
      });
    }
  }

  vehicleLinkSharing(): void {
    if (this.vehicle) {
      VehiclesModule.setInteractedFlag({
        key: "linkSharing",
        value: true,
      });
      VehiclesModule.setInteractedFlag({
        key: "item",
        value: this.vehicle,
      });
    }
  }

  manageDrivers(): void {
    this.driverModal.vehicle = this.vehicle;
    this.driverModal.visible = true;
  }

  openDeleteItem(): void {
    if (this.vehicle) {
      this.deleteModal.item = this.vehicle;
      this.deleteModal.open = true;
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  async deleteVehicle(): Promise<void> {
    try {
      if (this.deleteModal.item != null)
        await VehicleLib.DeleteVehicle(this.deleteModal.item.id);
      // console.log(this.deleteItem);
      NotificationLib.createSuccessNotification("Vehicle Deleted.");
      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      this.deleteModal.open = false;
      this.deleteModal.item = null;
      this.optionsModal = false;
      this.vehicle = null;
      this.init();
    }
  }

  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }

  async mounted() {
    // this.checkQueryParams();
    // console.log("Mounted");
    await this.init();

    fbHook.logEvent(0, null);
  }

  /* created(): void {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  }

  unmounted(): void {
    window.removeEventListener("scroll", this.handleScroll);
  } */

  /* handleScroll(): void {
    this.scrolled = window.scrollY;
  } */
}
