
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import { WorkItemForm } from "@/helpers/classes/Form";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import { jobLib } from "@/helpers/jobs";
import { JobCard, WorkItem } from "@/models/services";
import Auth from "@/store/modules/Auth";

@Component({
  components: {
    ImageAttachment,
  },
})
export default class WorkItemModal extends Vue {
  /*
   * This is for referencing jobcard stuff when creating and display what you are creating work items for
   */
  @PropSync("workitem") wi!: {
    open: boolean;
    jobcard: JobCard | null;
    workItem?: WorkItem;
  };
  /*
   * form to init and add onto
   */
  form: WorkItemForm | null = null;

  get getProfile() {
    return Auth.getProfile;
  }

  @Watch("wi.open")
  onOpenChanged(val: boolean, old: boolean) {
    if (val == true) {
      if (this.wi.jobcard != null && this.wi.jobcard.id)
        this.form = new WorkItemForm(this.wi.jobcard.id, this.wi.workItem);
    } else {
      if (this.form) this.form.reset();
    }
  }
  rules = {
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };
  valid = false;

  closeModal(): void {
    this.wi.open = false;
    this.wi.jobcard = null;
    if (this.form) this.form.reset();
  }
  async submit() {
    // Do work item submission here and then when complete respond with refresh event to then retrigger the async getJobCards
    try {
      if (this.form) await this.form.submit();
      this.$emit("submit");
      this.closeModal();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  }
}
