
import { Component, Vue } from "vue-property-decorator";
import { axiosNoAuth, generateMessageFromError } from "@/plugins/axios";
import { UserLib, StorageLib, NotificationLib } from "@/helpers/index";
import AuthModule from "@/store/modules/Auth";
import Recaptcha2 from "@/components/Recaptcha2.vue";
import { Device } from "@capacitor/device";
import { BiometryType, NativeBiometric } from "capacitor-native-biometric";
import fbHook from "@/helpers/firebase";

/**
 * override
 *
 * This enables/disables the ReCaptcha for development purposes.
 */
function override() {
  return process.env.VUE_APP_MODE == "development" || false;
}

@Component({
  components: {
    Recaptcha2,
  },
})
export default class Login extends Vue {
  loading = { standard: false, biometric: false };
  username: string | null = null;
  rememberMe = true;
  hasBiometrics = false;
  allowBiometrics = false;
  platform: "ios" | "android" | "web" = "web";
  email: string | null = null;
  password: string | null = null;
  reveal = false;
  valid = false;
  forgotPasswordModal = false;
  captcha = override();
  error = {
    open: false,
    message: "",
  };

  async performBiometricVerification(): Promise<void> {
    this.loading.biometric = true;
    // console.log("Platform ", this.platform);
    if (this.platform == "web") {
      this.loading.biometric = false;
      return;
    } else {
      const result = await NativeBiometric.isAvailable();
      //  console.log("Biometric isAvail res: ", result);
      if (!result.isAvailable) {
        this.loading.biometric = false;
        return;
      } else {
        //  console.log("biometrics: Biometric available");
        const isFaceID = result.biometryType == BiometryType.FACE_ID;

        const verified = await NativeBiometric.verifyIdentity({
          maxAttempts: 3,
          useFallback: true,
          reason: "For easy log in",
          title: "IsCarTi - Log in",
          subtitle:
            "This will log you back into your previously logged into account",
          description:
            "This is device specific and will not be able to log you in if you're already logged into another device.",
        })
          .then(() => true)
          .catch((err) => {
            switch (err) {
              case "0": {
                //Biometrics unavail or error;
                break;
              }
              case "10": {
                // authenticationFailed
                break;
              }
              case "11": {
                // appCancel
                break;
              }
              case "12": {
                // invalidContext
                break;
              }
              case "13": {
                // notInteractive
                break;
              }
              case "14": {
                // passcode not set
                break;
              }
              case "15": {
                // systemCancel
                break;
              }
              case "16": {
                // userCancel
                break;
              }
              case "17": {
                // userFallback
                break;
              }
            }
            console.error("Biometrics 1: ", err);
            NotificationLib.createWarningNotification("Biometrics " + err);
            return false;
          });

        if (!verified) return;

        const credentials = await NativeBiometric.getCredentials({
          server: process.env.VUE_APP_URL,
        }).catch((err) => {
          // credentials not found also errors here so just handling that

          console.error("Biometrics 2: ", err);
          return Promise.reject(err);
        });
        this.email = credentials.username;
        this.password = credentials.password;
        //trigger login submission;
        this.submitCredentials();
      }
    }
  }

  async getPlatform(): Promise<void> {
    const temp = await Device.getInfo();
    Promise.resolve();
    this.platform = temp.platform;
  }

  async checkIfHasBiometrics(): Promise<boolean> {
    if (this.platform != "web") {
      // console.log("checking if device has Biometrics");
      const res = await NativeBiometric.getCredentials({
        server: process.env.VUE_APP_URL,
      }).catch((err) => {
        console.error("biometrics: error with get", err);
        this.hasBiometrics = false;
        return false;
      });
      // console.log("biometrics: check biometrics", res);
      if (res) {
        //    console.log("biometrics: true", res);
        this.hasBiometrics = true;
        return true;
      } else {
        //  console.log("biometrics: false", res);

        this.hasBiometrics = false;
      }
    }
    Promise.resolve();
    return false;
  }

  async mounted() {
    // console.log("login mounted");
    await this.getPlatform();
    await this.checkIfHasBiometrics();
  }

  get getCaptcha(): boolean {
    return this.captcha;
  }

  rules = {
    required: (v: string) => !!v || "Cannot be empty",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Requires atleast 1 UPPERCASE character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Requires atleast 1 NUMBER character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Requires atleast 1 lowercase character";
    },
    email: (v: string) => {
      const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Valid email required";
    },
  };

  login(): void {
    this.$emit("login");
    const refForm: any = this.$refs.loginForm;
    refForm.reset();
  }

  close(): void {
    this.$emit("close");
    const refForm: any = this.$refs.loginForm;
    refForm.reset();
  }

  async submitCredentials(): Promise<void> {
    try {
      this.loading.standard = true;
      this.error.open = false;
      this.error.message = "";
      if (this.email != null && this.password != null) {
        const res = await UserLib.login(
          {
            email: this.email,
            password: this.password,
          },
          this.rememberMe
        );
        const valid = await UserLib.processNewJWT(res);
        if (
          this.email &&
          process.env.VUE_APP_MODE != "development" &&
          this.platform != "web"
        ) {
          //console.log("Biometrics Server: ", process.env.VUE_APP_URL);
          await NativeBiometric.deleteCredentials({
            server: process.env.VUE_APP_URL,
          });
          await NativeBiometric.setCredentials({
            username: this.email,
            password: this.password,
            server: process.env.VUE_APP_URL,
          }).catch((err) => {
            console.error("Biometrics 3: ", err);
            return Promise.reject(err);
          });
        }
        const device = await Device.getInfo();
        const user = await UserLib.getProfile();
        if (device.platform != "web") {
          // console.log("PLease register here");
          await NotificationLib.registerFirebaseNotifications();
        }
        if (valid) {
          this.login();
          fbHook.setUser(user);
          fbHook.logEvent(1, null);
          if (this.$route.query.redirectedFrom) {
            await this.$router.push({
              path: this.$route.query.redirectedFrom.toString(),
            });
          } else {
            await this.$router.push({ name: "garage" });
          }
        } else {
          NotificationLib.createErrorNotification(
            "Token was not valid. Please reload and try logging in again."
          );
        }

        return Promise.resolve();
      }
    } catch (err) {
      AuthModule.setAuthToken(null);
      // TODO: clear localstorage here too
      const temp = generateMessageFromError(err);
      this.error.message = temp;
      this.error.open = true;
    } finally {
      this.loading.standard = false;
      this.loading.biometric = false;
    }
  }
}
