const SHARE_TYPES = [
  {
    id: 0,
    text: "Sale",
  },
  {
    id: 1,
    text: "Repair",
  },
];

export { SHARE_TYPES };
