
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";

@Component({})
export default class LoaderComponent extends Vue {
  get getLoadingState(): boolean {
    return Loader.getLoadingState;
  }
}
