import {
  ImageAttachment,
  ImageFile,
  VehicleProfilePicture,
  VehicleAttachment,
} from "@/models/files";
import { JobCard, JobCardDetails, WorkItem } from "@/models/services";
import cloneDeep from "lodash.clonedeep";
import { isEqual } from "lodash";
import dayjs from "dayjs";
import {
  CreateVehicleAttachments,
  UpdateVehicleAttachments,
  Vehicle,
  VehicleProfile,
} from "@/models/vehicle";
import { VehicleCreate } from "@/models/vehicle";
import { Image } from "./Files";
import { JobLib, NotificationLib, UserLib, VehicleLib, CalendarLib } from "..";
import { Profile, Registration } from "@/models/user";
import { jobLib } from "../jobs";
import { Quote } from "@/models/documents";
import {
  CustomCalendarCompleteEvent,
  CustomCalendarEvent,
  CustomCalendarEventSubmit,
} from "@/models/calendar";
import { calendarLib } from "../calendar";
import { vehicleLib } from "../vehicle";
import Vehicles from "@/store/modules/Vehicles";
import { videosHook } from "@/helpers/videos";
import { Video, VideoViewModel } from "@/models/videos";
import { ProductsViewModel } from "@/models/store";
import { storeHook } from "../store";

class Form {}

class UserRegisterForm extends Form {
  public form: Registration;
  /**
   * This is to determine if it is new reg or edit of profile.
   */
  public readonly reference?: Profile;
  public confirmPassword: string | null = null;
  public acceptTCs = false;
  public reveal = false;
  public revealConfirm = false;
  public captcha = false;
  public type: "personal" | "business";

  constructor(type: "personal" | "business", profile?: Profile) {
    super();
    // console.log("Construct form : ", profile != undefined, type);
    this.type = type;
    this.reference = profile;
    this.form = this.initForm(type, profile);
  }
  reset(val: "personal" | "business") {
    this.type = val;
    this.acceptTCs = false;
    this.confirmPassword = null;
    this.captcha = false;
    this.reveal = false;
    this.revealConfirm = false;
    this.form = this.initForm(val, this.reference);
    // ? this.reference
    // : {
    //     name: null,
    //     password: null,
    //     email: null,
    //     logo: val == "personal" ? null : new Image().toValue(),
    //     phoneNumber: null,
    //     userType: val == "personal" ? "Individual" : "Company Owner",
    //     companyType: null,
    //     businessName: "",
    //     businessEmail: "",
    //     businessPhoneNo: "",
    //     businessRegNo: "",
    //     address: {
    //       complexName: "",
    //       buildingName: "",
    //       city: "",
    //       streetNo: "",
    //       street: "",
    //       suburb: "",
    //       province: "",
    //       zipCode: "",
    //     },
    //     rmiNo: "",
    //     emailNotification: false,
    //     pushNotification: false,
    //     whatsAppNotification: false,
    //     vatNo: "",
    //   };
  }
  async submit() {
    if (this.reference) {
      // this is then edit profile
      const update = await UserLib.updateProfile(this.form);
      NotificationLib.createSuccessNotification("Profile updated");
    } else {
      // this is registration on auth
      const registration = await UserLib.register(this.form);
      // console.log("Registration", registration);
      this.reset(this.type);
      NotificationLib.createSuccessNotification("Registration successful");
      return Promise.resolve();
    }
  }
  initForm(type: "personal" | "business", profile?: Profile) {
    return {
      name: profile ? profile.userDetails.nameOfUser : null,
      password: null,
      email: profile ? profile.userDetails.email : null,
      phoneNumber: profile ? profile.userDetails.phoneNumber : null,
      logo: profile
        ? type == "personal"
          ? null
          : profile.companyDetails && profile.companyDetails.logo
          ? profile.companyDetails.logo
          : new Image().toValue()
        : new Image().toValue(),
      userType: profile
        ? profile.userDetails.userType
        : type == "personal"
        ? "Individual"
        : "Company Owner",
      companyType:
        profile && profile.companyDetails
          ? profile.companyDetails.companyType
          : type == "personal"
          ? null
          : 1,
      businessName:
        profile && profile.companyDetails
          ? profile.companyDetails.businessName
          : "",
      businessEmail:
        profile && profile.companyDetails
          ? profile.companyDetails.businessEmail
          : "",
      businessPhoneNo:
        profile && profile.companyDetails
          ? profile.companyDetails.businessPhoneNo
          : "",
      businessRegNo:
        profile && profile.companyDetails
          ? profile.companyDetails.businessRegNo
          : "",
      address: profile
        ? profile.companyDetails
          ? {
              complexName: profile.companyDetails.businessComplexName,
              buildingName: profile.companyDetails.businessBuildingName,
              city: profile.companyDetails.businessCity,
              streetNo: profile.companyDetails.businessStreetNo,
              street: profile.companyDetails.businessStreet,
              suburb: profile.companyDetails.businessSuburb,
              province: profile.companyDetails.businessProvince,
              zipCode: profile.companyDetails.businessZipCode,
            }
          : {
              complexName: "",
              buildingName: "",
              city: "",
              streetNo: "",
              street: "",
              suburb: "",
              province: profile.userDetails.address,
              zipCode: "",
            }
        : {
            complexName: "",
            buildingName: "",
            city: "",
            streetNo: "",
            street: "",
            suburb: "",
            province: "",
            zipCode: "",
          },
      rmiNo:
        profile && profile.companyDetails ? profile.companyDetails.rmiNo : "",
      emailNotification: profile
        ? profile.userDetails.emailNotification
        : false,
      pushNotification: profile ? profile.userDetails.pushNotification : false,
      whatsAppNotification: profile
        ? profile.userDetails.whatsAppNotification
        : false,
      vatNo:
        profile && profile.companyDetails ? profile.companyDetails.vatNo : "",
    };
  }
}
class VehicleForm extends Form {
  public form: VehicleCreate;
  public unchanged = true;
  public vehicle?: VehicleProfile;
  constructor(vehicle?: VehicleProfile) {
    super();
    this.unchanged = true;
    this.form = this.initForm(vehicle);
    this.vehicle = vehicle;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.vehicle);
  }
  initForm(vehicle?: VehicleProfile) {
    return {
      description: vehicle ? vehicle.description : "",
      fullName: vehicle ? vehicle.fullName : "",
      mileage: vehicle && vehicle.mileage ? vehicle.mileage : 0,
      make: vehicle ? vehicle.make : "",
      model: vehicle ? vehicle.model : "",
      vehicleRegistration: vehicle ? vehicle.vehicleRegistration : "",
      vinNumber: vehicle ? vehicle.vinNumber : "",
      year: vehicle ? vehicle.year : 2000,
      shareable: vehicle ? vehicle.shareable : true,
      profilePicture: vehicle
        ? vehicle.profilePicture
        : (new Image().toValue() as VehicleProfilePicture),
      vehicleAvatar: vehicle
        ? ({
            fileName: vehicle.vehicleAvatarVM?.fileName,
            fileSize: vehicle.vehicleAvatarVM?.fileSize,
            mimeType: vehicle.vehicleAvatarVM?.mimeType,
            upload: vehicle.vehicleAvatarVM?.data,
            description: "",
          } as ImageAttachment)
        : (new Image() as ImageAttachment),
      vehicleCutOut: vehicle
        ? ({
            fileName: vehicle.vehicleCutOutVM?.fileName,
            fileSize: vehicle.vehicleCutOutVM?.fileSize,
            mimeType: vehicle.vehicleCutOutVM?.mimeType,
            upload: vehicle.vehicleCutOutVM?.data,
            description: "",
          } as ImageAttachment)
        : (new Image() as ImageAttachment),
    };
  }
  compare() {
    if (this.vehicle == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.vehicle) {
      if (
        this.vehicle.vehicleRegistration == this.form.vehicleRegistration &&
        this.vehicle.vinNumber == this.form.vinNumber &&
        this.vehicle.description == this.form.description &&
        this.vehicle.profilePicture.upload == this.form.profilePicture.upload &&
        this.vehicle.make == this.form.make &&
        this.vehicle.model == this.form.model &&
        this.vehicle.shareable == this.form.shareable &&
        this.vehicle.year == this.form.year &&
        this.vehicle.vehicleAvatar == this.form.vehicleAvatar &&
        this.vehicle.vehicleCutOut == this.form.vehicleCutOut
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.vehicle) {
      // edit
      // const res = await editFunction
      if (this.vehicle && this.form) {
        this.form.fullName = `${this.form.year} ${this.form.make} ${this.form.model}`;
        await VehicleLib.UpdateVehicle({
          ...this.vehicle,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Vehicle updated");
      }
    } else {
      // add
      if (this.form) {
        this.form.fullName = `${this.form.year} ${this.form.make} ${this.form.model}`;
        await VehicleLib.AddNewVehicle(this.form);
        NotificationLib.createSuccessNotification("Vehicle added");
      }
    }
    return Promise.resolve();
  }
}
class QuoteForm extends Form {
  datum!: Quote;
  constructor(quote?: Quote) {
    super();
    // this.datum = {};
  }
  reset() {
    // this.datum = {};
  }
  async submit() {
    if (!this.datum.quoteId) {
      // await createQuote;
    } else {
      // await updateQuote;
    }
  }
}
class JobcardForm extends Form {
  public form: JobCardDetails;
  public openDate: boolean;
  public jobcard?: JobCardDetails;
  public unchanged: boolean;
  constructor(vehicleId: number, jobcard?: JobCard) {
    super();
    this.unchanged = true;
    this.openDate = false;
    this.form = {
      id: jobcard ? jobcard.id : null,
      vehicleId: vehicleId,
      description: jobcard ? jobcard.description : "",
      inDate: jobcard ? dayjs(jobcard.inDate).format("YYYY-MM-DD") : null,
      mileage: jobcard ? jobcard.mileage : 0,
    };
    this.jobcard = jobcard;
  }
  compare(): boolean {
    // console.log("Compare");
    if (this.jobcard == undefined) {
      // console.log("Compare undefined");
      this.unchanged = true;
      return true;
    }
    if (
      this.form.description == this.jobcard.description &&
      this.form.inDate == dayjs(this.jobcard.inDate).format("YYYY-MM-DD") &&
      this.form.mileage == this.jobcard.mileage
    ) {
      this.unchanged = true;
    } else {
      this.unchanged = false;
      /*  console.log(
        "Compare difference",
        this.form.inDate,
        this.jobcard.inDate,
        this.form.description == this.jobcard.description,
        this.form.inDate == this.jobcard.inDate,
        this.form.mileage == this.jobcard.mileage
      ); */
    }
    return this.unchanged;
  }
  reset() {
    this.unchanged = true;
    this.form = {
      id: this.jobcard ? this.jobcard.id : null,
      vehicleId: this.form.vehicleId,
      description: this.jobcard ? this.jobcard.description : "",
      inDate: this.jobcard
        ? dayjs(this.jobcard.inDate).format("YYYY-MM-DD")
        : null,
      mileage: this.jobcard ? this.jobcard.mileage : 0,
    };
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.jobcard) {
      // edit
      // console.log("Update JobCard");
      const res = await JobLib.updateVehicleJobCard(this.form);
      return Promise.resolve(res);
    } else {
      // add
      // console.log("Create JobCard");
      const res = await JobLib.createVehicleJobCard(this.form);
      return Promise.resolve(res);
    }
  }
}
class ReviewAttachmentForm {
  public form: {
    datePicker: boolean;
    description: string | null;
    serviceDate: string | null;
    imageFiles: ImageAttachment[];
    km: number;
  };
  public unchanged: boolean;
  public newItem: {
    picture: ImageFile;
    description: string;
  };
  public reference: VehicleAttachment | null; // use for vehicleId
  constructor(obj?: VehicleAttachment) {
    this.unchanged = true;
    this.reference = obj ? obj : null;
    this.form = {
      datePicker: false,
      description: obj ? obj.description : null,
      serviceDate: obj
        ? typeof obj.serviceDate !== "string"
          ? obj.serviceDate.format("YYYY-MM-DD")
          : dayjs(obj.serviceDate).format("YYYY-MM-DD")
        : null,
      imageFiles: obj ? cloneDeep(obj.imageFiles) : [],
      km: obj ? obj.km : 0,
    };
    this.newItem = {
      picture: new Image().toValue(),
      description: "",
    };
    // console.log("Construction review attachment form");
  }
  removeAttachment(item: ImageAttachment) {
    if (!this.form.imageFiles) return;
    const index = this.form.imageFiles.findIndex(
      (e) => e.fileName == item.fileName && e.upload == item.upload
    );
    if (index != -1) this.form.imageFiles.splice(index, 1);
  }
  compare(): boolean {
    // console.log("Review compare ", this.form, this.unchanged, this.reference);
    if (!this.form) {
      //  console.log("Review compare !form");
      this.unchanged = true;
    } else if (this.reference == null) {
      // console.log("Review compare ref ==");
      const unchanged =
        this.form.description == null &&
        this.form.serviceDate == null &&
        this.form.imageFiles.length == 0;

      this.unchanged = unchanged;
    } else if (
      dayjs(this.reference.serviceDate).format("YYYY-MM-DD") !=
        this.form.serviceDate ||
      this.reference.description != this.form.description ||
      !isEqual(this.reference.imageFiles, this.form.imageFiles)
    ) {
      const match =
        this.form.description != null &&
        this.form.serviceDate != null &&
        this.form.imageFiles.length > 0;
      this.unchanged = false;
      if (match) {
        //console.log("Match ");
        // return true;
      }
      /* console.log(
        "Service date :",
        dayjs(this.reference.serviceDate).format("YYYY-MM-DD"),
        this.form.serviceDate,
        dayjs(this.reference.serviceDate).format("YYYY-MM-DD") ==
          this.form.serviceDate
      ); */
      /* console.log(
        "description :",
        this.reference.description,
        this.form.description,
        this.reference.description == this.form.description
      );
      console.log(
        "files :",
        this.reference.imageFiles,
        this.form.imageFiles,
        isEqual(this.reference.imageFiles, this.form.imageFiles)
      ); */
    }
    return this.unchanged;
  }
  async addNewItem() {
    if (this.newItem.picture.upload == "") return Promise.resolve();
    const item: ImageAttachment = {
      ...this.newItem.picture,
      description: this.newItem.description,
    };
    if (this.form.imageFiles) this.form.imageFiles.push(item);
    else this.form.imageFiles = [item];
    this.clearNewItem();
  }
  clearNewItem() {
    this.newItem = {
      picture: new Image().toValue(),
      description: "",
    };
  }
  reset() {
    //console.log("Reset review attachment form");
    this.form = {
      datePicker: false,
      description: this.reference ? this.reference.description : null,
      km: this.reference ? this.reference.km : 0,
      serviceDate: this.reference
        ? typeof this.reference.serviceDate !== "string"
          ? this.reference.serviceDate.format("YYYY-MM-DD")
          : dayjs(this.reference.serviceDate).format("YYYY-MM-DD")
        : null,
      imageFiles: this.reference ? cloneDeep(this.reference.imageFiles) : [],
    };
  }
  async submit() {
    if (this.reference) {
      // edit
      // console.log("Yolo reference ");
      if (!Vehicles.getInteracted || !Vehicles.getInteracted.item) {
        return;
      }
      const temp: UpdateVehicleAttachments = {
        vehicleId: Vehicles.getInteracted.item.id,
        fileId: this.reference.id,
        attachments: {
          images: this.form.imageFiles,
          attachmentDescription: this.form.description,
          attachmentServiceDate: this.form.serviceDate,
          attachmentKm: this.form.km,
        },
      };
      // console.log("Temps update ", temp);
      const res = await vehicleLib.UpdateAttachments(temp);
    } else {
      // create
      if (!Vehicles.getInteracted || !Vehicles.getInteracted.item) {
        return;
      }
      const temp: CreateVehicleAttachments = {
        vehicleId: Vehicles.getInteracted.item.id,
        attachment: {
          images: this.form.imageFiles,
          attachmentDescription: this.form.description,
          attachmentServiceDate: this.form.serviceDate,
          attachmentKm: this.form.km,
        },
      };
      //  console.log("Temps ", temp);
      const res = await vehicleLib.CreateAttachments(temp);
      // console.log("Res submit : ", res,);
      return;
    }
    // console.log("Submit review attachment form");
  }
}
class WorkItemForm extends Form {
  public form: WorkItem;
  public reference?: WorkItem;
  public newItem: {
    picture: ImageFile;
    description: string;
  };
  constructor(jobcardId: number | string, workItem?: WorkItem) {
    super();
    if (workItem) this.reference = workItem;
    this.form = {
      jobCardId: jobcardId,
      // ...(workItem?.workItemId ? { workItemId: workItem?.workItemId } : {}),
      workItemId: workItem ? workItem.workItemId : undefined,
      description: workItem ? workItem.description : "",
      attachments: workItem ? cloneDeep(workItem.attachments) : [], // undefined,
    };
    this.newItem = {
      picture: new Image().toValue(),
      description: "",
    };
  }
  removeAttachment(item: ImageAttachment) {
    if (!this.form.attachments) return;
    const index = this.form.attachments.findIndex(
      (e) => e.fileName == item.fileName && e.upload == item.upload
    );
    if (index != -1) this.form.attachments.splice(index, 1);
  }
  async addNewItem() {
    // console.log(this.newItem.file);
    if (this.newItem.picture.upload == "") return Promise.resolve();
    // console.log(this.newItem.file);
    // const image = await Image.init(this.newItem.file);
    const item: ImageAttachment = {
      ...this.newItem.picture,
      description: this.newItem.description,
    };
    if (this.form.attachments) this.form.attachments.push(item);
    else this.form.attachments = [item];
    this.clearNewItem();
  }
  clearNewItem() {
    this.newItem = {
      picture: new Image().toValue(),
      description: "",
    };
  }
  reset() {
    this.form = {
      jobCardId: this.form.jobCardId,
      workItemId: this.reference ? this.reference.workItemId : undefined,
      description: this.reference ? this.reference.description : "",
      attachments:
        this.reference &&
        this.reference.attachments &&
        Array.isArray(this.reference.attachments)
          ? cloneDeep(this.reference.attachments)
          : [], // undefined,
    };
    this.clearNewItem();
  }
  async submit() {
    // console.log("Creating Work Item:", this.form);
    try {
      if (this.newItem.picture.upload != "") {
        this.addNewItem();
      }
      if (this.form.workItemId) {
        const res = await jobLib.updateJobCardWorkItem(this.form);
        return Promise.resolve(res);
      } else {
        const res = await jobLib.createJobCardWorkItem(this.form);
        return Promise.resolve(res);
      }
    } catch (e) {
      return Promise.reject();
    }
  }
}

class CalendarBookingForm extends Form {
  public form: CustomCalendarCompleteEvent | null;
  public booking?: CustomCalendarCompleteEvent | null;
  public unchanged: boolean;
  constructor(booking?: CustomCalendarCompleteEvent | null) {
    super();
    this.unchanged = true;
    /* this.form = this.initForm(booking); */
    this.form = {
      color: booking ? booking.color : "",
      details: booking ? booking.details : ({} as CustomCalendarEvent),
      end: booking ? booking.end : null,
      start: booking ? booking.start : null,
      name: booking ? booking.name : "",
      timed: booking ? booking.timed : false,
      id: booking ? booking.id : undefined,
    };
    if (
      booking &&
      booking.details.vehicleId == null &&
      booking.details.vehicleRegistration
    ) {
      this.form.details.vehicle = booking.details.vehicleRegistration;
    }
    this.booking = booking;
  }

  async initForm(booking?: CustomCalendarCompleteEvent | null) {
    //check for both ids (vehicle & jobcard)
    //getVehicle
    if (booking) {
      if (booking.details.vehicleId) {
        const resVehicle = await vehicleLib.VehicleByID(
          booking.details.vehicleId
        );
        // console.log("getVehicleById res: ", resVehicle);
        booking.details.vehicle = resVehicle;
      }
      //getJobcard
      if (booking.details.jobCardId) {
        const resJobCard = await JobLib.getJobCardById(
          booking.details.jobCardId
        );
        // console.log("getJobCardById res: ", resJobCard);
        booking.details.jobCard = resJobCard;

        // console.log("booking.details.jobCard: ", booking.details.jobCard);
      }
    }

    //call constructor w/ optional param of booking
    const temp = new CalendarBookingForm(booking);
    return Promise.resolve(temp);
  }
  compare(): boolean {
    //console.log("Compare");
    if (this.booking == undefined) {
      // console.log("Compare undefined");
      this.unchanged = true;
      return true;
    }
    if (this.form)
      if (
        this.form.details.vehicleRegistration ==
          this.booking.details.vehicleRegistration &&
        dayjs(this.form.end).format("YYYY-MM-DD") ==
          dayjs(this.booking.end).format("YYYY-MM-DD") &&
        dayjs(this.form.start).format("YYYY-MM-DD") ==
          dayjs(this.booking.start).format("YYYY-MM-DD")
      ) {
        this.unchanged = true;
      } else {
        this.unchanged = false;
        // console.log("Compare difference", this.form);
      }
    return this.unchanged;
  }
  reset() {
    this.unchanged = true;
    /* this.form = this.initForm(this.booking); */

    // console.log("Reset Triggered");
  }
  async submit() {
    if (this.form) {
      const bookingToSubmit: CustomCalendarEventSubmit = {
        bookingDate: this.form.start,
        clientEmail: this.form.details.clientEmail,
        clientName: this.form.details.clientName,
        clientPhone: this.form.details.clientPhone,
        color: this.form.color,
        jobCardId: this.form.details.jobCard
          ? this.form.details.jobCard.id
            ? this.form.details.jobCard.id
            : undefined
          : undefined,
        make: this.form.details.make,
        model: this.form.details.model,
        outDate: this.form.end,
        reason: this.form.details.reason,
        vehicleId:
          this.form.details.vehicle &&
          typeof this.form.details.vehicle == "object"
            ? this.form.details.vehicle.id
            : undefined,
        vehicleRegistration:
          typeof this.form.details.vehicle == "object" &&
          this.form.details.vehicle != null
            ? this.form.details.vehicle.vehicleRegistration
            : this.form.details.vehicle != null
            ? this.form.details.vehicle
            : "",
        year: this.form.details.year,
        id: this.form.id,
        companyId: this.form.details.companyId,
      };
      // having a booking  === edit || === add
      if (this.booking?.id) {
        // edit
        // console.log("Update Booking(Calendar Event)");
        const res = await calendarLib.updateEvent(bookingToSubmit);
        return Promise.resolve(res);
      } else {
        // add
        //console.log("Create Booking(Calendar Event)");
        const res = await calendarLib.submitEvent(bookingToSubmit);
        return Promise.resolve(res);
      }
    }
  }
}

class AvatarForm extends Form {
  public form: {
    generateFrom: string;
    make: string;
    model: string;
    year: number;
    prompt: string;
    upload: ImageFile | File | string | null;
  };
  public unchanged = true;
  public avatar?: {
    generateFrom: string;
    make: string;
    model: string;
    year: number;
    prompt: string;
    upload: ImageFile | File | string | null;
  };
  constructor(avatar?: {
    generateFrom: string;
    make: string;
    model: string;
    year: number;
    prompt: string;
    upload: string | null;
  }) {
    super();
    this.unchanged = true;
    this.form = this.initForm(avatar);
    this.avatar = avatar;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.avatar);
  }
  initForm(avatar?: {
    generateFrom: string;
    make: string;
    model: string;
    year: number;
    prompt: string;
    upload: ImageFile | File | string | null;
  }) {
    return {
      generateFrom: avatar ? avatar.generateFrom : "",
      make: avatar ? avatar.make : "",
      model: avatar ? avatar.model : "",
      year: avatar ? avatar.year : 2000,
      prompt: avatar ? avatar.prompt : "",
      upload: avatar ? avatar.upload : "",
    };
  }
  compare() {
    if (this.avatar == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.avatar) {
      if (
        this.avatar.generateFrom == this.form.generateFrom &&
        this.avatar.make == this.form.make &&
        this.avatar.model == this.form.model &&
        this.avatar.year == this.form.year &&
        this.avatar.prompt == this.form.prompt &&
        this.avatar.upload == this.form.upload
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        // console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.avatar) {
      // edit
      // const res = await editFunction
      if (this.avatar && this.form) {
        /*  await VehicleLib.UpdateVehicle({
          ...this.vehicle,
          ...this.form,
        }); */
        NotificationLib.createSuccessNotification("Vehicle updated");
      }
    } else {
      // add
      if (this.form) {
        /*         await VehicleLib.AddNewVehicle(this.form);
         */
      }
    }
    return Promise.resolve();
  }
}

class VideosForm extends Form {
  public form: Video;
  public unchanged = true;
  public video?: Video;
  constructor(video?: Video) {
    super();
    this.unchanged = true;
    this.form = this.initForm(video);
    this.video = video;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.video);
  }
  initForm(video?: Video) {
    return {
      id: video ? video.id : 0,
      videoUrl: video ? video.videoUrl : "",
      title: video ? video.title : "",
      description: video ? video.description : "",
      status: video ? video.status : 0,
      createByUser: video ? video.createByUser : "",
      createDate: video ? video.createDate : "",
      updateByUser: video ? video.updateByUser : "",
      updateDate: video ? video.updateDate : "",
      deleted: video ? video.deleted : false,
      category: video ? video.category : "",
    };
  }
  compare() {
    if (this.video == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.video) {
      if (
        this.video.videoUrl == this.form.videoUrl &&
        this.video.title == this.form.title &&
        this.video.description == this.form.description &&
        this.video.status == this.form.status &&
        this.video.category == this.form.category
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        // console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.video) {
      // edit
      // const res = await editFunction
      if (this.video && this.form) {
        await videosHook.UpdateVideo({
          ...this.video,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Video updated");
      }
    } else {
      // add
      if (this.form) {
        this.form.status = 0;
        await videosHook.CreateVideo(this.form);
        NotificationLib.createSuccessNotification("Video Added");
      }
    }
    return Promise.resolve();
  }
}

class ProductsForm extends Form {
  public form: ProductsViewModel;
  public unchanged = true;
  public product?: ProductsViewModel;
  constructor(product?: ProductsViewModel) {
    super();
    this.unchanged = true;
    this.form = this.initForm(product);
    this.product = product;
  }
  reset() {
    this.unchanged = true;
    this.product = this.initForm(this.product);
  }
  initForm(product?: ProductsViewModel) {
    return {
      id: product ? product.id : 0,
      title: product ? product.title : "",
      description: product ? product.description : "",
      category: product ? product.category : "",
      price: product ? (product.price.toFixed(2) as any) : 0,
      images: product ? product.images : [],
      status: product ? product.status : undefined,
      user: product ? product.user : null,
    };
  }
  compare() {
    if (this.product == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.product) {
      if (
        this.product.price == this.form.price &&
        this.product.title == this.form.title &&
        this.product.description == this.form.description &&
        this.product.category == this.form.category &&
        this.product.images.length == this.form.images.length
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.product) {
      // edit
      // const res = await editFunction
      if (this.product && this.form) {
        await storeHook.UpdateProducts({
          ...this.product,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Product updated");
      }
    } else {
      // add
      if (this.form) {
        await storeHook.AddProduct(this.form);
        NotificationLib.createSuccessNotification("Product Added");
      }
    }
    return Promise.resolve();
  }
}

export {
  Form,
  WorkItemForm,
  ReviewAttachmentForm,
  JobcardForm,
  VehicleForm,
  UserRegisterForm,
  CalendarBookingForm,
  AvatarForm,
  VideosForm,
  ProductsForm,
};
