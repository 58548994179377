
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";

@Component({})
export default class Footer extends Vue {
  get getYear(): string {
    return dayjs().format("YYYY");
  }

  get getVersion() {
    return process.env.VUE_APP_VERSION;
  }
}
