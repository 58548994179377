
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import { NotificationLib, videosLib } from "@/helpers";
import { generateMessageFromError } from "@/plugins/axios";
import { Video, VideoCommentsViewModel, VideoViewModel } from "@/models/videos";
import fbHook from "@/helpers/firebase";
import videosModal from "@/views/Videos/VideosModal.vue";
import Loader from "@/store/modules/Loader";
import { Share as CapShare } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import { Query } from "@/models/search";
import { VehicleCategories } from "@/models/vehicleCategories";

interface VideoTableViewModel extends Video {
  createDateFormatted: string;
  videoId: string | null;
}

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Videos",
    meta: [
      {
        name: "description",
        content: "ISCARTI Videos.",
      },
      {
        property: "og:description",
        content: "ISCARTI Videos.",
      },
    ],
  },
  components: {
    videosModal,
  },
  filters: {
    description: function (value: string) {
      if (value.length >= 75) {
        const front = value.substring(0, 75);

        return front + "...";
      } else {
        return value;
      }
    },
    title: function (value: string) {
      if (value.length >= 18) {
        const front = value.substring(0, 18);

        return front + "...";
      } else {
        return value;
      }
    },
    titleDesktop: function (value: string) {
      if (value.length >= 25) {
        const front = value.substring(0, 25);

        return front + "...";
      } else {
        return value;
      }
    },
    comment: function (value: string) {
      if (value.length >= 35) {
        const front = value.substring(0, 35);

        return front + "...";
      } else {
        return value;
      }
    },
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class Videos extends Vue {
  optionsModal = false;
  showMore = false;
  readMore = false;
  slideshow = 0;
  items: VideoTableViewModel[] = [];
  activeVideo: VideoTableViewModel | null | undefined = null;
  search: string | null = null;
  category: string | null = null;
  categories: VehicleCategories[] = [];
  sortBy: string | null = "Upload Date";
  sortByList = ["Upload Date", "Category"];
  sortDesc = true;
  modal = false;
  modalItem: Video | undefined | null = null;
  deleteModal = false;
  deleteItem = {} as Video;
  deleteCommentModal = false;
  deleteCommentItem = {} as VideoCommentsViewModel;
  showCommentBox = true;
  commentToAdd: string | null = null;
  query: Query = {
    searchString: "",
    filterOnList: 3,
    page: 0,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  get getLoggedInUserEmail(): string | null {
    if (AuthModule.getProfile && AuthModule.getProfile.userDetails.email) {
      return AuthModule.getProfile.userDetails.email.toLocaleLowerCase();
    } else {
      return null;
    }
  }

  get getActiveVideoUserEmail(): string | null {
    if (this.activeVideo) {
      return this.activeVideo.createByUser.toLocaleLowerCase();
    } else {
      return null;
    }
  }

  openEdit(i: VideoTableViewModel | null | undefined): void {
    this.modalItem = i as Video;
    this.modal = true;
  }

  openAdd(): void {
    this.modal = true;
  }

  openDelete(i: VideoTableViewModel | null | undefined): void {
    this.deleteItem = i as Video;
    this.deleteModal = true;
  }

  openCommentDelete(i: VideoCommentsViewModel | null | undefined): void {
    this.deleteCommentItem = i as VideoCommentsViewModel;
    this.deleteCommentModal = true;
  }

  next() {
    if (this.slideshow != this.items.length - 1) {
      this.slideshow = this.slideshow + 1;
    }
  }
  prev() {
    if (this.slideshow != 0) {
      this.slideshow = this.slideshow - 1;
    }
  }

  @Watch("slideshow", { immediate: true })
  @Watch("items", { immediate: true })
  activeSlideChanged(): void {
    this.activeVideo = this.items[this.slideshow];
    // console.log("Active Video Changed", this.activeVideo);
  }

  /* @Watch("search")
  async searchChanged(): Promise<void> {
    if (this.search) {
      var resItems = await this.getVideos();
      var resFind = resItems.filter(
        (elem) =>
          (this.search ? elem.title.includes(this.search) : null) ||
          (this.search ? elem.description.includes(this.search) : null) ||
          (this.search ? elem.createByUser.includes(this.search) : null) ||
          (this.search ? elem.createDateFormatted.includes(this.search) : null)
      );
      if (resFind) {
        this.items = resFind;
      } else {
        this.items = resItems;
      }
    } else {
      this.items = await this.getVideos();
    }
  } */

  async applyFilters(): Promise<void> {
    Loader.setLoadingState(true);
    this.items = await this.getVideos();
    var filteredItems: VideoTableViewModel[] = [];

    if (this.search) {
      var resSearch = this.items.filter(
        (elem) =>
          (this.search ? elem.title.includes(this.search) : null) ||
          (this.search ? elem.description.includes(this.search) : null) ||
          (this.search
            ? elem.createByUser?.toString().includes(this.search)
            : null) /* ||
          (this.search ? elem.category.includes(this.search) : null) */
      );
      if (resSearch.length > 0) {
        filteredItems = resSearch;
        console.log("filtered after search processed :", filteredItems);
      }
    }

    if (this.category) {
      var resCategory: VideoTableViewModel[] = [];
      var processCategoryItems = [];

      if (filteredItems.length > 0) {
        processCategoryItems = filteredItems;
      } else {
        processCategoryItems = this.items;
      }

      processCategoryItems.map((item) => {
        if (item.category == this.category) {
          resCategory.push(item);
        }
      });

      if (resCategory.length > 0) {
        filteredItems = resCategory;
        console.log("filtered after category processed :", filteredItems);
      }
    }

    if (this.sortBy) {
      var resSortBy = [];
      var processSortItems = [];

      if (filteredItems.length > 0) {
        processSortItems = filteredItems;
      } else {
        processSortItems = this.items;
      }

      if (this.sortBy == "Upload Date") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort(
            (a, b) =>
              Number(new Date(b.createDate ? b.createDate : "")) -
              Number(new Date(a.createDate ? a.createDate : ""))
          );
        } else {
          resSortBy = processSortItems.sort(
            (a, b) =>
              Number(new Date(a.createDate ? a.createDate : "")) -
              Number(new Date(b.createDate ? b.createDate : ""))
          );
        }
      }

      if (this.sortBy == "Category") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort((a, b) =>
            a.category < b.category ? 1 : a.category > b.category ? -1 : 0
          );
        } else {
          resSortBy = processSortItems.sort((a, b) =>
            a.category > b.category ? 1 : a.category < b.category ? -1 : 0
          );
        }
      }

      if (processSortItems.length > 0) {
        filteredItems = processSortItems;
        console.log("filtered after sort processed :", filteredItems);
      }
    }

    console.log("final filtered :", filteredItems);
    if (filteredItems.length > 0) {
      this.items = filteredItems;
    } else {
      if (this.category != null || this.search != null || this.sortBy != null) {
        NotificationLib.createWarningNotification(
          "No videos were found. Please Adjust the filters and try again."
        );
      }
    }

    Loader.setLoadingState(false);
  }

  async mounted(): Promise<void> {
    fbHook.logEvent(0, null);
    await this.init();

    if (this.$route.params.video) {
      var video = this.items.find(
        (video) => video.id == Number(this.$route.params.video)
      );
      // console.log("Video from Route params :", video);
      if (video) {
        //this.activeVideo = video;
        this.slideshow = this.items.findIndex(
          (videoTobeFound) => videoTobeFound.id == video?.id
        );
      }
    }
  }

  getVideoId(videoURL: string): string | null {
    const res = videoURL.split("/");
    if (res[3]) {
      if (res[3].includes("?")) {
        const addSplit = res[3].split("?");
        if (addSplit[0]) {
          //console.log("videoid", addSplit[0]);
          return addSplit[0];
        } else {
          return res[3];
        }
      } else {
        return res[3];
      }
    } else {
      return null;
    }
  }

  formatDate(date: string): string {
    return dayjs(date).format("YYYY-MM-DD HH:mm");
  }

  /* formatStatus(statusId: number): string {
    if (statusId == 0) {
      return "Pending";
    } else if (statusId == 1) {
      return "Approved";
    } else if (statusId == 2) {
      return "Declined";
    } else {
      return "";
    }
  } */

  async postComment(): Promise<void> {
    try {
      if (this.commentToAdd != null && this.activeVideo != null) {
        const temp: VideoCommentsViewModel = {
          comment: this.commentToAdd,
          videoId: this.activeVideo.id,
        };

        const res = await videosLib.AddCommentToVideo(temp);
        this.showCommentBox = true;
        this.commentToAdd = null;
        await this.init();
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteComment(): Promise<void> {
    try {
      if (this.deleteCommentItem && this.activeVideo != null) {
        this.deleteCommentItem.videoId = this.activeVideo.id;
        const res = await videosLib.DeleteCommentOnVideo(
          this.deleteCommentItem
        );
        this.deleteCommentModal = false;
        NotificationLib.createSuccessNotification("Comment Deleted.");
        await this.init();
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async editComment(comment: VideoCommentsViewModel): Promise<void> {
    try {
      if (comment.commentToUpdate && this.activeVideo) {
        const temp: VideoCommentsViewModel = {
          comment: comment.commentToUpdate,
          videoId: this.activeVideo.id,
          commentId: comment.commentId,
          createByUser: comment.createByUser,
          createDate: comment.createDate,
        };

        const res = await videosLib.UpdateCommentOnVideo(temp);
        NotificationLib.createSuccessNotification("Comment Updated.");
        await this.init();
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getVideos(): Promise<VideoTableViewModel[]> {
    try {
      const res = await videosLib.GetAllVideos(this.query);
      var items: VideoTableViewModel[] = [];
      res.videos.map((elem: Video) => {
        if (elem.status == 1) {
          items.push({
            createByUser: elem.createByUser,
            createDate: elem.createDate,
            deleted: elem.deleted,
            description: elem.description,
            id: elem.id,
            status: elem.status,
            title: elem.title,
            updateByUser: elem.updateByUser,
            updateDate: elem.updateDate,
            videoUrl: elem.videoUrl,
            createDateFormatted: this.formatDate(elem.createDate),
            videoId: this.getVideoId(elem.videoUrl),
            category: elem.category,
            videoComments: elem.videoComments,
          });
        }
      });
      items.map((video) => {
        if (video.videoComments) {
          video.videoComments.map((comment) => {
            comment.showEditCommentBox = false;
            comment.commentToUpdate = null;
            comment.readMore = false;
          });
          video.videoComments = video.videoComments.sort(
            (a, b) =>
              Number(new Date(b.createDate ? b.createDate : "")) -
              Number(new Date(a.createDate ? a.createDate : ""))
          );
        }
      });
      return Promise.resolve(items);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getVideoCategories(): Promise<VehicleCategories[]> {
    try {
      const res = await videosLib.GetVideoCategories();
      var items: VehicleCategories[] = [];
      items = res;
      return Promise.resolve(items);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      //this.items = await this.getVideos();
      await this.applyFilters();
      this.categories = await this.getVideoCategories();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async deleteVideo(): Promise<void> {
    try {
      Loader.setLoadingState(true);

      const res = await videosLib.DeleteVideo(this.deleteItem);
      NotificationLib.createSuccessNotification("Video Deleted");
      await this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.optionsModal = false;
      Loader.setLoadingState(false);
    }
  }

  async generateLink(): Promise<void> {
    if (!this.activeVideo) {
      return Promise.resolve();
    }

    const link = process.env.VUE_APP_URL + "/videos/" + this.activeVideo.id;

    let payload = {
      title: `IsCarTi: Video`,
      text:
        this.activeVideo.title +
        " has been shared with you. Click the link to watch the video.",
      url: link,
    };

    if (Capacitor.isNativePlatform()) {
      await CapShare.share({
        ...payload,
        dialogTitle: "Share Video with another party",
      });
    } else {
      await navigator.share(payload);
    }
    fbHook.logEvent(3, {
      method: "Generated Link",
      content_type: "Video Share",
      item_id: this.activeVideo.id.toString(),
    });
  }
}
