
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import dayjs from "dayjs";
import { ProductsForm } from "@/helpers/classes/Form";
import { storeHook } from "@/helpers/store";
import { ProductCategories, ProductsViewModel } from "@/models/store";

@Component({
  components: {
    ImageAttachment,
  },
  filters: {
    displayDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class Buy_SellModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly product!: ProductsViewModel | undefined;
  form: ProductsForm | null = null;
  valid = false;
  loading = false;
  productCategories: ProductCategories[] = [];
  productImages: File[] = [];
  price: string | null = null;
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    requiredMultiple: (v: string[]): boolean | string =>
      !v || v.length > 0 || "At least one option required.",
  };
  getBase64 = (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  formatCurrency(n: string | null) {
    // format number 1000000 to 1,234,567
    if (n != null) {
      this.price = Number(n).toLocaleString();
    }

    if (this.price != null && this.form != null) {
      console.log("price", Number(this.price.replace(/[^0-9.-]+/g, "")));
      this.form.form.price = Number(this.price.replace(/[^0-9.-]+/g, ""));
    }
  }
  populateForm() {
    this.form!.form.images = [];
    this.productImages.map(async (elem) => {
      const bs64 = await this.getBase64(elem);
      if (bs64 != null && typeof bs64 == "string")
        this.form!.form.images.push({
          fileName: elem.name,
          fileSize: elem.size,
          mimeType: elem.type,
          upload: bs64.split(",")[1],
          description: "",
        });
    });
    //console.log("images", this.form!.form.images);
  }
  removeImage(index: number) {
    this.form!.form.images.splice(index, 1);
    this.productImages.splice(index, 1);
  }

  @Watch("open")
  async onOpenChanged(val: boolean) {
    if (val == true) {
      await this.init();
      this.form = new ProductsForm(this.product);
    } else {
      this.close();
    }
  }

  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form && this.form.product) {
      this.form.compare();
    }
  }

  close(): void {
    this.$emit("close");
    this.productImages = [];
    this.price = null;
    this.modal = false;
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await storeHook.GetProductCategoriess();
      //console.log("Product Categories:", res);
      this.productCategories = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        await this.form.submit();
      }
      this.close();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.$emit("refresh");
    }
  }
}
