
import { Administrator, Driver, Employee } from "@/models/employee";
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { VForm } from "vuetify/lib/components";

@Component({})
export default class EmployeeForm extends VForm {
  @PropSync("data") datum!: Driver | null; // TODO@CVD Extend to Employee | Administrator when backend drops province
  rules = {
    required: (v: string) => !!v || "Please provide a value",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Please use atleast 1 uppercase character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Please use atleast 1 numeric character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Please use atleast 1 lowercase character";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Please use atleast 1 special character";
    },
    password: (v: string) => {
      return (!!v && v.length >= 8) || "Please use atleast 8 characters";
    },
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
    cell: (value: string | null = null): boolean | string => {
      if (value != null) {
        if (value.length == 10) return true;
      }
      return "Please provide a contact number";
    },
  };
  valid = false;
  get display() {
    return this.datum !== null;
  }
  set display(display: boolean) {
    if (!display) this.datum = null;
  }
  get getRules() {
    return this.rules;
  }
  submit() {
    if (this.datum) {
      const data = {
        email: this.datum.email,
        name: this.datum.name,
        userType: this.datum.userType,
        province: this.datum.province,
        phoneNumber: this.datum.phoneNumber,
      };
      this.$emit("submit", [data]);
    }
    this.close();
  }
  cancel() {
    if (this.datum) {
      const data = {
        email: this.datum.email,
        name: this.datum.name,
        userType: this.datum.userType,
        province: this.datum.province,
        phoneNumber: this.datum.phoneNumber,
      };
      this.$emit("cancel", [data]);
    }
    this.close();
  }
  close() {
    this.datum = null;
  }
}
