var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"pt-5 white--text",attrs:{"cols":"10"}},[_c('div',{staticClass:"text-title-1 text-left"},[_vm._v(" Please specify type of account "),_c('v-radio-group',{attrs:{"row":"","disabled":_vm.form.reference != null,"dark":"","dense":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('v-radio',{attrs:{"label":"Personal","value":"personal","color":"primary"}}),_c('v-radio',{attrs:{"label":"Business","value":"business","color":"primary"}})],1)],1)])],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.type == 'business'),expression:"form.type == 'business'"}],attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"10"}},[_c('div',{staticClass:"text-title-1 text-left white--text"},[_vm._v(" Select Type of Organization ")]),_c('v-select',{attrs:{"dense":"","disabled":_vm.form.reference != null,"full-width":"","outlined":"","hide-details":"auto","rules":[_vm.rules.required],"menu-props":{
          offsetY: true,
        },"item-text":"description","item-value":"id","label":"Organization *","items":_vm.companyType,"dark":""},model:{value:(_vm.form.form.companyType),callback:function ($$v) {_vm.$set(_vm.form.form, "companyType", $$v)},expression:"form.form.companyType"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-title-1 text-left ml-1 white--text"},[_vm._v(" Select a role of this user ")]),_c('v-select',{attrs:{"dense":"","full-width":"","outlined":"","disabled":_vm.form.reference != null,"hide-details":"","menu-props":{
          offsetY: true,
        },"item-text":"name","item-value":"name","label":"Role","items":_vm.roles,"dark":""},model:{value:(_vm.form.form.userType),callback:function ($$v) {_vm.$set(_vm.form.form, "userType", $$v)},expression:"form.form.userType"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[(_vm.getSteps)?_c('v-stepper',{staticClass:"mt-5 black",attrs:{"flat":"","non-linear":"","vertical":"","dark":""},model:{value:(_vm.curr),callback:function ($$v) {_vm.curr=$$v},expression:"curr"}},[_vm._l((_vm.getSteps),function(step,n){return [_c('v-stepper-step',{key:step.id,staticClass:"pl-4 primary rounded-0 registerStepper",staticStyle:{"border":"1px solid black"},attrs:{"editable":"","complete-icon":"mdi-check-circle","edit-icon":_vm.curr == n + 1 ? 'mdi-pencil-circle' : 'mdi-check-circle',"ripple":false,"error-icon":"mdi-alert-circle-outline","complete":_vm.stepComplete(n + 1),"step":n + 1,"rules":[
              (value) =>
                !!step.valid && (step.id == 'tcs' ? _vm.form.captcha : true),
            ],"color":_vm.stepStatus(n + 1)}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(step.name)+" ")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-up "+_vm._s(n + 1 == _vm.curr ? "mdi-rotate-180" : "")+" ")])],1)]),_c('v-stepper-content',{key:n,staticClass:"ml-0 black",attrs:{"step":n + 1}},[_c('div',{staticClass:"pt-3"},[_c('v-form',{ref:'stepForm',refInFor:true,model:{value:(step.valid),callback:function ($$v) {_vm.$set(step, "valid", $$v)},expression:"step.valid"}},[(step.id == 'perInfo')?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","rules":[_vm.getRules.required],"outlined":"","clearable":"","label":"Full Name *","dense":""},model:{value:(_vm.form.form.name),callback:function ($$v) {_vm.$set(_vm.form.form, "name", $$v)},expression:"form.form.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"validate-on-blur":"","hide-details":"auto","rules":[_vm.getRules.required, _vm.getRules.email],"disabled":_vm.form.reference != null,"clearable":"","type":"email","label":"Email Address *","outlined":"","dense":""},model:{value:(_vm.form.form.email),callback:function ($$v) {_vm.$set(_vm.form.form, "email", $$v)},expression:"form.form.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","rules":[_vm.getRules.required, _vm.getRules.cell],"clearable":"","counter":"10","type":"tel","outlined":"","label":"Cellphone *","dense":""},model:{value:(_vm.form.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form.form, "phoneNumber", $$v)},expression:"form.form.phoneNumber"}})],1)],1)]:_vm._e(),(step.id == 'busInfo')?[_c('v-row',{staticClass:"pb-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageAttachment',{staticClass:"mb-3",attrs:{"picture":_vm.form.form.logo,"disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"label":"Business Logo"},on:{"update:picture":function($event){return _vm.$set(_vm.form.form, "logo", $event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"rules":[_vm.getRules.required],"outlined":"","clearable":"","label":"Business Name *","dense":""},model:{value:(_vm.form.form.businessName),callback:function ($$v) {_vm.$set(_vm.form.form, "businessName", $$v)},expression:"form.form.businessName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","disabled":_vm.form.reference != null,"rules":[_vm.getRules.required],"prepend-inner-icon":"mdi-email","outlined":"","clearable":"","label":"Business Email *","dense":""},model:{value:(_vm.form.form.businessEmail),callback:function ($$v) {_vm.$set(_vm.form.form, "businessEmail", $$v)},expression:"form.form.businessEmail"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"rules":[_vm.getRules.required, _vm.getRules.cell],"type":"tel","outlined":"","counter":"10","clearable":"","label":"Business Phone Number *","dense":""},model:{value:(_vm.form.form.businessPhoneNo),callback:function ($$v) {_vm.$set(_vm.form.form, "businessPhoneNo", $$v)},expression:"form.form.businessPhoneNo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","rules":[_vm.getRules.required],"disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"outlined":"","clearable":"","label":"Business Reg Number *","dense":""},model:{value:(_vm.form.form.businessRegNo),callback:function ($$v) {_vm.$set(_vm.form.form, "businessRegNo", $$v)},expression:"form.form.businessRegNo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"hide-details":"auto","outlined":"","clearable":"","label":"Vat Number","dense":""},model:{value:(_vm.form.form.vatNo),callback:function ($$v) {_vm.$set(_vm.form.form, "vatNo", $$v)},expression:"form.form.vatNo"}})],1)],1)]:_vm._e(),(step.id == 'address')?[_c('v-row',{staticClass:"pb-5"},[(_vm.form.form.userType != 'Individual')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"clearable":"","label":"Building Name","dense":""},model:{value:(_vm.form.form.address.buildingName),callback:function ($$v) {_vm.$set(_vm.form.form.address, "buildingName", $$v)},expression:"form.form.address.buildingName"}})],1):_vm._e(),(_vm.form.form.userType != 'Individual')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","rules":[_vm.getRules.required],"outlined":"","clearable":"","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"label":"Street number *","dense":""},model:{value:(_vm.form.form.address.streetNo),callback:function ($$v) {_vm.$set(_vm.form.form.address, "streetNo", $$v)},expression:"form.form.address.streetNo"}})],1):_vm._e(),(_vm.form.form.userType != 'Individual')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","rules":[_vm.getRules.required],"disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"outlined":"","clearable":"","label":"Street Name *","dense":""},model:{value:(_vm.form.form.address.street),callback:function ($$v) {_vm.$set(_vm.form.form.address, "street", $$v)},expression:"form.form.address.street"}})],1):_vm._e(),(_vm.form.form.userType != 'Individual')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"rules":[_vm.getRules.required],"outlined":"","clearable":"","label":"Suburb *","dense":""},model:{value:(_vm.form.form.address.suburb),callback:function ($$v) {_vm.$set(_vm.form.form.address, "suburb", $$v)},expression:"form.form.address.suburb"}})],1):_vm._e(),(_vm.form.form.userType != 'Individual')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"rules":[_vm.getRules.required],"outlined":"","clearable":"","label":"City *","dense":""},model:{value:(_vm.form.form.address.city),callback:function ($$v) {_vm.$set(_vm.form.form.address, "city", $$v)},expression:"form.form.address.city"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Province *","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"clearable":"","menu-props":{
                          offsetY: true,
                        },"hide-details":"auto","outlined":"","dense":"","items":[
                          'Eastern Cape',
                          'Free State',
                          'Gauteng',
                          'KwaZulu-Natal',
                          'Limpopo',
                          'Mpumalanga',
                          'Northen Cape',
                          'North West',
                          'Western Cape',
                        ],"rules":[_vm.getRules.required]},on:{"change":function($event){return _vm.validate(n)}},model:{value:(_vm.form.form.address.province),callback:function ($$v) {_vm.$set(_vm.form.form.address, "province", $$v)},expression:"form.form.address.province"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.form.form.userType != 'Individual')?_c('v-text-field',{attrs:{"hide-details":"auto","disabled":_vm.form.reference
                            ? _vm.form.reference.userDetails.userType.includes(
                                'Driver'
                              ) == true
                            : false,"rules":[_vm.getRules.required],"outlined":"","clearable":"","label":"Area code *","dense":""},model:{value:(_vm.form.form.address.zipCode),callback:function ($$v) {_vm.$set(_vm.form.form.address, "zipCode", $$v)},expression:"form.form.address.zipCode"}}):_vm._e()],1)],1)]:_vm._e(),(step.id == 'tcs')?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","clearable":"","error-count":"3","rules":[
                          _vm.rules.required,
                          _vm.rules.capital,
                          _vm.rules.number,
                          _vm.rules.normalCase,
                          _vm.rules.special,
                          _vm.rules.password,
                        ],"append-icon":_vm.form.reveal == true ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.form.reveal ? 'text' : 'password',"outlined":"","label":"Password *","dense":""},on:{"click:append":function($event){_vm.form.reveal = !_vm.form.reveal}},model:{value:(_vm.form.form.password),callback:function ($$v) {_vm.$set(_vm.form.form, "password", $$v)},expression:"form.form.password"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","clearable":"","error-count":"3","rules":[
                          (!!_vm.form.confirmPassword &&
                            _vm.form.form.password === _vm.form.confirmPassword) ||
                            'Please confirm your password',
                        ],"append-icon":_vm.form.reveal ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.form.reveal == true ? 'text' : 'password',"outlined":"","label":"Confirm Password *","dense":""},on:{"click:append":function($event){_vm.form.reveal = !_vm.form.reveal}},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"black--text ml-3",attrs:{"rules":[_vm.rules.required],"hide-details":"auto","color":"primary","inset":"","dark":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption white--text"},[_vm._v(" I accept the "),_c('a',{attrs:{"onclick":"event.stopPropagation()","target":"_blank","href":"/terms-and-conditions"}},[_vm._v(" Terms & Conditions ")])])]},proxy:true}],null,true),model:{value:(_vm.form.acceptTCs),callback:function ($$v) {_vm.$set(_vm.form, "acceptTCs", $$v)},expression:"form.acceptTCs"}})],1)],1),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{attrs:{"id":"recaptcha2"}},[_c('recaptcha-2',{attrs:{"has-passed":_vm.form.captcha},on:{"update:hasPassed":function($event){return _vm.$set(_vm.form, "captcha", $event)},"update:has-passed":function($event){return _vm.$set(_vm.form, "captcha", $event)}}})],1)])],1)]:_vm._e()],2)],1)])]})],2):_vm._e()],1)],1),(!_vm.form.reference)?_c('v-row',{staticClass:"mt-3 flex-row-reverse",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":!_vm.getValid ||
          (_vm.form.reference ? false : _vm.form.acceptTCs == false || !_vm.getCaptcha),"block":"","color":"primary","large":"","dark":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.form.reference ? "Update" : "Register")+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }