
import { Component, Vue, PropSync } from "vue-property-decorator";

@Component({})
export default class AttachmentCheckModal extends Vue {
  @PropSync("open") attachmentCheckModal!: boolean;

  submit(): void {
    this.$emit("submit");
  }
}
