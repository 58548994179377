
import { Component, PropSync, Prop, Vue, Watch } from "vue-property-decorator";
import VehiclesModule from "@/store/modules/Vehicles";
import { vehicleLib } from "@/helpers/vehicle";
import { VehicleAttachment } from "@/models/files";

import ReviewAttachment from "@/views/vehicles/ReviewAttachment.vue";

import AttachmentCheckModal from "@/views/vehicles/attachments/attachmentAddedCheck.vue";
import dayjs, { Dayjs } from "dayjs";
import { DataTableHeader } from "vuetify";
import { ReviewAttachmentForm } from "@/helpers/classes/Form";

@Component({
  components: {
    ReviewAttachment,
    AttachmentCheckModal,
  },
  filters: {
    dateformat: (val: string) => {
      return dayjs(val).format("YYYY-MM-DD");
    },
  },
})
export default class AttachmentsModal extends Vue {
  tab = "";
  del: {
    open: boolean;
    attachment: VehicleAttachment | null;
  } = {
    open: false,
    attachment: null,
  };
  resetForm() {
    if (this.reviewAttachment && this.reviewAttachment.form) {
      this.reviewAttachment.form.reset();
    }
  }
  openDeletePrompt(item: VehicleAttachment) {
    this.del = {
      open: true,
      attachment: item,
    };
  }
  editAttachment(item: VehicleAttachment) {
    // console.log("Edit attachment", item, this.reviewAttachment);
    this.tab = "add";
    setTimeout(() => {
      if (!this.reviewAttachment) {
        const temp = this.$refs.reviewAttachment as ReviewAttachment;
        if (temp) this.reviewAttachment = temp;
      }
      if (this.reviewAttachment) {
        this.reviewAttachment.form = new ReviewAttachmentForm(item);
      }
    }, 100);
  }
  async submitProper() {
    // console.log("Submit create");
    if (!this.reviewAttachment || !this.reviewAttachment.form) {
      return;
    } else {
      //  console.log("Submitting");
      await this.reviewAttachment.form.submit();
      await this.downloadAttachments();
      this.tab = "view";
    }
  }
  reviewAttachment: ReviewAttachment | null = null;
  headers: DataTableHeader[] = [
    {
      text: "Description",
      value: "description",
    },
    {
      text: "Service Date",
      value: "serviceDate",
    },
    {
      text: "KM",
      value: "km",
    },
    {
      text: "Actions",
      value: "actions",
      width: "100",
    },
  ];
  get expanded() {
    return this.attachments.filter((item) => item.expand);
  }
  set expanded(expandedItems: any) {
    this.attachments.forEach((item) => {
      item.expand = expandedItems.includes(item);
    });
  }

  get validCreate() {
    if (this.reviewAttachment == null) {
      return false;
    }
    if (
      this.reviewAttachment.form &&
      this.reviewAttachment.form.unchanged == true
    ) {
      // console.log("Falign", this.reviewAttachment.form.unchanged);
      // return false;
      return false;
    }
    return this.reviewAttachment.getValid;
  }
  loading = false;
  attachments: VehicleAttachment[] = []; // Ideally :  ImageAttachment[] = [];

  attachmentCheckModal = false;

  get vehicle() {
    return VehiclesModule.getInteracted.item;
  }

  get display() {
    return VehiclesModule.getInteracted.attachments;
  }

  set display(val: boolean) {
    VehiclesModule.setInteractedFlag({
      key: "attachments",
      value: val,
    });
    VehiclesModule.setInteractedFlag({
      key: "item",
      value: null,
    });
  }

  @Watch("tab")
  onTabChange(val: string, oldVal: string) {
    setTimeout(() => {
      const temp = this.$refs.reviewAttachment as ReviewAttachment;
      // console.log("REview temp ", temp, val);
      this.reviewAttachment = temp ? temp : null;
      if (oldVal == "add" && val == "view") {
        if (this.reviewAttachment && this.reviewAttachment.form) {
          this.reviewAttachment.form = new ReviewAttachmentForm();
        }
      }
    }, 0);

    // this.reviewAttachment = temp;
  }

  @Watch("display")
  async downloadAttachments() {
    if (this.display) {
      if (this.vehicle) {
        this.attachments = await vehicleLib.GetAllAttachments(this.vehicle.id);
      }
      if (this.reviewAttachment && this.reviewAttachment.form) {
        this.reviewAttachment.form.reset();
      } else {
        if (this.reviewAttachment)
          this.reviewAttachment.form = new ReviewAttachmentForm();
      }
    } else {
      if (this.reviewAttachment && this.reviewAttachment.form) {
        this.reviewAttachment.form = null;
      }
    }
  }

  async submitDeleteAttachments(vehicle: VehicleAttachment) {
    await vehicleLib.DeleteAttachments(vehicle);
    await this.downloadAttachments();
    this.del.open = false;
    this.del.attachment = null;
  }

  close() {
    this.display = false;
  }
}
