import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  // Action,
} from "vuex-module-decorators";
import store from "@/store";
import { VehicleProfile, VehicleStatus } from "@/models/vehicle";
import { Status } from "@/models/services";
// import { Vehicle } from "@/models/vehicle";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Vehicles",
  store,
})
class Vehicles extends VuexModule {
  // manufacturers: null | string[] = null;
  // models: null | string[] = null;
  // years: null | number[] = null;
  statuses: Status[] = [];
  get getStatuses() {
    return this.statuses;
  }
  @Mutation
  setStatuses(items: VehicleStatus[]) {
    this.statuses = items;
  }
  interacted: {
    [key: string]: null | boolean | VehicleProfile;
    item: VehicleProfile | null;
    history: boolean;
    attachments: boolean;
    linkSharing: boolean;
    emailSharing: boolean;
  } = {
    item: null,
    history: false,
    attachments: false,
    linkSharing: false,
    emailSharing: false,
  };
  @Mutation
  setInteractedFlag(item: {
    key: string;
    value: boolean | null | VehicleProfile;
  }) {
    this.interacted[item.key] = item.value;
  }
  get getInteracted() {
    return this.interacted;
  }
  @Mutation
  setInteracted(item: {
    item: VehicleProfile | null;
    history: boolean;
    attachments: boolean;
    linkSharing: boolean;
    emailSharing: boolean;
  }) {
    this.interacted = item;
  }
  // get getManufacturers(): string[] | null {
  //   return this.manufacturers;
  // }
  // @Mutation
  // setManufacturers(manufacturers: string[] | null) {
  //   this.manufacturers = manufacturers;
  // }

  // get getModels(): string[] | null {
  //   return this.models;
  // }
  // @Mutation
  // setModels(models: string[] | null) {
  //   this.models = models;
  // }

  // get getYears(): number[] | null {
  //   return this.years;
  // }
  // @Mutation
  // setYears(years: number[] | null) {
  //   this.years = years;
  // }
}
export default getModule(Vehicles);
