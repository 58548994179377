
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Quote } from "@/models/documents";

@Component({})
export default class QuotesModal extends Vue {
  @PropSync("record") datum!: Quote | null;
  get display() {
    return this.datum !== null;
  }
  set display(toggle: boolean) {
    if (!toggle) this.datum = null;
  }
  submit() {
    this.$emit("submit", this.datum);
    this.datum = null;
  }
  cancel() {
    this.$emit("cancel", this.datum);
    this.datum = null;
  }
}
