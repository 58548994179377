
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { JobCard } from "@/models/services";
import { JobcardForm } from "@/helpers/classes/Form";
import { VehicleProfile } from "@/models/vehicle";

@Component({})
export default class JobCardModal extends Vue {
  @PropSync("isOpen") open!: boolean;
  @Prop() vehicle!: VehicleProfile | null;
  @Prop() jobcard!: JobCard | undefined;
  valid = false;
  form: JobcardForm | null = null;
  @Watch("open")
  onOpenChange(val: boolean, old: boolean) {
    if (val == true) {
      if (this.vehicle)
        this.form = new JobcardForm(this.vehicle.id, this.jobcard);
    } else {
      if (this.form) this.form.reset();
    }
  }
  rules = {
    mileage: (v: string) => Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };
  @Watch("form.form", {
    deep: true,
  })
  onFormChanged(val: JobCard | undefined, oldVal: JobCard | undefined) {
    if (val && this.form) {
      this.form.compare();
    }
  }
  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }
  async submit() {
    // console.log("Submit async call for jobcard");
    try {
      if (this.form) await this.form.submit();
      this.$emit("submit");
      this.open = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
