import { Invoice, Quote } from "@/models/documents";
import { Driver, Employee, Unregistered } from "@/models/employee";
import { JobCard } from "@/models/services";
import { VehicleProfile } from "@/models/vehicle";
import { axiosInstance } from "@/plugins/axios";

const FleetLib = () => {
  // const createDriver = async (quote: Quote): Promise<Quote> => {
  //   const res = await axiosInstance
  //     .post(`/api/Quote/GenerateQuote`, quote)
  //     .catch((err) => {
  //       return Promise.reject(err);
  //     });
  //   return Promise.resolve(res.data);
  // };

  const reviewDriver = async (
    driverId?: string | number
  ): Promise<Driver | Driver[]> => {
    if (driverId) {
      const res = await axiosInstance
        .get(`/api/Fleet/GetDriver`, { params: { id: driverId } })
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    } else {
      const res = await axiosInstance
        .get(`/api/Fleet/GetAllDrivers`)
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    }
  };

  const reviewUnregistered = async (): Promise<Unregistered[]> => {
    const res = await axiosInstance
      .get(`/api/Fleet/GetAllStaleDrivers`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  // const revokeInvitation = async (employeeId: number): Promise<any> => {
  // }

  const resendInvitation = async (
    employeeId: string | number
  ): Promise<any> => {
    if (employeeId) {
      const res = await axiosInstance
        .get(`/api/Fleet/ReshareLinkToIndividual`, {
          params: { id: employeeId },
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    } else {
      const res = await axiosInstance
        .get(`/api/Fleet/ReshareLink`)
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    }
  };

  const updateDriver = async (driver: Driver): Promise<Driver> => {
    const res = await axiosInstance
      .post(`/api/Fleet/UpdateDriver`, driver)
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const driverVehicles = async (
    driverId: number | string
  ): Promise<VehicleProfile[]> => {
    const res = await axiosInstance
      .get(`/api/Fleet/GetAllDriverVehicles`, {
        params: { driverId: driverId },
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const assign = async (
    vehicle: VehicleProfile,
    driver: Driver
  ): Promise<string> => {
    const res = await axiosInstance
      .get(`/api/Fleet/AssignVehicle`, {
        params: {
          driverId: driver.id,
          vehicleId: vehicle.id,
        },
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  const unassign = async (
    vehicle?: VehicleProfile,
    driver?: Driver
  ): Promise<Driver[]> => {
    // TODO@CVD Fix these calls
    if (driver !== undefined && vehicle !== undefined) {
      const res = await axiosInstance
        .get(`/api/Fleet/RemoveDriver`, {
          params: { driverId: driver.id, vehicleId: vehicle.id },
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    } else if (driver !== undefined) {
      const res = await axiosInstance
        .get(`/api/Fleet/RemoveAllVehiclesFromDriver`, {
          params: { driverId: driver.id },
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    } else if (vehicle !== undefined) {
      const res = await axiosInstance
        .get(`/api/Fleet/RemoveAllDriversFromVehicle`, {
          params: { vehicleId: vehicle.id },
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    } else {
      const res = await axiosInstance
        .get(`/api/Fleet/RemoveAllVehicles`)
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve(res.data);
    }
  };

  const deleteDriver = async (driver: Driver): Promise<any> => {
    const res = await axiosInstance
      .get(`/api/Fleet/DeleteDriver`, { params: { id: driver.id } })
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  };

  return {
    // createDriver,
    reviewDriver,
    updateDriver,
    assign,
    unassign,
    resendInvitation,
    // revokeInvitation,
    reviewUnregistered,
    deleteDriver,
    driverVehicles,
  };
};

const fleetLib = FleetLib();
export { fleetLib };
