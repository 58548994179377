
import { Quote } from "@/models/documents";
import VuePdfApp from "vue-pdf-app";
import {
  Component,
  Emit,
  Prop,
  PropSync,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import { VContainer } from "vuetify/lib";
import { config } from "vuex-module-decorators";

@Component({
  components: { VuePdfApp },
})
export default class DocumentViewer extends Vue {
  @Prop() document!: Blob | null;
  // fallback = require("@/assets/logo/logo.pdf");
  // let blob = await fetch(url).then(r => r.blob());
  // get document() {
  //   if (this.datum) {
  //     return this.datum;
  //   } else {
  //     // return this.fallback;
  //     return null;
  //   }
  // }
  @Watch("document")
  dump() {
    // console.log(this.document);
  }
  get display() {
    return this.document !== null;
  }
  config = { zoomIn: "zoomInId", zoomOut: "zoomOutId" };
  // @Emit()
  close() {
    this.$emit("close");
    // this.datum = null;
  }
}
