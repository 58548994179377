import { ImageAttachment, VehicleAttachment } from "@/models/files";
import {
  Vehicle,
  VehicleShared,
  VehicleProfile,
  ProfilePicture,
  VehicleCreate,
  CreateVehicleAttachments,
  VehicleProfiles,
  VehicleQuery,
  UpdateVehicleAttachments,
  ISharedVehicleCheck,
  VehicleSharedViaLink,
} from "@/models/vehicle";
import { axiosInstance } from "@/plugins/axios";
import VehicleModule from "@/store/modules/Vehicles";
import { fileToBase64 } from "@/utilities";

const VehicleLib = () => {
  const Vehicles = async (data: VehicleQuery): Promise<VehicleProfiles> => {
    const res = await axiosInstance.post(`/api/Vehicle/SearchVehicle`, data);
    return Promise.resolve(res.data);
  };
  const VehicleByQuery = async (
    data: VehicleQuery
  ): Promise<VehicleProfile> => {
    const res = await axiosInstance.post(`/api/Vehicle/SearchVehicle`, data);
    return Promise.resolve(res.data[0]);
  };
  const loadStatuses = async (): Promise<VehicleProfile> => {
    const res = await axiosInstance.get("/api/Vehicle/GetVehicleStatus");
    console.info("VehicleStatus", res.data);
    VehicleModule.setStatuses(res.data);
    return Promise.resolve(res.data);
  };
  const VehicleByID = async (
    data: string | number
  ): Promise<VehicleProfile> => {
    const res = await axiosInstance.get(
      `/api/Vehicle/GetVehicle?vehicleId=${data}`
    );
    return Promise.resolve(res.data);
  };
  const VehicleByVIN = async (data: VehicleQuery): Promise<VehicleProfile> => {
    const res = await axiosInstance.post(`/api/Vehicle/SearchVehicle`, data);
    return Promise.resolve(res.data);
  };

  const GetProfilePicture = async (
    vehicleId: number
  ): Promise<ProfilePicture> => {
    const res = await axiosInstance.post(
      `/api/Vehicle/GetProfilePicture?vehicleId=${vehicleId}`
    );

    return Promise.resolve(res.data);
  };

  const AddNewVehicle = async (data: VehicleCreate): Promise<Vehicle> => {
    const res = await axiosInstance.post(`/api/Vehicle/AddNewVehicle`, data);
    // console.log("Res ", res.data, data);
    return Promise.resolve(res.data);
  };

  const UpdateVehicle = async (data: Vehicle): Promise<void> => {
    const res = await axiosInstance.post(`/api/Vehicle/UpdateVehicle`, data);
    // console.log("Res ", res.data, data);
    return Promise.resolve(res.data);
  };

  const ShareVehicleViaEmail = async (data: VehicleShared): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/Vehicle/ShareVehicleViaEmail`,
      data
    );
    return Promise.resolve(res.data);
  };

  const ShareVehicleViaLink = async (
    data: VehicleSharedViaLink
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/Vehicle/ShareVehicleViaCell`,
      data
    );
    return Promise.resolve(res.data);
  };

  const DeleteVehicle = async (id: number): Promise<void> => {
    const res = await axiosInstance.get(`/api/Vehicle/DeleteVehicle?id=${id}`);

    return Promise.resolve(res.data);
  };
  const CheckSharedVehicle = async (
    userId: string,
    vehicleId: number
  ): Promise<ISharedVehicleCheck> => {
    const res = await axiosInstance.get(`/api/Vehicle/SharedCheck`, {
      params: {
        vehicleId: vehicleId,
        sharedByUserId: userId,
      },
    });

    return Promise.resolve(res.data);
  };

  const DownloadProfilePicture = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      `/api/Vehicle/DownloadProfilePicture?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadAttachment = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      `/api/Vehicle/DownloadAttachment?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const CreateAttachments = async (
    vehicleAttachments: CreateVehicleAttachments
  ): Promise<any> => {
    //console.log("Test: ", vehicleAttachments.attachment);
    const res = await axiosInstance.post(
      `/api/Vehicle/${vehicleAttachments.vehicleId}/SaveVehicleFiles`,
      vehicleAttachments
    );
  };

  const ReviewAttachments = async (
    vehicleId: number
  ): Promise<VehicleAttachment[]> => {
    const res = await axiosInstance.post(
      `/api/Vehicle/GetAllAttachments?vehicleId=${vehicleId}`
    ); // TODO@CVD: Include MIMETYPE here
    // console.log(res.data);
    return Promise.resolve(
      res.data
        .map((attachment: VehicleAttachment) => {
          attachment.expand = false;
          return attachment;
        })
        .sort((a: VehicleAttachment, b: VehicleAttachment) => {
          if (a.serviceDate > b.serviceDate) return 1;
          else if (a.serviceDate < b.serviceDate) return -1;
          else return 0;
        })
    );
  };

  const UpdateAttachments = async (
    vehicleAttachments: UpdateVehicleAttachments
  ): Promise<any> => {
    // TODO: This is not tested
    const res = await axiosInstance.post(
      `/api/Vehicle/UpdateVehicleFiles`,
      vehicleAttachments
    );
  };

  const DeleteAttachments = async (
    vehicleAttachments: VehicleAttachment
  ): Promise<any> => {
    // TODO: This is not tested
    const res = await axiosInstance.post(
      `/api/Vehicle/DeleteVehicleFiles`,
      vehicleAttachments
    );
  };

  return {
    CheckSharedVehicle,
    Vehicles,
    VehicleByQuery,
    VehicleByID,
    VehicleByVIN,
    loadStatuses,
    GetProfilePicture,
    AddNewVehicle,
    UpdateVehicle,
    ShareVehicleViaEmail,
    ShareVehicleViaLink,
    DeleteVehicle,
    DownloadProfilePicture,
    DownloadAttachment,
    // Attachments
    GetAllAttachments: ReviewAttachments,
    CreateAttachments,
    UpdateAttachments,
    DeleteAttachments,
  };
};

const vehicleLib = VehicleLib();
export { vehicleLib };
