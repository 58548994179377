import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Loader",
  store,
})
class Loader extends VuexModule {
  loading = false;

  get getLoadingState(): boolean {
    return this.loading;
  }
  @Mutation
  setLoadingState(val: boolean) {
    this.loading = val;
  }
}
export default getModule(Loader);
