
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import { generateMessageFromError } from "@/plugins/axios";
import { NotificationLib } from "@/helpers";
import NavModule from "@/store/modules/Nav";
import Recaptcha2 from "@/components/Recaptcha2.vue";
import { UserRegisterForm } from "@/helpers/classes/Form";
import { Profile, Registration } from "@/models/user";
import fbHook from "@/helpers/firebase";

@Component({
  components: {
    Recaptcha2,
    ImageAttachment,
  },
})
export default class Register extends Vue {
  @Prop() profile!: null | Registration;
  @PropSync("validForm") valid!: boolean;
  @Watch("profile", {
    deep: true,
    immediate: true,
  })
  onProfileChanged(val: Profile | undefined) {
    //console.log("Profile changed ", val);
    if (val) {
      const type =
        val.userDetails.userType == "Individual" ? "personal" : "business";
      this.form = new UserRegisterForm(type, val);
    }
  }
  @Watch("form.type")
  onFormTypeChanged(val: "personal" | "business") {
    // console.log("Profile form type ", val);
    this.form.reset(val);
  }
  curr = 1;
  steps: {
    name: string;
    id: "perInfo" | "busInfo" | "address" | "tcs";
    valid: boolean;
  }[] = [
    {
      name: "Personal Information",
      id: "perInfo",
      valid: false,
    },
    {
      name: "Business Information",
      id: "busInfo",
      valid: false,
    },
    { name: "Address", valid: false, id: "address" },
    { name: "Terms and conditions", id: "tcs", valid: false },
  ];
  get getSteps() {
    if (this.form.form.userType == "Individual") {
      return this.steps.filter((e) => {
        if (e.id != "busInfo") {
          if (this.form.reference) {
            if (e.id != "tcs") {
              return e;
            }
          }
          return e;
        }
      });
    } else {
      return this.steps.filter((e) => {
        if (this.form.reference) {
          if (e.id != "tcs") {
            return e;
          }
        } else {
          return e;
        }
      });
    }
  }
  @Watch("getValid")
  onValidChanged(val: boolean) {
    this.valid = val;
  }
  get getValid() {
    let valid = true;
    for (let i = 0; i < this.getSteps.length; i++) {
      if (this.getSteps[i].valid == false) {
        return false;
      }
      if (this.getSteps[i].id == "tcs" && this.form.captcha == false) {
        return false;
      }
    }
    return valid;
  }
  stepForm = [];
  get roles() {
    return NavModule.getSystemRoles.filter((role) => {
      return (
        role.normalizedName.includes("COMPANY") &&
        (this.profile == null
          ? role.normalizedName.includes("DRIVER") == false
          : true)
      );
    });
  }
  get companyType() {
    return NavModule.getSystemCompanyTypes.filter(
      (elem) => elem.description != "Mechanic"
    );
  }
  form = new UserRegisterForm(
    this.profile
      ? this.profile.userType == "Individual"
        ? "personal"
        : "business"
      : "business"
  );
  loading = false;
  // confirmPassword: string | null = null;
  // acceptTCs = false;
  // reveal = false;
  // revealConfirm = false;
  // captcha = false;

  get getRules() {
    return this.rules;
  }

  get getCaptcha(): boolean {
    return this.form.captcha;
  }
  stepComplete(step: number) {
    // console.log("Step complete ", step, this.getSteps[step - 1]);
    if (this.getSteps[step - 1].id == "tcs") {
      //last index. check for tcs
      if (this.form.captcha == false) {
        return false;
      }
    }
    return this.getSteps[step - 1].valid;
  }
  stepStatus(step: number) {
    if (this.getSteps[step - 1].id == "tcs") {
      //last index. check for tcs
      if (this.form.captcha == true && this.getSteps[step - 1].valid) {
        return "success";
      } else {
        return "yellow";
      }
    }
    return this.getSteps[step - 1].valid == true ? "success" : "orange";
    // return this.curr > step ? "green" : "blue";
  }
  validate(n: number) {
    this.steps[n].valid = false;
    let v = (this.$refs.stepForm as any)[n].validate();
    if (v) {
      // console.log("va;idate : ", v);
      if (this.getSteps[n].id == "tcs") {
        if (this.form.captcha == false) {
          return false;
        }
      }
      this.getSteps[n].valid = true;
      // continue to next
      this.curr = n + 2;
    }
  }
  done() {
    this.curr = 0;
  }

  rules = {
    required: (v: string) => !!v || "Please provide a value",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Please use atleast 1 uppercase character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Please use atleast 1 numeric character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Please use atleast 1 lowercase character";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Please use atleast 1 special character";
    },
    password: (v: string) => {
      return (!!v && v.length >= 8) || "Please use atleast 8 characters";
    },
    matchPass: (v: string) => {
      if (v === this.form.form.password) {
        return true;
      } else {
        return "Passwords do not match: ";
      }
    },
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
    cell: (value: string | null = null): boolean | string => {
      if (value != null) {
        if (value.length == 10) return true;
      }
      return "Please provide a contact number";
    },
  };
  // @Watch("entity", {
  //   immediate: true,
  // })
  // entityChanged(newEntity: boolean): void {
  //   if (!newEntity) {
  //     const role = NavModule.getSystemRoles.find((role) => {
  //       return role.normalizedName == "INDIVIDUAL";
  //     });
  //     if (role) {
  //       this.form.form.userType = role.name;
  //     }
  //     this.form.form.companyType = null;
  //   }
  // }
  close(): void {
    this.$emit("close");
    this.resetForm();
  }
  resetForm() {
    this.form.reset(this.form.type);
    this.getSteps.forEach((e, n) => {
      let v = (this.$refs.stepForm as any)[n];
      if (v) {
        v.reset();
      }
    });
  }
  async submit(): Promise<void> {
    try {
      this.loading = true;
      await this.form.submit();
      this.resetForm();
      this.$emit("login");
      fbHook.logEvent(2, null);
      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
