
import {
  Component,
  Vue,
  // Prop,
  PropSync,
  Watch,
  // Watch,
  // Model,
  // VModel,
  // Emit,
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthModule from "@/store/modules/Auth";
import { JobCard } from "@/models/services";
import { Invoice, Quote } from "@/models/documents";
import { DocumentLib, JobLib, NotificationLib, UserLib } from "@/helpers";
import VuePdfApp from "vue-pdf-app";
import CreateQuote from "@/views/vehicle/jobcards/quotes/Create.vue";
import ReviewQuote from "@/components/documents/pdf.vue";
import UpdateQuote from "@/views/vehicle/jobcards/quotes/Update.vue";
import DeleteQuote from "@/views/vehicle/jobcards/quotes/Delete.vue";
import { generateMessageFromError } from "@/plugins/axios";
import dayjs from "dayjs";
import { Business } from "@/models/business";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import Auth from "@/store/modules/Auth";

@Component({
  components: { CreateQuote, UpdateQuote, ReviewQuote, DeleteQuote, VuePdfApp },
  filters: {
    formatDateTime: (val: string) => {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class BillingModal extends Vue {
  @PropSync("data") jobcard!: JobCard | null;
  create: Quote | null = null;
  update: Quote | null = null;
  review: ArrayBuffer | null = null;
  delete: Quote | null = null;
  loading = false;
  get invoices(): Invoice[] {
    if (this.jobcard && this.jobcard.invoice !== null) {
      return Array(this.jobcard.invoice);
    } else {
      return [];
    }
  }
  get quotes(): Quote[] {
    return this.jobcard && this.jobcard.quotes
      ? this.jobcard.quotes.map((quote) => {
          const invoice = this.invoices.find((invoice) => {
            return invoice.quoteId == quote.quoteId;
          });
          if (invoice) quote.invoiceId = invoice.invoiceId;
          return quote;
        })
      : []; // .map((quote) -> {return {quote : quote, invoice = this.invoice if }})
  }
  config = {
    zoomIn: "zoomInId",
    zoomOut: "zoomOutId",
    print: "print",
    download: "download",
  };
  remove: Quote | null = null;
  stage = 1;
  get display() {
    return this.jobcard !== null;
  }
  set display(toggle: boolean) {
    if (!toggle) {
      this.jobcard = null;
      this.create = null;
      this.review = null;
      this.update = null;
      this.delete = null;
    }
  }
  get business(): Business {
    return AuthModule.getBusiness!; // By the time one is viewing quotes there had better be a  business
  }
  get getProfile() {
    return AuthModule.getProfile;
  }
  generateQuote() {
    if (this.jobcard) {
      this.create = {
        ...(this.jobcard.id ? { jobcardId: this.jobcard.id } : {}),
        ...(this.jobcard.vehicleId
          ? { vehicleId: this.jobcard.vehicleId }
          : {}),
        business: this.business,
        client: {
          name: "",
          email: "",
          phoneNumber: "",
          address: {
            streetNo: "",
            street: "",
            suburb: "",
            city: "",
            zipCode: "",
            province: "",
          },
        },
        // reference: "", // `${this.business.name} - ${this.business.name}`,
        groupJobs: [],
        legalese: "Terms and Conditions",
        quoteSubTotal: 0,
        quoteVAT: 0,
        quoteTotal: 0,
        // createBy: AuthModule.profile?.name ? AuthModule.profile?.name : "",
        // createDate: dayjs().format("YYYY-MM-DD HH:mm"),
        quoteDate: dayjs().format("YYYY-MM-DD"),
        expiryDate: dayjs().add(30, "day").format("YYYY-MM-DD"),
      };
    }
    // TODO@CVD: Fix Vehicle ID and Quote ID on update / invoicing
  }
  vatCheck(srcQuote: Quote): Quote {
    var noVatQuote: Quote = srcQuote;
    noVatQuote.quoteVAT = 0;
    noVatQuote.groupJobs.map((item) => {
      item.lineItems.map((lineItem) => {
        lineItem.vat = undefined;
      });
    });

    if (Auth.getProfile != null && Auth.getProfile.companyDetails != null) {
      if (
        Auth.getProfile.companyDetails.vatNo != "" ||
        Auth.getProfile.companyDetails.vatNo != null ||
        Auth.getProfile.companyDetails.vatNo != undefined ||
        Auth.getProfile.companyDetails.vatNo
      ) {
        return srcQuote;
      } else {
        return noVatQuote;
      }
    } else {
      return noVatQuote;
    }
  }
  async createQuote(srcQuote: Quote) {
    try {
      const data = this.vatCheck(srcQuote);
      //console.log("Quote Data: ", data);
      const tgtQuote = await DocumentLib.createQuote(data);
      this.$emit("created", tgtQuote);
      // this.jobcard?.quotes.push(tgtQuote); // Originally: QuotesModule.createQuote(newQuote);
      // console.log("Create Quote", srcQuote, tgtQuote);
      this.create = null;
      // this.updateJobCard();
      Promise.resolve(tgtQuote);
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject();
    } finally {
      // this.loading = false;
      this.updateJobCard();
      // this.stage = 5;
    }
  }
  async updateQuote(srcQuote: Quote) {
    // console.log("Update Quote", srcQuote);
    try {
      const data = this.vatCheck(srcQuote);
      var tgtQuote = await DocumentLib.updateQuote(data);
      tgtQuote = srcQuote; // Waitign for resolution on backend
      this.$emit("updated", tgtQuote);
      // console.log("Update Quote", this.update, srcQuote, tgtQuote);
      if (this.jobcard) {
        const tmpQuote = this.jobcard.quotes.find((quote) => {
          return quote.quoteId === tgtQuote.quoteId;
        });
        if (tmpQuote) {
          this.jobcard.quotes[this.jobcard.quotes.indexOf(tmpQuote)] = tgtQuote;
        }
      }
      // Originally:  QuotesModule.updateQuote(oldQuote, newQuote);
      this.update = null;
      Promise.resolve(tgtQuote);
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject();
    } finally {
      // this.loading = false;
      // this.stage = 5;
      await this.updateJobCard();
    }
  }
  async reviewQuote(quote: Quote) {
    this.loading = true;
    try {
      const document = (await DocumentLib.reviewQuoteAsPDF(
        quote,
        "arraybuffer"
      )) as ArrayBuffer;
      this.review = document;
      Promise.resolve(document);
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve(null);
    } finally {
      this.loading = false;
    }
    // this.remove = null;
  }
  async removeQuote(quote: Quote) {
    try {
      // console.log("Delete", quote);
      const response = await DocumentLib.deleteQuote(quote);
      this.$emit("deleted", quote);
      if (this.jobcard)
        this.jobcard.quotes.splice(this.jobcard.quotes.indexOf(quote), 1); // Originally : QuotesModule.deleteQuote(quote);
      // this.updateJobCard();
      this.remove = null;
      Promise.resolve(quote);
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve(null);
    } finally {
      await this.updateJobCard();
      // this.loading = false;
      // this.stage = 5;
    }
    this.remove = null;
  }
  async issueInvoice(quote: Quote): Promise<Invoice> {
    // this.stage = 5;
    // this.loading = true;
    try {
      const invoice = await DocumentLib.createInvoice(quote);
      if (this.jobcard)
        this.jobcard.quotes.forEach((item) => {
          if (invoice.quoteId == item.quoteId) {
            item.invoiceId = invoice.invoiceId;
          }
        });
      this.$emit("invoiceIssued", invoice);
      if (this.jobcard) this.jobcard.invoice = invoice;
      return Promise.resolve(invoice);
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject();
    } finally {
      await this.updateJobCard();
      // this.loading = false;
    }
  }
  async reviewInvoice(invoice: Invoice) {
    try {
      const document = (await DocumentLib.reviewInvoiceAsPDF(
        invoice,
        "arraybuffer"
      )) as ArrayBuffer;
      this.review = document;
      Promise.resolve(document);
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.resolve(null);
    } finally {
      // this.loading = false;
      // this.stage = 5;
    }
    this.remove = null;
  }
  async updateJobCard() {
    //console.log("Update JobCard");
    this.$emit("update");
  }
  async payment(invoice: Invoice) {
    // TODO@CVD: Waiting on Business to realize that they might want to mark an invoice as payed.
  }
  async shareQuote(document: Quote) {
    if (!this.getProfile) {
      return null;
    }
    if (Capacitor.isNativePlatform()) {
      await Share.share({
        title: `IsCarTi: ${document.reference}`,
        text: `${this.getProfile.userDetails.nameOfUser} would like to share a vehicle profile with you`,
        // url: `${process.env.VUE_APP_API_URL}/vehicles/`, // `${process.env.VUE_APP_API_URL}/vehicles/${this.vehicle.id}`
        files: [
          `${process.env.VUE_APP_API_URL}//api/Quote/DownloadQuotePDF?Id=${document.quoteId}`,
        ],
        dialogTitle: "Share vehicle profile with another party",
      });
    } else {
      await navigator.share({
        title: `IsCarTi: ${document.reference}`,
        text: `${this.getProfile.userDetails.nameOfUser} would like to share a vehicle profile with you`,
        files: [(await DocumentLib.reviewQuoteAsPDF(document, "file")) as File],
      });
    }
  }
  async shareInvoice(document: Invoice) {
    if (!this.getProfile) {
      return null;
    }
    if (Capacitor.isNativePlatform()) {
      await Share.share({
        title: `IsCarTi: ${document.reference}`,
        text: `${this.getProfile.userDetails.nameOfUser} would like to share a vehicle profile with you`,
        files: [
          `${process.env.VUE_APP_API_URL}//api/Quote/DownloadInvoicePDF?Id=${document.invoiceId}`,
        ],
        dialogTitle: "Share vehicle profile with another party",
      });
    } else {
      await navigator.share({
        title: `IsCarTi: ${document.reference}`,
        text: `${
          (
            await this.getProfile
          ).userDetails.nameOfUser
        } would like to share a vehicle profile with you`,
        files: [
          (await DocumentLib.reviewInvoiceAsPDF(document, "file")) as File,
        ],
      });
    }
  }
}
