
import { Component, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import AuthModule from "@/store/modules/Auth";
// import VehiclesModule from "@/store/modules/Vehicles";
import { DirectoryLib, NotificationLib } from "@/helpers";
import { generateMessageFromError } from "@/plugins/axios";
import { Mechanic } from "@/models/business";
import { Query } from "@/models/search";
import Nav from "@/store/modules/Nav";
import fbHook from "@/helpers/firebase";
import Loader from "@/store/modules/Loader";

interface MechanicWrapper {
  item: Mechanic;
  expanded: boolean;
}

@Component({
  filters: {
    displayDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Directory",
  },
})
export default class Directory extends Vue {
  mechanics: MechanicWrapper[] = [];
  slideshow = 0;
  scrolled = 0;
  showMore = true;
  optionsModal = false;
  options: {
    sortBy: {
      text: string;
      value: string | null;
    }[];
    groupBy: {
      text: string;
      value: string | null;
    }[];
  } = {
    sortBy: [
      {
        text: "Name",
        value: "name",
      },
    ],
    groupBy: [
      {
        text: "Province",
        value: "province",
      },
    ],
  };
  query: Query = {
    searchString: "",
    filterOnList: 3,
    page: 1,
    itemsPerPage: 10,
    sortBy:
      this.options.sortBy[0] && this.options.sortBy[0].value
        ? [this.options.sortBy[0].value]
        : [],
    sortDesc: [true],
    groupBy:
      this.options.groupBy[0] && this.options.groupBy[0].value
        ? [this.options.groupBy[0].value]
        : [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  get availFilterList() {
    return [
      {
        id: 3,
        description: "All",
      },
    ].concat(Nav.getSystemCompanyTypes);
  }

  next() {
    if (this.slideshow != this.mechanics.length - 1) {
      this.slideshow = this.slideshow + 1;
    }
  }
  prev() {
    if (this.slideshow != 0) {
      this.slideshow = this.slideshow - 1;
    }
  }

  @Watch("query", { deep: true })
  async init() {
    Loader.setLoadingState(true);
    try {
      if (AuthModule.isLoggedIn == false) return Promise.resolve();
      const res = await DirectoryLib.Directory(this.query);
      // console.log("directory: ", res);
      if (res.mechanics == null) {
        this.mechanics = [];
      } else {
        /* this.mechanics = res.mechanics.map((e) => {
          return {
            item: e,
            expanded: false,
          };
        }); */
        res.mechanics.map((e) => {
          if (e.approved == true) {
            this.mechanics.push({
              item: e,
              expanded: false,
            });
          }
        });
        // this.mechanics = this.mechanics.concat(cloneDeep(this.mechanics));
        // this.mechanics = this.mechanics.concat(cloneDeep(this.mechanics));
        // this.mechanics = this.mechanics.concat(cloneDeep(this.mechanics));
      }
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }

  async mounted() {
    await this.init();
    fbHook.logEvent(0, null);
  }

  /* created(): void {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollBy(0, 0);
  }

  unmounted(): void {
    window.removeEventListener("scroll", this.handleScroll);
  } */

  /*  handleScroll(): void {
    this.scrolled = window.scrollY;
  } */
}
