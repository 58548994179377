
import { Component, Vue, Watch } from "vue-property-decorator";
import { Device } from "@capacitor/device";
import { App as AppCap, URLOpenListenerEvent } from "@capacitor/app";
import { NotificationLib, RolesLib, StorageLib, UserLib } from "@/helpers";
import AuthModule from "@/store/modules/Auth";
import GlobalNotifications from "@/components/GlobalNotifications.vue";
import {
  PushNotifications,
  Token,
  PushNotificationSchema,
  ActionPerformed,
  DeliveredNotifications,
} from "@capacitor/push-notifications";
import NotificationSystem from "./store/modules/NotificationSystem";
import SplashScreen from "@/components/Splashscreen.vue";
import fbHook from "./helpers/firebase";
import updateNotif from "@/components/pwa/updateAvailNotif.vue";
import InstallBanner from "@/components/pwa/InstallBanner.vue";
import OfflineFallback from "@/components/pwa/OfflineFallback.vue";
import { Profile } from "./models/user";
import { generateMessageFromError } from "./plugins/axios";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    GlobalNotifications,
    updateNotif,
    InstallBanner,
    OfflineFallback,
    SplashScreen,
    Loader,
    // Auth,
  },
  metaInfo(this: App) {
    return {
      title: "IsCarTi",
      titleTemplate: "%s | Vehicle Block Chain Management",
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "IsCarTi applies block chain management to vehicles so that all parties concerned may be assured of the repair, service and sale history of a vehicle.",
        },
      ],
    };
  },
})
export default class App extends Vue {
  loading = true;
  showSplashScreen = false;

  stopLoading(): void {
    this.loading = false;
  }
  async loadUserRoles() {
    await RolesLib.userRoles();
  }
  async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  }

  get loggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }

  async addListeners() {
    await AppCap.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tabs2
      // slug = /tabs/tabs2
      const slug = event.url.split(".co.za").pop();

      // We only push to the route if there is a slug present
      if (slug) {
        this.$router.push({
          path: slug,
        });
      }
    });
    await PushNotifications.addListener("registration", async (token) => {
      try {
        console.info(
          "Registration token: ",
          token.value,
          AuthModule.getProfile
        );
        await StorageLib.setFcmToken(token.value);
        NotificationSystem.setFirebaseToken(token.value);
        //set token in local storage/vuex
        // if user doPushNotifications
        if (this.loggedIn) {
          await UserLib.getProfile();
        }

        console.info("Registration token after: ", AuthModule.getProfile);
        if (AuthModule.getProfile != null) {
          //console.log("doPushNotif parent block");
          await NotificationLib.doPushNotifications(token.value);
        } else {
          // this should not happen though test
          NotificationLib.createWarningNotification(
            `Notification does not register after getProfile. ${token.value}`
          );
        }
      } catch (err) {
        const message = generateMessageFromError(err);
        NotificationLib.createErrorNotification(message);
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
      NotificationLib.createErrorNotification(
        "Mobile Push notification registration failed. Contact the dev team."
      );
    });
    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema) => {
        /* console.log(
          "Notification in foreground: ",
          JSON.stringify(notification)
        ); */
        // vibrate

        // create notification based on X Y Z
        const dev = await Device.getInfo();
        if (dev) {
          if (dev.platform == "android") {
            // console.log("Android 11 and up", dev.osVersion);
            const temp = Number(dev.osVersion);
            if (dev.osVersion && Number.isNaN(temp) == false && temp >= 11) {
              // return Promise.resolve();
            }
          }
        }

        // const getAllDelivered =
        //   await PushNotifications.getDeliveredNotifications();
        // console.log("getAllDelivered", getAllDelivered);

        // NotificationLib.createAlarmNotification({
        //   id: notification.id,
        //   title: notification.title,
        //   body: notification.body,
        //   cb: () => {
        //     this.$router.push({
        //       name: "vehicles",
        //       params: {
        //         id: notification.data.id,
        //       },
        //     });
        //   },
        // });

        return Promise.resolve();
      }
    );
    // Method called when tapping on a notification
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: ActionPerformed) => {
        /* console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        ); */

        const getAllDelivered =
          await PushNotifications.getDeliveredNotifications();
        //console.log("getAllDelivered", getAllDelivered);

        //console.log("KP -- notif: ", notification);
        //console.log("KP -- notif.notif: ", notification.notification);
      }
    );
  }

  async getPWAToken(): Promise<string | null> {
    const temp = await navigator.serviceWorker.getRegistration();
    var token: string | null = null;

    /* console.log("temp: ", temp); */
    if (temp) {
      //console.log("Requesting permission...");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        //console.log("Notification permission granted.");
        fbHook.initApp();
        token = await fbHook.getTokenAsync(temp);
      } else {
        //console.log("Notification permission denied.");
        token = null;
      }
    } else {
      //console.log("No registration for service worker");
      token = null;
    }
    return Promise.resolve(token);
  }

  get getProfile(): Profile | null {
    //console.log("getProfile: ", AuthModule.getProfile);
    return AuthModule.getProfile;
  }

  @Watch("getProfile", { immediate: false, deep: true })
  async getProfileChanged(): Promise<void> {
    const devInfo = await Device.getInfo();
    const id = await Device.getId();
    //checks to see if pwa is installed
    const media = window.matchMedia("(display-mode: standalone)").matches;
    const andref = document.referrer.includes("android-app://");
    const token = devInfo.platform == "web" ? await this.getPWAToken() : null;

    if (token != null) {
      await StorageLib.setFcmToken(token);
      NotificationSystem.setFirebaseToken(token);
    }

    //console.log("media: ", media);
    //console.log("andref: ", andref);
    const tok = NotificationSystem.getFirebaseToken;
    if (this.getProfile != null && (media == true || andref == true)) {
      //console.log("token w/ getProfile: ", tok, token);
      if (tok != null) {
        await NotificationLib.registerFcmOnServer(tok, devInfo, id);
        // console.log("Token: ", tok, "devInfo: ", devInfo, "deviceId: ", id);
      }
    } else if (this.getProfile != null && devInfo.platform != "web") {
      // console.log("token w/ getProfile: ", tok, token);
      if (tok != null) {
        await NotificationLib.registerFcmOnServer(tok, devInfo, id);
        // console.log("Token: ", tok, "devInfo: ", devInfo, "deviceId: ", id);
      }
    }
  }

  @Watch("loggedIn", { immediate: false, deep: true })
  loggedInChanged() {
    if (AuthModule.isLoggedIn == true) {
      this.showSplashScreen = false;
    } else {
      this.showSplashScreen = true;
    }
  }

  async created() {
    if (AuthModule.isLoggedIn == true) {
      this.showSplashScreen = false;
    } else {
      this.showSplashScreen = true;
    }
    const devInfo = await Device.getInfo();

    if (devInfo.platform == "android" || devInfo.platform == "ios") {
      //console.log("OS: ", devInfo.osVersion);
      // const temp = Number(devInfo.osVersion);
      // if (devInfo.osVersion && Number.isNaN(temp) == false && temp >= 11) {
      //   console.log("Foreground Notifications hid on: ", devInfo.osVersion);
      // }
      this.addListeners();
    }
  }

  async mounted() {
    await RolesLib.allRoles();
    await RolesLib.companyTypes();
  }
}
