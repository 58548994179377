
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { UserLib } from "@/helpers";
import { Profile } from "@/models/user";
import ConfirmPasswordCard from "@/components/ConfirmDriver/ConfirmPasswordCard.vue";
import ConfirmDriverCard from "@/components/ConfirmDriver/ConfirmDriverCard.vue";
import AuthModule from "@/store/modules/Auth";

@Component({
  components: {
    ConfirmDriverCard,
    ConfirmPasswordCard,
  },
})
export default class ConfirmDriver extends Vue {
  valid = false;
  currentComponent: typeof ConfirmDriverCard | typeof ConfirmPasswordCard =
    ConfirmDriverCard;
  profile: null | Profile = null;
  get authenticated() {
    return AuthModule.isLoggedIn;
  }
  rules = {
    required: (v: string) => !!v || "Email is required",
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
  };
  async mounted() {
    this.profile = await UserLib.getProfile(this.$route.params.uuid);
  }
  submit() {
    switch (this.currentComponent) {
      case ConfirmDriverCard: {
        // console.log("Move to Password");
        this.currentComponent = ConfirmPasswordCard;
        break;
      }
      case ConfirmPasswordCard: {
        // console.log("Move to Site");
        this.$router.push("vehicles");
        break;
      }
    }
  }
}
