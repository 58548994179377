
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import Login from "@/views/authentication/Login.vue";
import Register from "@/views/authentication/Register.vue";
import ForgotPassword from "@/views/authentication/ForgotPassword.vue";
import NavModule from "@/store/modules/Nav";

@Component({
  components: {
    Login,
    Register,
    ForgotPassword,
  },
})
export default class Authenticate extends Vue {
  @PropSync("open") modal!: boolean;
  validReg = false;
  get tab() {
    return NavModule.getTab;
  }
  set tab(val: "login" | "register") {
    NavModule.setTab(val);
  }
  @Watch("tab")
  onTabChange(val: "login" | "register") {
    this.$router.push({ name: "authentication", hash: `#${val}` });
    //console.log("Tab change -", val, " - ", this.$route);
  }
  registered = false;
  forgotPasswordActive = false;
  mounted() {
    switch (this.$route.hash) {
      case "#login":
        this.tab = "login";
        break;
      case "#register":
        this.tab = "register";
        break;
      default:
        this.tab = "login";
        break;
    }
  }
}
