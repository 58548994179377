import NotificationSystem from "@/store/modules/NotificationSystem";
import { notificationType } from "models";
import { PushNotifications } from "@capacitor/push-notifications";
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { axiosInstance } from "@/plugins/axios";
import { NotificationLib, StorageLib } from ".";

const delay = 100;

const Notifications = () => {
  const createAlarmNotification = (notif: {
    title: string | undefined;
    body: string | undefined;
    id: string;
    cb: () => void;
  }): notificationType => {
    const temp = {
      color: "warning",
      title: notif.title,
      message: notif.body != undefined ? notif.body : "Message not loaded",
      icon: "mdi-alarm",
      timeout: 10000,
      id: notif.id,
      cb: notif.cb,
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createSuccessNotification = (message: string): notificationType => {
    const temp = {
      color: "success",
      message: message,
      icon: "mdi-check-all",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000).toString(),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createErrorNotification = (message: string): notificationType => {
    const temp = {
      color: "error",
      message: message,
      icon: "mdi-alert-circle",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000).toString(),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createWarningNotification = (message: string): notificationType => {
    const temp = {
      color: "warning",
      message: message,
      icon: "mdi-shield-alert",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000).toString(),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const registerFirebaseNotifications = async () => {
    // NotificationLib.createWarningNotification("Checking permisssions");
    // console.log("Checking permisssions");
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      // console.log("Requesting permission here");
      permStatus = await PushNotifications.requestPermissions();
    }
    console.warn("Register fcm ", permStatus);
    // NotificationLib.createWarningNotification(`PErm status: ${permStatus}`);
    if (permStatus.receive !== "granted") {
      // rejected permissions
      return Promise.resolve();
    }

    return await PushNotifications.register();
  };
  const registerFcmOnServer = async (
    token: string,
    devInfo: DeviceInfo,
    id: DeviceId
  ) => {
    const res = await axiosInstance.get("/api/Login/SyncDeviceTokens", {
      params: {
        token: token,
      },
    });
  };
  // const registerFcmOnServer = async (
  //   token: string,
  //   devInfo: DeviceInfo,
  //   id: DeviceId
  // ) => {
  //   const res = await axiosInstance.post("/api/Login/SyncDeviceTokens", {
  //     token,
  //     platform: devInfo.platform,
  //     deviceUuid: id.uuid,
  //   });
  // };
  const deregisterFcmOnServer = async () => {
    const token = await StorageLib.getFcmToken();
    if (token == null) {
      // NotificationLib.createWarningNotification("No FCM token to deregister");
      return Promise.resolve();
    }

    const res = await axiosInstance.get("/api/Login/RemoveDeviceToken", {
      params: {
        token: token,
      },
    });
    return Promise.resolve();
  };
  const doPushNotifications = async (token: string) => {
    const devInfo = await Device.getInfo();
    // if (devInfo.platform != "android" || devInfo.platform == "ios") {
    const id = await Device.getId();
    // await registerFirebaseNotifications();

    await registerFcmOnServer(token, devInfo, id);
    // }
  };

  return {
    deregisterFcmOnServer,
    doPushNotifications,
    registerFcmOnServer,
    registerFirebaseNotifications,
    createSuccessNotification,
    createWarningNotification,
    createErrorNotification,
    createAlarmNotification,
  };
};
const notifications = Notifications();
export { notifications };
