import { JobCard } from "@/models/services";
import {
  CustomCalendarEvent,
  CustomCalendarEventSubmit,
  Event,
} from "@/models/calendar";
import { axiosInstance } from "@/plugins/axios";

const CalendarLib = () => {
  const searchForJobcards = async (search: string): Promise<JobCard[]> => {
    const res = await axiosInstance.post(`/api/booking/SearchAndSyncVehicle`, {
      regNumber: search,
    });
    return Promise.resolve(res.data);
  };
  const getEvents = async (): Promise<Event[]> => {
    const res = await axiosInstance.get(`/api/booking/GetBookings`);
    return Promise.resolve(res.data);
  };
  const submitEvent = async (payload: CustomCalendarEventSubmit) => {
    // console.log("Payload ", payload);
    const res = await axiosInstance.post(`/api/booking/CreateBooking`, payload);
    return Promise.resolve(res.data);
  };
  const updateEvent = async (payload: CustomCalendarEventSubmit) => {
    // console.log("Payload ", payload);
    const res = await axiosInstance.post(`/api/booking/UpdateBooking`, payload);
    return Promise.resolve(res.data);
  };
  const deleteBooking = async (payload: number) => {
    // console.log("Payload ", payload);
    const newPayload: CustomCalendarEventSubmit = {
      id: payload,
      color: "",
      bookingDate: new Date(),
      outDate: new Date(),
      clientName: "",
      clientEmail: "",
      clientPhone: "",
      vehicleRegistration: "",
      vehicleId: undefined,
      jobCardId: undefined,
      make: "",
      model: "",
      year: 0,
      reason: "",
      companyId: undefined,
    };
    const res = await axiosInstance.post(
      `/api/booking/RemoveBooking`,
      newPayload
    );
    return Promise.resolve(res.data);
  };

  return {
    deleteBooking,
    searchForJobcards,
    getEvents,
    submitEvent,
    updateEvent,
  };
};
const calendarLib = CalendarLib();
export { calendarLib };
