import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { UserRole } from "@/models/user";

interface CompanyType {
  id: number;
  description: "Fleet" | "Mechanic";
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Nav",
  store,
})
class Nav extends VuexModule {
  systemCompanyTypes: CompanyType[] = [];
  systemRoles: UserRole[] = [];
  tab: "login" | "register" = "login";
  appDrawer = false;

  get getAppDrawer(): boolean {
    return this.appDrawer;
  }
  @Mutation
  setAppDrawer(val: boolean) {
    this.appDrawer = val;
  }

  get getTab(): "login" | "register" {
    return this.tab;
  }

  @Mutation
  setTab(token: "login" | "register") {
    this.tab = token;
  }
  get getSystemRoles() {
    return this.systemRoles;
  }

  @Mutation
  setSystemRoles(token: UserRole[]) {
    this.systemRoles = token;
  }
  get getSystemCompanyTypes() {
    return this.systemCompanyTypes;
  }

  @Mutation
  setSystemCompanyTypes(token: CompanyType[]) {
    this.systemCompanyTypes = token;
  }
}
export default getModule(Nav);
