
import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Privacy Policy",
    meta: [
      {
        name: "description",
        content: "General Information about ISCARTI.",
      },
      {
        property: "og:description",
        content: "General Information about ISCARTI.",
      },
    ],
  },
})
export default class PrivacyPolicy extends Vue {
  CompanyName = "ISCARTI TECH (PTY) LTD";
  CompanyRegistration = "2014/168073/07";
  CompanyEMail = "admin@iscarti.co.za";
  ApplicationName = "ISCARTI TECH (PTY) LTD";
  CompanyNumber = "063 685 8113";
  Website = "www.iscarti.com";
}
