/* eslint-disable no-console */

import { Device } from "@capacitor/device";
import { register } from "register-service-worker";

const shouldReg = Device.getInfo().then((devInfo) => {
  if (
    (devInfo.platform == "web" && devInfo.operatingSystem == "windows") ||
    devInfo.operatingSystem == "mac"
  ) {
    registerSW();
    console.log("Register PWA service worker");
  }
});
const registerSW = () => {
  if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          "App is being served from cache by a service worker.\n" +
            "For more details, visit https://goo.gl/AFskqB"
        );
      },
      registered(registration) {
        console.log("Service worker has been registered.", registration);
        setInterval(() => {
          console.log("Checked for update in registered");
          registration.update();
        }, 1000 * 60);
      },
      cached() {
        console.log("Content has been cached for offline use.");
      },
      updatefound() {
        console.log("New content is downloading.");
      },
      updated(registration: ServiceWorkerRegistration) {
        console.log("New content is available; please refresh.");
        document.dispatchEvent(
          new CustomEvent("swUpdated", { detail: registration })
        );
      },
      offline() {
        console.log(
          "No internet connection found. App is running in offline mode."
        );
      },
      error(error) {
        console.error("Error during service worker registration:", error);
      },
    });
  }
};
