import { render, staticRenderFns } from "./ShareVehicleViaLink.vue?vue&type=template&id=4900c842&scoped=true&"
import script from "./ShareVehicleViaLink.vue?vue&type=script&lang=ts&"
export * from "./ShareVehicleViaLink.vue?vue&type=script&lang=ts&"
import style0 from "./ShareVehicleViaLink.vue?vue&type=style&index=0&id=4900c842&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4900c842",
  null
  
)

export default component.exports