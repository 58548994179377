
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import { NotificationLib, storeLib } from "@/helpers";
import fbHook from "@/helpers/firebase";
import { ProductCategories, ProductsViewModel } from "@/models/store";
import Loader from "@/store/modules/Loader";
import { Query } from "@/models/search";
import { Share as CapShare } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
import { storeHook } from "@/helpers/store";
import Products from "@/views/Cars_&_Parts/Cars_&_PartsModal.vue";

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Buy/Sell",
    meta: [
      {
        name: "description",
        content: "ISCARTI Buy/Sell.",
      },
      {
        property: "og:description",
        content: "ISCARTI Buy/Sell.",
      },
    ],
  },
  filters: {
    description: function (value: string) {
      if (value.length >= 75) {
        const front = value.substring(0, 75);

        return front + "...";
      } else {
        return value;
      }
    },

    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
  components: {
    Products,
  },
})
export default class Buy_Sell extends Vue {
  optionsModal = false;
  showMore = false;
  readMore = false;
  slideshow = 0;
  productImages = 0;
  items: ProductsViewModel[] = [];
  activeProduct: ProductsViewModel | null | undefined = null;
  search: string | null = null;
  category: string | null = null;
  categories: ProductCategories[] = [];
  sortBy: string | null = null;
  sortByList = ["Price", "Category"];
  sortDesc = true;
  modal = false;
  modalItem: ProductsViewModel | undefined | null = null;
  deleteModal = false;
  deleteItem = {} as ProductsViewModel;
  query: Query = {
    searchString: "",
    filterOnList: 1,
    page: 0,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  get getLoggedInUserEmail(): string | null {
    if (AuthModule.getProfile && AuthModule.getProfile.userDetails.email) {
      return AuthModule.getProfile.userDetails.email.toLocaleLowerCase();
    } else {
      return null;
    }
  }

  get getActiveProductUserEmail(): string | null {
    if (this.activeProduct && this.activeProduct.createdBy) {
      return this.activeProduct.createdBy.toLocaleLowerCase();
    } else {
      return null;
    }
  }

  openEdit(i: any): void {
    console.log(i);
    this.modalItem = i;
    this.modal = true;
  }

  openAdd(): void {
    this.modalItem = undefined;
    this.modal = true;
  }

  openDelete(i: any): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  next() {
    if (this.slideshow != this.items.length - 1) {
      this.slideshow = this.slideshow + 1;
    }
  }
  prev() {
    if (this.slideshow != 0) {
      this.slideshow = this.slideshow - 1;
    }
  }

  nextImage() {
    if (
      this.activeProduct &&
      this.productImages != this.activeProduct.images.length - 1
    ) {
      this.productImages = this.productImages + 1;
    }
  }
  prevImage() {
    if (this.slideshow != 0) {
      this.productImages = this.productImages - 1;
    }
  }

  @Watch("slideshow", { immediate: true })
  @Watch("items", { immediate: true })
  activeSlideChanged(): void {
    this.activeProduct = this.items[this.slideshow];
    // console.log("Active Product Changed", this.activeProduct);
  }

  async applyFilters(): Promise<void> {
    Loader.setLoadingState(true);
    this.items = await this.getProducts();
    var filteredItems: ProductsViewModel[] = [];

    if (this.search) {
      var resSearch = this.items.filter(
        (elem) =>
          (this.search ? elem.title.includes(this.search) : null) ||
          (this.search ? elem.description.includes(this.search) : null) ||
          (this.search ? elem.price.toString().includes(this.search) : null) ||
          (this.search
            ? elem.createdBy?.toString().includes(this.search)
            : null) /* ||
          (this.search ? elem.category.includes(this.search) : null) */
      );
      if (resSearch.length > 0) {
        filteredItems = resSearch;
        console.log("filtered after search processed :", filteredItems);
      }
    }

    if (this.category) {
      var resCategory: ProductsViewModel[] = [];
      var processCategoryItems = [];

      if (filteredItems.length > 0) {
        processCategoryItems = filteredItems;
      } else {
        processCategoryItems = this.items;
      }

      processCategoryItems.map((item) => {
        if (item.category == this.category) {
          resCategory.push(item);
        }
      });

      if (resCategory.length > 0) {
        filteredItems = resCategory;
        console.log("filtered after category processed :", filteredItems);
      }
    }

    if (this.sortBy) {
      var resSortBy = [];
      var processSortItems = [];

      if (filteredItems.length > 0) {
        processSortItems = filteredItems;
      } else {
        processSortItems = this.items;
      }

      if (this.sortBy == "Price") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort((a, b) =>
            a.price < b.price ? 1 : a.price > b.price ? -1 : 0
          );
        } else {
          resSortBy = processSortItems.sort((a, b) =>
            a.price > b.price ? 1 : a.price < b.price ? -1 : 0
          );
        }
      }

      if (this.sortBy == "Category") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort((a, b) =>
            a.category < b.category ? 1 : a.category > b.category ? -1 : 0
          );
        } else {
          resSortBy = processSortItems.sort((a, b) =>
            a.category > b.category ? 1 : a.category < b.category ? -1 : 0
          );
        }
      }

      if (processSortItems.length > 0) {
        filteredItems = processSortItems;
        console.log("filtered after sort processed :", filteredItems);
      }
    }

    console.log("final filtered :", filteredItems);
    if (filteredItems.length > 0) {
      this.items = filteredItems;
    } else {
      if (this.category != null || this.search != null || this.sortBy != null) {
        NotificationLib.createWarningNotification(
          "No products were found. Please Adjust the filters and try again."
        );
      }
    }

    Loader.setLoadingState(false);
  }

  /* @Watch("search")
  async searchChanged(): Promise<void> {
    if (this.search) {
      var resItems = await this.getProducts();
      var resFind = resItems.filter(
        (elem) =>
          (this.search ? elem.title.includes(this.search) : null) ||
          (this.search ? elem.description.includes(this.search) : null) ||
          (this.search ? elem.price.toString().includes(this.search) : null) ||
          (this.search ? elem.category.includes(this.search) : null)
      );
      if (resFind.length > 0) {
        this.items = resFind;
      } else {
        this.items = resItems;
      }
    } else {
      this.items = await this.getProducts();
    }
  } */

  /* @Watch("search")
  async searchChanged(): Promise<void> {
    if (this.search) {
      var resItems = await this.getProducts();
      var resFind = resItems.filter(
        (elem) =>
          (this.search ? elem.title.includes(this.search) : null) ||
          (this.search ? elem.description.includes(this.search) : null) ||
          (this.search ? elem.price.toString().includes(this.search) : null) ||
          (this.search ? elem.category.includes(this.search) : null)
      );
      if (resFind) {
        this.items = resFind;
      } else {
        this.items = resItems;
      }
    } else {
      this.items = await this.getProducts();
    }
  } */

  async mounted(): Promise<void> {
    fbHook.logEvent(0, null);
    await this.init();

    if (this.$route.params.product) {
      var product = this.items.find(
        (product) => product.id == Number(this.$route.params.product)
      );
      // console.log("Video from Route params :", video);
      if (product) {
        //this.activeVideo = video;
        this.slideshow = this.items.findIndex(
          (productTobeFound) => productTobeFound.id == product?.id
        );
      }
    }
  }

  formatDate(date: string): string {
    return dayjs(date).format("YYYY-MM-DD HH:mm");
  }

  async getProducts(): Promise<ProductsViewModel[]> {
    try {
      const res = await storeLib.GetProducts(this.query);
      var items: ProductsViewModel[] = [];
      items = res.products;
      return Promise.resolve(items);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getProductsCategories(): Promise<ProductCategories[]> {
    try {
      const res = await storeLib.GetProductCategoriess();
      var items: ProductCategories[] = [];
      items = res;
      return Promise.resolve(items);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      this.items = await this.getProducts();
      this.categories = await this.getProductsCategories();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async generateLink(): Promise<void> {
    if (!this.activeProduct) {
      return Promise.resolve();
    }

    const link = process.env.VUE_APP_URL + "/buy_sell/" + this.activeProduct.id;

    let payload = {
      title: `IsCarTi: Product`,
      text:
        this.activeProduct.title +
        " has been shared with you. Click the link to see more about the Product.",
      url: link,
    };

    if (Capacitor.isNativePlatform()) {
      await CapShare.share({
        ...payload,
        dialogTitle: "Share Product with another party",
      });
    } else {
      await navigator.share(payload);
    }
    fbHook.logEvent(3, {
      method: "Generated Link",
      content_type: "Product Share",
      item_id: this.activeProduct.id?.toString(),
    });
  }

  async deleteProduct(): Promise<void> {
    try {
      Loader.setLoadingState(true);

      const res = await storeHook.DeleteProducts(this.deleteItem);
      NotificationLib.createSuccessNotification("Product Deleted");
      await this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      Loader.setLoadingState(false);
    }
  }
}
