
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { CalendarEvent } from "vuetify/types";
import { Event, EventCategories } from "@/models/event";
import { EventsLib, NotificationLib } from "@/helpers";
import { CustomCalendarCompleteEvent } from "@/models/calendar";
import fbHook from "@/helpers/firebase";
import { component } from "vue/types/umd";
import Loader from "@/store/modules/Loader";
import { Query } from "@/models/search";
import { Device } from "@capacitor/device";
import { Share as CapShare } from "@capacitor/share";

dayjs.extend(advancedFormat);
@Component({
  /* components: {
    AddToCalendarButton,
  }, */
  filters: {
    hourDiff: (val: string, other: string) => {
      const diff = dayjs(other).diff(val, "hours", true);
      if (diff > 24) {
        return `${Math.round(diff / 24)} ${
          Math.round(diff / 24) == 1 ? "day" : "days"
        }`;
      } else {
        return diff + " hrs";
      }
    },
    convertTimeDate: (val: string) => {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
    convertHour: (val: string) => {
      return dayjs(val).get("hour");
    },
    convertMin: (val: string) => {
      return dayjs(val).get("minute");
    },
    lessThan10: (val: number) => {
      // console.log("Less ", val);
      if (val <= 0) return "00";
      if (val) return String(val).padStart(val == 0 ? 3 : 2, "0");
      return val;
    },
  },
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Events Calendar",
  },
})
export default class Calendar extends Vue {
  weekdays = [1, 2, 3, 4, 5, 6, 0];
  focus = "";
  type = "month";
  typeToLabel: {
    [key: string]: string;
  } = {
    month: "Month",
    week: "Week",
    "custom-daily": "CDaily",
    "custom-weekly": "CWeekly ",
    day: "Day",
    "4day": "4 Days",
  };
  selectedEvent: {
    id?: number;
    name: string;
    start: Date;
    end: Date;
    details: Event;
    color: string;
    timed: boolean;
  } | null = null;
  selectedElement: any = null;
  selectedOpen = false;
  search: string | null = null;
  location: string | null = null;
  category: string | null = null;
  categories: EventCategories[] = [];
  sortBy: string | null = null;
  sortByList = ["Start Date", "End Date", "Category", "Location"];
  sortDesc = true;
  query: Query = {
    searchString: "",
    filterOnList: 3,
    page: 0,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  events: {
    id?: number;
    name: string;
    start: Date;
    end: Date;
    details: Event;
    color: string;
    timed: boolean;
  }[] = [];
  colors = [
    "blue",
    "blue darken-2",
    "indigo",
    "deep-purple",
    "cyan",
    "green",
    "orange",
    "grey darken-2",
    "primary",
    "red",
    "light-blue",
    "teal",
    "amber",
    "blue-grey",
    "yellow",
    "grey",
    "purple",
  ];
  rules = {
    required: (v: string) => !!v || "Please provide a value",
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null
        ? value.length == 10
        : "" || "Please provide a contact number",
  };

  isBefore(val: string, cmp: string) {
    return dayjs(val).isBefore(cmp, "day");
  }

  isSame(val: string, cmp: string) {
    return dayjs(val).isSame(cmp, "day");
  }

  isAfter(val: string, cmp: string) {
    return dayjs(val).isAfter(cmp, "day");
  }

  get getCalendarTitle() {
    if (this.$refs.calendar) {
      return (this.$refs.calendar as any).title;
    }
    return null;
  }

  getDate(val: string): string {
    return dayjs(val).format("YYYY-MM-DD");
  }
  getTime(val: string): string {
    return dayjs(val).format("HH:mm");
  }

  async applyFilters(): Promise<void> {
    Loader.setLoadingState(true);
    await this.loadEvents();
    var filteredItems: {
      id?: number;
      name: string;
      start: Date;
      end: Date;
      details: Event;
      color: string;
      timed: boolean;
    }[] = [];

    if (this.search) {
      var resSearch = this.events.filter(
        (elem) =>
          (this.search ? elem.name.includes(this.search) : null) ||
          (this.search
            ? elem.details.description!.includes(this.search)
            : null) ||
          (this.search
            ? elem.details.location!.toString().includes(this.search)
            : null)
      );
      if (resSearch.length > 0) {
        filteredItems = resSearch;
        console.log("filtered after search processed :", filteredItems);
      }
    }

    if (this.category) {
      var resCategory: {
        id?: number;
        name: string;
        start: Date;
        end: Date;
        details: Event;
        color: string;
        timed: boolean;
      }[] = [];
      var processCategoryItems = [];

      if (filteredItems.length > 0) {
        processCategoryItems = filteredItems;
      } else {
        processCategoryItems = this.events;
      }

      processCategoryItems.map((item) => {
        if (item.details.category == this.category) {
          resCategory.push(item);
        }
      });

      if (resCategory.length > 0) {
        filteredItems = resCategory;
        console.log("filtered after category processed :", filteredItems);
      }
    }

    if (this.location) {
      var resLocation: {
        id?: number;
        name: string;
        start: Date;
        end: Date;
        details: Event;
        color: string;
        timed: boolean;
      }[] = [];
      var processLocationItems = [];

      if (filteredItems.length > 0) {
        processLocationItems = filteredItems;
      } else {
        processLocationItems = this.events;
      }

      processLocationItems.map((item) => {
        if (item.details.location == this.location) {
          resLocation.push(item);
        }
      });

      if (resLocation.length > 0) {
        filteredItems = resLocation;
        console.log("filtered after location processed :", filteredItems);
      }
    }

    /*  if (this.sortBy) {
      var resSortBy = [];
      var processSortItems = [];

      if (filteredItems.length > 0) {
        processSortItems = filteredItems;
      } else {
        processSortItems = this.events;
      }

      if (this.sortBy == "Start Date") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort(
            (a, b) =>
              Number(new Date(b.start ? b.start : "")) -
              Number(new Date(a.start ? a.start : ""))
          );
        } else {
          resSortBy = processSortItems.sort(
            (a, b) =>
              Number(new Date(a.start ? a.start : "")) -
              Number(new Date(b.start ? b.start : ""))
          );
        }
      }

      if (this.sortBy == "End Date") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort(
            (a, b) =>
              Number(new Date(b.end ? b.end : "")) -
              Number(new Date(a.end ? a.end : ""))
          );
        } else {
          resSortBy = processSortItems.sort(
            (a, b) =>
              Number(new Date(a.end ? a.end : "")) -
              Number(new Date(b.end ? b.end : ""))
          );
        }
      }

      if (this.sortBy == "Category") {
        if (this.sortDesc == true) {
          resSortBy = processSortItems.sort((a, b) =>
            a.details.category! < b.details.category!
              ? 1
              : a.details.category! > b.details.category!
              ? -1
              : 0
          );
        } else {
          resSortBy = processSortItems.sort((a, b) =>
            a.details.category! > b.details.category!
              ? 1
              : a.details.category! < b.details.category!
              ? -1
              : 0
          );
        }
      }

      if (processSortItems.length > 0) {
        filteredItems = processSortItems;
        console.log("filtered after sort processed :", filteredItems);
      }
    } */

    console.log("final filtered :", filteredItems);
    if (filteredItems.length > 0) {
      this.events = filteredItems;
    } else {
      if (
        this.category != null ||
        this.search != null ||
        this.sortBy != null ||
        this.location != null
      ) {
        NotificationLib.createWarningNotification(
          "No events were found. Please Adjust the filters and try again."
        );
      }
    }

    Loader.setLoadingState(false);
  }

  async loadEvents() {
    Loader.setLoadingState(true);
    const res = await EventsLib.GetAllEventCalendar(this.query);

    this.events.splice(0);
    res.eventCalendars.forEach((evnt: Event) => {
      const randomIndex = this.rnd(0, this.colors.length - 1);
      //console.log("randomIndex", randomIndex);
      const colorToBeUsed =
        this.events.length > 0
          ? this.colors[randomIndex] ==
            this.events[this.events.length - 1].color
            ? this.colors[
                randomIndex == this.colors.length - 1
                  ? randomIndex - 1
                  : randomIndex + 1
              ]
            : this.colors[randomIndex]
          : this.colors[randomIndex];
      //console.log("color to be used", colorToBeUsed);
      this.events.push({
        id: evnt._id,
        name: evnt.eventTitle,
        color: colorToBeUsed,
        timed: false,
        start: dayjs(evnt.start).toDate(),
        end: dayjs(evnt.ending).toDate(),
        details: evnt,
      });
    });
    Loader.setLoadingState(false);
  }

  async getEventCategories(): Promise<EventCategories[]> {
    try {
      const res = await EventsLib.GetEventCategoriess();
      var items: EventCategories[] = [];
      items = res;
      return Promise.resolve(items);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async mounted() {
    const temp = this.$refs.calendar;
    if (temp) {
      (temp as any).checkChange();
    }

    await this.loadEvents();
    this.categories = await this.getEventCategories();

    fbHook.logEvent(0, null);
  }

  viewDay({ date }: { date: string }) {
    this.focus = date;
    this.type = "day";
  }

  roundTime(time: number, down = true) {
    const roundTo = 15; // minutes
    const roundDownTime = roundTo * 60 * 1000;

    return down
      ? time - (time % roundDownTime)
      : time + (roundDownTime - (time % roundDownTime));
  }

  rndElement(arr: any[]) {
    return arr[this.rnd(0, arr.length - 1)];
  }

  toTime(tms: any) {
    return new Date(
      tms.year,
      tms.month - 1,
      tms.day,
      tms.hour,
      tms.minute
    ).getTime();
  }

  getEventColor(event: CustomCalendarCompleteEvent) {
    return event.color;
  }

  setToday() {
    this.focus = "";
  }

  prev() {
    const temp = this.$refs.calendar;
    if (temp) (temp as any).prev();
  }

  next() {
    const temp = this.$refs.calendar;
    if (temp) (temp as any).next();
  }

  async openLink(): Promise<void> {
    if (this.selectedEvent && this.selectedEvent.details.url) {
      const devInfo = await Device.getInfo();
      if (devInfo.platform == "web") {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = this.selectedEvent.details.url;
        a.target = "_blank";
        a.click();
      } else {
        let payload = {
          title: this.selectedEvent.name,
          url: this.selectedEvent.details.url,
        };
        await CapShare.share({
          ...payload,
          dialogTitle: "Open Link",
        });
      }
    }
  }

  get getOrderedEvents() {
    return this.events.sort((a, b) => {
      if (dayjs(a.start).isBefore(dayjs(b.start))) {
        // if (dayjs(a.end).isBefore(dayjs(b.end))) {
        //   return -1;
        // } else {
        //   return -1;
        // }
        return -1;
      } else if (dayjs(a.start).isSame(dayjs(b.start))) {
        return 0;
      } else {
        return 1;
      }
    });
  }

  showEvent({
    nativeEvent,
    event,
  }: {
    nativeEvent: MouseEvent;
    event: {
      id?: number;
      name: string;
      start: Date;
      end: Date;
      details: Event;
      color: string;
      timed: boolean;
    };
  }) {
    //console.log("event", event);
    const open = () => {
      this.selectedEvent = {
        ...(event as any),
      };
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true))
      );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }
  rnd(a: number, b: number) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }
}
