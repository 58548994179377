
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Register from "@/views/authentication/Register.vue";
import EmployeeForm from "@/views/employees/Create.vue";
import dayjs, { Dayjs } from "dayjs";
import { EmployeeLib, NotificationLib, StorageLib, UserLib } from "@/helpers";
import { generateMessageFromError } from "@/plugins/axios";
import Nav from "@/store/modules/Nav";
import {
  Administrator,
  Driver,
  Employee,
  Unregistered,
} from "@/models/employee";
import { fleetLib } from "@/helpers/fleet";
import { UserProfileSettings } from "@/models/user";
import fbHook from "@/helpers/firebase";
import Loader from "@/store/modules/Loader";

interface link {
  title: string;
  path?: string;
}

@Component({
  metaInfo: {
    title: "IsCarTi",
    titleTemplate: "%s | Profile",
    meta: [
      {
        name: "description",
        content: "ISCARTI Profile.",
      },
      {
        property: "og:description",
        content: "ISCARTI Profile.",
      },
    ],
  },
  components: {
    Register,
    EmployeeForm,
  },
})
export default class Profile extends Vue {
  // Modals
  logoutModal = false;
  deregisterModal = false;
  editProfile = false;
  validForm = false;
  employee: Employee | Driver | Administrator | null = null;
  unregistered: Unregistered[] = [];
  registered: Employee[] | Driver[] = [];
  settings: UserProfileSettings = {
    pushNotification: false,
    emailNotification: false,
    whatsAppNotficication: false,
  };
  optionsModal = false;
  scrolled = 0;
  showMore = true;
  panel: number[] = [0];
  // Enums
  // Methods
  login(): void {
    // AuthModule.setLoginModal(true);
  }
  async update() {
    const temp = this.$refs.updateProfileForm as Register;
    if (temp) {
      await temp.form.submit();
      //console.log("Updated profile ");
      await this.loadProfile();
      this.editProfile = false;
      if (this.optionsModal == true) this.optionsModal = false;
    }
  }
  async updateSettings() {
    try {
      await UserLib.updateUserSettings(this.settings);
      await this.loadProfile();
      NotificationLib.createSuccessNotification("Updated settings.");
    } catch (err) {
      NotificationLib.createErrorNotification("Updated failed.");
      return Promise.reject(err);
    }
  }
  logout(): void {
    AuthModule.logout();
    StorageLib.removeLogin();
    this.logoutModal = false;
  }

  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }

  initializeDriver(): Driver | null {
    const company = this.getProfile ? this.getProfile.companyDetails : null;
    if (company) {
      return {
        name: "",
        email: "",
        companyId: company.id,
        userType: "Company Driver",
        // province = null,
        phoneNumber: "",
        province: "",
        // address: {
        //   complexName: "",
        //   buildingName: "",
        //   streetNo: "",
        //   street: "",
        //   suburb: "",
        //   city: "",
        //   zipCode: "",
        //   province: "",
        // },
        // createBy: this.getProfile?.userDetails.nameOfUser,
        // createDate: dayjs(),
      };
    } else {
      return null;
    }
  }
  initializeAdministrator(): Administrator | null {
    const company = this.getProfile ? this.getProfile.companyDetails : null;
    if (company) {
      return {
        name: "",
        email: "",
        companyId: company.id,
        userType: "Company Administrator",
        phoneNumber: "",
        // province: null,
        // address: null,
        address: {
          complexName: "",
          buildingName: "",
          streetNo: "",
          street: "",
          suburb: "",
          city: "",
          zipCode: "",
          province: "",
        },
        // createBy: this.getProfile?.userDetails.nameOfUser,
        // createDate: dayjs(),
      };
    } else {
      return null;
    }
  }
  async registerEmployee(employee: Employee) {
    try {
      Loader.setLoadingState(true);
      const res = await EmployeeLib.registerEmployee(employee);
      /* AuthModule.setProfile(res); */
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
    if (AuthModule.isLoggedIn) {
      this.unregistered = await this.loadUnregisteredEmployees();
      this.registered = await this.loadRegisteredEmployees();
    }
  }
  async deleteEmployee(employee: Driver) {
    try {
      const res = await fleetLib.deleteDriver(employee);
      return Promise.reject(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deregister(): Promise<void> {
    try {
      if (AuthModule.getProfile) {
        await UserLib.deregister();
        // AuthModule.setAuthToken(null);
        StorageLib.removeLogin();
        NotificationLib.createSuccessNotification(
          "Your account was successfully deregistered"
        );
      } else {
        NotificationLib.createWarningNotification(
          "We were unable to remove your account at this time. Please contact the system administrator to effect this."
        );
      }
      AuthModule.logout();
      this.deregisterModal = false;

      return Promise.resolve();
    } catch (err) {
      const temp = generateMessageFromError(err);
      NotificationLib.createErrorNotification(
        "Could not Get Vehicle Makes. " + temp
      );
      return Promise.reject(err);
    }
  }
  async loadProfile() {
    try {
      Loader.setLoadingState(true);
      if (AuthModule.isLoggedIn) {
        await UserLib.getProfile();
        if (this.getProfile) {
          this.settings = {
            emailNotification: this.getProfile.userDetails.emailNotification,
            pushNotification: this.getProfile.userDetails.pushNotification,
            whatsAppNotficication: false,
          };
        }
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
  async mounted() {
    await this.loadProfile();
    if (AuthModule.isLoggedIn) {
      this.unregistered = await this.loadUnregisteredEmployees();
      this.registered = await this.loadRegisteredEmployees();
    }

    fbHook.logEvent(0, null);
  }
  async loadRegisteredEmployees(): Promise<Employee[] | Driver[]> {
    try {
      Loader.setLoadingState(true);
      const res = (await fleetLib.reviewDriver()) as Driver[]; // Returns a list of drivers if no id is specified; cast is to appease the linter
      return Promise.resolve(res);
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      // this.loading = false;

      Loader.setLoadingState(false);
    }
  }
  async loadUnregisteredEmployees(): Promise<Unregistered[]> {
    // this.loading = true;
    try {
      Loader.setLoadingState(true);
      const res = await fleetLib.reviewUnregistered(); // Returns a list of drivers if no id is specified; cast is to appease the linter
      return Promise.resolve(res);
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      // this.loading = false;

      Loader.setLoadingState(false);
    }
  }
  async resendInvitation(employee: Unregistered) {
    if (employee.id) {
      await fleetLib.resendInvitation(employee.id);
    }
  }
  get getProfile() {
    return AuthModule.getProfile;
  }
  // get getEmployees() {
  //   return AuthModule.getProfile?.companyDetails;
  // }
  get getCompanyType() {
    return Nav.getSystemCompanyTypes;
  }
  get getMechanicRole() {
    const role = Nav.getSystemCompanyTypes.find(
      (e) => e.description == "Mechanic"
    );
    if (role) return role.id;
    return null;
  }

  get getYear() {
    return dayjs().format("YYYY");
  }

  goTo(name: string | undefined): void {
    if (name != undefined) {
      this.$router.push({
        name: name,
      });
    }
  }
  get getVersion() {
    return process.env.VUE_APP_VERSION;
  }
  links: link[] = [
    {
      title: "View Vehicle List",
      path: "vehicleList",
    },
    {
      title: "Contact Details",
      path: "contactUs",
    },
    /* {
      title: "Offline Permits",
      path: "dash.offlinePermits",
    }, */
  ];

  /* created(): void {
    window.addEventListener("scroll", this.handleScroll);
  } */

  /* unmounted(): void {
    window.removeEventListener("scroll", this.handleScroll);
  } */

  /* handleScroll(): void {
    this.scrolled = window.scrollY;
  } */
}
