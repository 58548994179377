import { EntityQuery, Entity, SearchMechanicResponse } from "@/models/business";
import { axiosInstance } from "@/plugins/axios";
import AuthModule from "@/store/modules/Auth";
import { fileToBase64 } from "@/utilities";

const DirectoryLib = () => {
  const Directory = async (
    data: EntityQuery
  ): Promise<SearchMechanicResponse> => {
    const res = await axiosInstance.post(`/api/Directory/SearchMechanic`, data);
    return Promise.resolve(res.data);
  };
  return {
    Directory,
  };
};

const directoryLib = DirectoryLib();
export { directoryLib };
