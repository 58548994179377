import { axiosInstance } from "@/plugins/axios";

const ai_Lib = () => {
  const GetImg2Img = async (
    incoming: string | null,
    base64: string | null
  ): Promise<any> => {
    const res = await axiosInstance.post(
      /* `/api/AI/GetImg2Img?incoming=${incoming}&base64=${base64}` */
      "/api/AI/GetImg2Img",
      {
        incoming: incoming,
        base64: base64,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return Promise.resolve(res.data);
  };

  const GetImg2ImgWithVehicle = async (
    vehicleId: number,
    base64: string | null
  ): Promise<any> => {
    const res = await axiosInstance.post(
      /* `/api/AI/GetImg2Img?incoming=${incoming}&base64=${base64}` */
      "/api/AI/GetImg2ImgWithVehicle",
      {
        vehicleId: vehicleId,
        base64: base64,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return Promise.resolve(res.data);
  };

  const GetTxt2Img = async (incoming: string | null): Promise<any> => {
    const res = await axiosInstance.post(
      /* `/api/AI/GetImg2Img?incoming=${incoming}&base64=${base64}` */
      "/api/AI/GetTxt2Img",
      {
        incoming: incoming,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return Promise.resolve(res.data);
  };

  const GetImageCutOut = async (
    x: number | null,
    y: number | null,
    h: number | null,
    w: number | null,
    image: string | null
  ): Promise<any> => {
    const res = await axiosInstance.post(
      "/api/AI/GetImageCutOut",
      {
        x: x,
        y: y,
        h: h,
        w: w,
        image: image,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return Promise.resolve(res.data);
  };

  return {
    GetImg2Img,
    GetImg2ImgWithVehicle,
    GetImageCutOut,
    GetTxt2Img,
  };
};

const aiLib = ai_Lib();
export { aiLib };
